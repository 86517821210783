<mat-dialog-content class="mat-typography main-container">
  <h3 *ngIf="dialogData.messageParams else translationWithoutParams">
    {{dialogData.messageKey | translate: dialogData.messageParams}}
  </h3>
  <ng-template #translationWithoutParams>
    <h3>{{dialogData.messageKey | translate}}</h3>
  </ng-template>
  <div cdkDropList class="item-list mat-list-base" (cdkDropListDropped)="drop($event)">
    <div class="item-box" *ngFor="let item of dialogData.items" cdkDrag>
      <div class="item-custom-placeholder mat-list-item" *cdkDragPlaceholder></div>
      {{item.name}}
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{dialogData.btnCancelLabelKey | translate}}
  </button>
  <button mat-button color="primary" (click)="onOk()">
    {{dialogData.btnOkLabelKey | translate}}
  </button>
</mat-dialog-actions>
