import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {environment as env_const} from '@env/environment';
import {Observable} from 'rxjs';
import {
  SendLogResponseModel,
  ExecutionInstanceResponseModel,
  ExecutionLogResponseModel,
  ExecutionLogsResponseModel,
  ExecutionInstancesResponseModel
} from '@common/models/response-model';
import {formatDate} from '@angular/common';

export interface ExecutionInstanceSearch {
  project_id?: number;
  user_id?: number;
  from_date?: string;
  to_date?: string;
}

interface HttpParamsAppend {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class ExecutionReportRestService {
  private static readonly HEADERS = new HttpHeaders({'Content-Type': 'application/json'});
  private apiUrl = env_const.apiUrl;

  constructor(private http: HttpClient) {
  }

  public static getNowDateTimeAsText(): string {
    return formatDate( new Date(), 'yyyy-MM-dd hh:mm:ss', 'en_US' );
  }

  public sendLog$(executionId: number, tag: string = '', jsonData: string = ''): Observable<SendLogResponseModel> {
    if (executionId !== null) {
      const restServiceUrl = this.apiUrl + 'add_execution_log/';
      const datetime = ExecutionReportRestService.getNowDateTimeAsText();
      const sendTag = datetime + ' &' + tag;
      const httpParams = new HttpParams().appendAll({
        'execution_instance_id': executionId && executionId.toString(),
        'client_date': datetime,
        'tag': sendTag,
        'data_json': jsonData
      });
      return this.http.post<SendLogResponseModel>(restServiceUrl, null, {
        headers: ExecutionReportRestService.HEADERS,
        params: httpParams
      });
    }
  }

  public addExecutionLog$(executionId: number, tag: string = '', jsonData: string = ''): Observable<ExecutionLogResponseModel> {
    if (executionId !== null) {
      const restServiceUrl = this.apiUrl + 'add_execution_log/';
      const httpParams = new HttpParams().appendAll({
        'execution_instance_id': executionId.toString(),
        'client_date': ExecutionReportRestService.getNowDateTimeAsText(),
        'tag': tag,
        'data_json': jsonData
      });
      return this.http.post<ExecutionLogResponseModel>(restServiceUrl, null, {
        headers: ExecutionReportRestService.HEADERS,
        params: httpParams
      });
    }
  }

  public getExecutionLogs$(executionId: number): Observable<ExecutionLogsResponseModel> {
    if (executionId !== null) {
      const restServiceUrl = this.apiUrl + 'get_execution_logs/';
      const httpParams = new HttpParams().appendAll({
        'execution_instance_id': executionId.toString()
      });
      return this.http.post<ExecutionLogsResponseModel>(restServiceUrl, null, {
        headers: ExecutionReportRestService.HEADERS,
        params: httpParams
      });
    }
  }

  public addExecutionInstance$( projectId: number, forceEvenNotPublished = false ): Observable<ExecutionInstanceResponseModel> {
    if ( projectId !== null ) {
      const restServiceUrl = this.apiUrl + 'add_execution_instance/';
      const httpParams = new HttpParams().appendAll({
        'project_id': projectId,
        'force_project': env_const.forceAllProjectsReports || forceEvenNotPublished,
        'client_date': ExecutionReportRestService.getNowDateTimeAsText()
      });
      return this.http.post<ExecutionInstanceResponseModel>(restServiceUrl, null, {
        headers: ExecutionReportRestService.HEADERS,
        params: httpParams
      });
    }
  }

  public getExecutionInstances$( executionInstanceSearch?: ExecutionInstanceSearch): Observable<ExecutionInstancesResponseModel> {
    const restServiceUrl = this.apiUrl + 'get_execution_instances/';
    const appendParams = <HttpParamsAppend>{};
    if ( executionInstanceSearch ) {
      if ( executionInstanceSearch.project_id ) {
        appendParams.project_id = executionInstanceSearch.project_id;
      }
      if ( executionInstanceSearch.user_id ) {
        appendParams.user_id = executionInstanceSearch.user_id;
      }
      if ( executionInstanceSearch.from_date ) {
        appendParams.from_date = executionInstanceSearch.from_date;
      }
      if ( executionInstanceSearch.to_date ) {
        appendParams.to_date = executionInstanceSearch.to_date;
      }
    }
    return this.http.post<ExecutionInstancesResponseModel>(restServiceUrl, null, {
      headers: ExecutionReportRestService.HEADERS,
      params: new HttpParams().appendAll(appendParams)
    });
  }

  public getPdfReport$( json: string): Observable<Blob> {
    const restServiceUrl = this.apiUrl + 'get_pdf/';
    const options = {
      headers: ExecutionReportRestService.HEADERS,
      params: new HttpParams().appendAll({
        'data_json': json
      } ),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.post<Blob>( restServiceUrl, null, options );
  }
}


