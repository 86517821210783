import { Component, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface MessageDialogData {
  message: string;
  messageKey?: string;
  remainTime?: number;
}

@Component({
  selector: 'tiwp-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})

export class MessageDialogComponent {
  constructor(private  dialogRef:  MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public  data:  MessageDialogData) {
    // this.message = data.message;
    let readTime = data.message.length * 50;
    if ( data.messageKey ) {
      readTime = 3000;
    }
    const time = data.remainTime || readTime;
    setTimeout( function closeDialog () {
        dialogRef.close();
      }, time
    );

  }

  public  closeMe() {
      this.dialogRef.close();
  }
}
