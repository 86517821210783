import {
  ChildrenComponentsModel,
  ComponentAttributesModel, ComponentAttributeValue, ComponentsModel,
  ComponentTypeModel, ComponentTypesModel,
  ServerSideComponentModel, ServerSideComponentsModel
} from '@common/models/component-types-model';

import {StoreService} from '@common/services/store.service';
import {SceneModel, TiwpSceneObjectModel} from '@common/models/types-model';

const isFilled = ( object ): boolean => {
  return Object.keys(object).length !== 0;
};

export class ComponentsTranslator {
  constructor( private store: StoreService ) {}

  public translateObjectFromSeverSideToClientSide (object: TiwpSceneObjectModel): TiwpSceneObjectModel {
    const objectComponentTypes = object.object_type_id.component_types;
    const objectComponents = <ServerSideComponentsModel>object.components;
    object.components = this.getClientSideComponents( objectComponents, objectComponentTypes );

    return object;
  }

  public translateSceneObjectsFromSeverSideToClientSide (scene: SceneModel): SceneModel {
    scene.objects = scene.objects.map (
      ( object ) => {
        if ( object.object_type_id ) {
          return this.translateObjectFromSeverSideToClientSide(object);
        } else {
          console.error ( 'Object Error: ', object );
          return null;
        }
      }
    );

    return scene;
  }

  public getServerSideComponents (
    clientComponents: ComponentsModel, objectComponentTypes: ComponentTypeModel[]
  ): ServerSideComponentsModel {
    const componentTypes = this.store.getComponentTypesKeyValue();
    const toSendComponents: ServerSideComponentsModel = {};

    if ( componentTypes && objectComponentTypes && objectComponentTypes.length ) {
      for (const componentKey in clientComponents) {
        if (clientComponents.hasOwnProperty(componentKey)) {
          const component = clientComponents[componentKey];
          const componentType = componentTypes[componentKey];
          if ( component && componentType ) {
            const componentToSend = this.getServerComponent( <ComponentAttributesModel>component, componentType.attributes_json, componentTypes );
            if ( componentToSend ) {
              toSendComponents[componentKey] = [componentToSend];
            } else {
              toSendComponents[componentKey] = [ { 'attributes': {} } ];
            }
          }
        }
      }
    }

    return toSendComponents;
    // return isFilled( toSendComponents ) ? toSendComponents : undefined;
  }

  public getClientSideComponents (
    serverComponents: ServerSideComponentsModel, objectComponentTypes: ComponentTypeModel[]
  ): ComponentsModel {
    const componentTypes = this.store.getComponentTypesKeyValue();

    const clientComponents: ComponentsModel = {};

    if ( componentTypes && objectComponentTypes && objectComponentTypes.length ) {
      for (const componentKey in serverComponents) {
        if (serverComponents.hasOwnProperty(componentKey)) {
          const serverComponentList = serverComponents[componentKey]; // RestComponentModel[]
          const componentType = objectComponentTypes.find(
            (type) => type.name === componentKey
          );

          if ( serverComponentList && componentType ) {
            if ( serverComponentList.length === 1 ) {
              clientComponents[componentKey] = this.getClientComponent(
                serverComponentList[0], componentType.attributes_json, componentTypes
              );
            } else {
              // getClientComponentList
            }
          }
        }
      }
    }

    return isFilled( clientComponents ) ? clientComponents : undefined;
  }

  private setupChildrenComponent = (sendingComponent: ServerSideComponentModel, attributeKey: string ): ServerSideComponentModel[] => {
    if ( !sendingComponent.children_components ) {
      sendingComponent.children_components = <ServerSideComponentsModel>{};
    }

    let children_component = sendingComponent.children_components[attributeKey];
    if ( !children_component ) {
      children_component = sendingComponent.children_components[attributeKey] = [];
    }

    return children_component;
  };

  private setupChildrenComponentAttributes = (sendingComponent: ServerSideComponentModel, attributeKey: string ): number[] => {
    let attributesToSend = <number[]>sendingComponent.attributes[attributeKey];
    if ( !attributesToSend ) {
      attributesToSend = sendingComponent.attributes[attributeKey] = [];
    }
    return attributesToSend;
  };

  private pushSubComponentDataToSend = (
    subcomponent: ComponentAttributesModel,
    subcomponentAttributesJson: ComponentAttributesModel,
    subcomponentTypes: ComponentTypesModel,
    children_component: ServerSideComponentModel[],
    attributesToSend?: number[]
  ) => {
    const subcomponentToSend = this.getServerComponent( subcomponent, subcomponentAttributesJson, subcomponentTypes );
    if ( subcomponentToSend ) {
      if (attributesToSend) {
        attributesToSend.push( children_component.length );
      }
      children_component.push( subcomponentToSend );
    }
  };

  private pushAttributeArrayInComponentDataToSend = (
    toFillComponent: ServerSideComponentModel,
    toFillAttributes: ComponentAttributesModel,
    toFillAttributeKey: string,
    srcComponentAttributeValues: ComponentAttributesModel[],
    attributeArrayType: string,
    srcComponentTypes: ComponentTypesModel
  ) => {
    if ( attributeArrayType === 'number' || attributeArrayType === 'string' || attributeArrayType === 'boolean' ) {
      // simple values
      toFillAttributes[toFillAttributeKey] = srcComponentAttributeValues.map( ( value ) => value );
    } else {
      // subcomponents
      const componentType = srcComponentTypes[attributeArrayType];
      if ( componentType ) {
        const children_component = this.setupChildrenComponent( toFillComponent, attributeArrayType );
        const attributesToSend = this.setupChildrenComponentAttributes( toFillComponent, toFillAttributeKey );
        srcComponentAttributeValues.forEach(
          ( subcomponent: ComponentAttributesModel ) => {
            this.pushSubComponentDataToSend(
              subcomponent, componentType.attributes_json, srcComponentTypes, children_component, attributesToSend
            );
          }
        );
      }
    }
  };

  private pushAttributesInComponentDataToSend = (
    toFillComponent: ServerSideComponentModel,
    toFillAttributes: ComponentAttributesModel,
    srcComponent: ComponentAttributesModel,
    srcComponentAttributesJson: ComponentAttributesModel,
    componentTypes: ComponentTypesModel
  ) => {
    for (const attributeKey in srcComponent) {
      if ( srcComponent.hasOwnProperty(attributeKey) ) {
        const srcAttributeJsonValue = srcComponentAttributesJson[attributeKey];
        const srcAttributeValues = srcComponent[attributeKey];
        if ( srcAttributeJsonValue ) {
          if ( srcAttributeJsonValue === 'number' || srcAttributeJsonValue === 'string' || srcAttributeJsonValue === 'boolean' ) {
            // Simple Value
            toFillAttributes[attributeKey] = srcComponent[attributeKey];
          } else if ( Array.isArray( srcAttributeJsonValue ) && Array.isArray( srcAttributeValues ) ) {
            // array as value
            const attributeArrayType = <string>srcAttributeJsonValue[0];
            if ( attributeArrayType && typeof attributeArrayType === 'string') {
              this.pushAttributeArrayInComponentDataToSend(
                toFillComponent, toFillAttributes, attributeKey,
                <ComponentAttributesModel[]>srcAttributeValues, attributeArrayType,
                componentTypes
              );
            }
          } else if ( srcAttributeJsonValue === 'scene_id' ) {
            // scene ID Value
            if ( srcAttributeValues ) {
              toFillComponent.referenced_scene_id = <number>srcAttributeValues;
              toFillAttributes[attributeKey] = true;
            } else {
              toFillAttributes[attributeKey] = false;
            }
          } else if ( srcAttributeJsonValue === 'object_id' ) {
            if ( srcAttributeValues ) {
              toFillComponent.referenced_object_id = <number>srcAttributeValues;
              toFillAttributes[attributeKey] = true;
            } else {
              toFillAttributes[attributeKey] = false;
            }
          } else if ( typeof srcAttributeJsonValue === 'string' ) {
            // object as Value
            const componentType = componentTypes[srcAttributeJsonValue];
            if ( componentType ) {
              const children_component = this.setupChildrenComponent( toFillComponent, srcAttributeJsonValue );
              // toFillAttributes[srcAttributeJsonValue] = children_component.length;
              toFillAttributes[attributeKey] = children_component.length;
              this.pushSubComponentDataToSend(
                <ComponentAttributesModel>srcAttributeValues, componentType.attributes_json, componentTypes, children_component
              );
            }
          } else if ( typeof srcAttributeJsonValue === 'object' && typeof srcAttributeValues === 'object' ) {
            const fillingAttributes = toFillAttributes[attributeKey] = {};
            this.pushAttributesInComponentDataToSend(
              toFillComponent, fillingAttributes,
              <ComponentAttributesModel>srcAttributeValues, <ComponentAttributesModel>srcAttributeJsonValue,
              componentTypes
            );
          }
        }
      }
    }
  };

  private getServerComponent (
    clientComponent: ComponentAttributesModel,
    componentAttributesJson: ComponentAttributesModel,
    componentTypes: ComponentTypesModel
  ): ServerSideComponentModel {
    const toSendComponent: ServerSideComponentModel = {
      // 'referenced_scene_id': undefined, // ?: number;
      // 'children_components': {}, // : ToSendComponentsModel;
      'attributes': {} // : ComponentAttributesModel
    };

    this.pushAttributesInComponentDataToSend(
      toSendComponent, toSendComponent.attributes, clientComponent, componentAttributesJson, componentTypes
    );

    return isFilled( toSendComponent.attributes ) ? toSendComponent : undefined;
  }

  private fillComponentAttribute (
    clientComponent: ComponentAttributesModel,
    attributeKey: string,
    attributeValue: ComponentAttributeValue,
    attributeType: ComponentAttributeValue,
    childrenComponentLibrary: ChildrenComponentsModel,
    sceneId: number,
    objectId: number,
    componentTypes: ComponentTypesModel
  ) {
    if (attributeType) {
      if (attributeType === 'number' || attributeType === 'string' || attributeType === 'boolean') {
        // Simple Value
        clientComponent[attributeKey] = attributeValue;
      } else if (Array.isArray(attributeType) && Array.isArray(attributeValue)) {
        // array as value
        const attributeArrayType = <string>attributeType[0];
        if (attributeArrayType === 'number' || attributeArrayType === 'string' || attributeArrayType === 'boolean') {
          // Simple Values
          clientComponent[attributeKey] = <number[] | string[] | boolean[]>attributeValue;
        } else if (attributeArrayType && typeof attributeArrayType === 'string' ) {
          if ( childrenComponentLibrary && childrenComponentLibrary[attributeArrayType] ) {
            //  (its a list of subcomponents)
            const subComponentsIndexes = <number[]>attributeValue;
            const subComponentList = childrenComponentLibrary[attributeArrayType];
            clientComponent[attributeKey] = subComponentsIndexes.map(
              (subComponentsIndex) => subComponentList[subComponentsIndex]
            );
          } else {
            clientComponent[attributeKey] = <number[] | string[] | boolean[]>[];
          }
        }
      } else if (attributeType === 'scene_id') {
        // scene ID Value
        clientComponent[attributeKey] = attributeValue ? sceneId : undefined;
      } else if (attributeType === 'object_id') {
        // Object ID Value
        clientComponent[attributeKey] = attributeValue ? objectId : undefined;
      } else if (typeof attributeType === 'string') {
        // object as Value
        const componentType = componentTypes[attributeType];
        if (componentType && typeof attributeValue === 'number' && childrenComponentLibrary) {
          // clientComponent[attributeKey] = childrenComponentLibrary[<number>attributeValue];
          const childComponentList = <ComponentAttributeValue[]>childrenComponentLibrary[componentType.name];
          clientComponent[attributeKey] = childComponentList && childComponentList[<number>attributeValue];
        }
      } else if (typeof attributeType === 'object' && typeof attributeValue === 'object') {
        const subAttribute = clientComponent[attributeKey] = <ComponentAttributesModel>{};
        for (const subAttributeKey in attributeType) {
          if (
            attributeType.hasOwnProperty(subAttributeKey) &&
            attributeValue[subAttributeKey] !== undefined &&
            attributeValue[subAttributeKey] !== null
          ) {
            this.fillComponentAttribute(
              subAttribute,
              subAttributeKey,
              attributeValue[subAttributeKey],
              attributeType[subAttributeKey],
              childrenComponentLibrary,
              sceneId,
              objectId,
              componentTypes
            );
          }
        }
      }
    }
  }

  private getClientComponent (
    serverComponent: ServerSideComponentModel,
    attributesJson: ComponentAttributesModel,
    componentTypes: ComponentTypesModel
  ): ComponentAttributesModel {
    const clientComponent: ComponentAttributesModel = {};
    const attributes = serverComponent.attributes;
    const children_components = serverComponent.children_components;
    const referenced_scene_id = serverComponent.referenced_scene_id;
    const referenced_object_id = serverComponent.referenced_object_id;

    let childrenComponentLibrary;
    if ( children_components && isFilled( children_components ) ) {
      childrenComponentLibrary = this.getChildrenComponentLibrary ( children_components );
    }

    if ( attributes ) {
      for (const attributeKey in attributesJson) {
        if (
          attributesJson.hasOwnProperty(attributeKey) &&
          attributes[attributeKey] !== undefined &&
          attributes[attributeKey] !== null
          ) {
          this.fillComponentAttribute (
            clientComponent,
            attributeKey,
            attributes[attributeKey],
            attributesJson[attributeKey],
            childrenComponentLibrary,
            referenced_scene_id,
            referenced_object_id,
            componentTypes
          );
        }
      }
    }

    return clientComponent;
  }

  private getChildrenComponentLibrary (
    childrenComponents: ServerSideComponentsModel,
  ): ChildrenComponentsModel {
    const childrenComponentLibrary: ChildrenComponentsModel = {};
    const componentTypes = this.store.getComponentTypesKeyValue();

    for (const componentKey in childrenComponents) {
      if (childrenComponents.hasOwnProperty(componentKey)) {
        const subComponentList = childrenComponents[componentKey];
        const componentType = <ComponentTypeModel>componentTypes[componentKey];
        if ( componentType ) {
          const clientSubComponentList = subComponentList.map(
            (subComponent) => this.getClientComponent(
              subComponent, componentType.attributes_json, componentTypes
            )
          );
          if ( clientSubComponentList.length ) {
            childrenComponentLibrary[componentKey] = <ComponentAttributesModel[]>clientSubComponentList;
          }
        }
      }
    }

    return childrenComponentLibrary;
  }
}
