import { Component, Inject  } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {TreeNodeModel} from '@common/models/id-name-model';

export interface FolderSelectionDialogData {
  title?: string;
  titleKey?: string;
  folders: TreeNodeModel;
  selectedFolder?: TreeNodeModel;
}

// https://github.com/angular/material2/issues/3593
// https://stackoverflow.com/questions/42717508/how-to-communicate-from-angular-material2-dialog-to-its-parent

@Component({
  selector: 'tiwp-folder-selection-dialog',
  templateUrl: './folder-selection-dialog.component.html',
  styleUrls: ['./folder-selection-dialog.component.scss']
})
export class FolderSelectionDialogComponent {
  onDisabledTooltipText = 'disabled';
  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<FolderSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FolderSelectionDialogData
  ) {
    this.translate.get('common.tooltip.disabled').subscribe(
      ( tooltipText: string ) => this.onDisabledTooltipText = tooltipText,
      ( error ) => {
        this.onDisabledTooltipText = 'disabled';
        console.error(
          '%cLanguage error: ', 'color:black;background:red', error.message, 'Key: common.tooltip.disabled'
        );
      }
    );
  }

  stopPropagation( event: Event ) {
    event.stopPropagation();
  }

  getExpandBreadcrumb( action: ( node: TreeNodeModel ) => void ) {
    if ( this.data.selectedFolder ) {
      action ( this.data.selectedFolder );
    }
  }

  selectFolder( clickedFolder: TreeNodeModel ) {
    if ( clickedFolder ) {
      this.dialogRef.close( clickedFolder );
    }
  }
}
