import {Component, AfterViewInit , Inject, ElementRef, Renderer2} from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface PlayDialogData {
  messageTranslatedTextKey?: string;
}

/**
  Alternativa de Spinner
  https://www.npmjs.com/package/ngx-loading
*/

@Component({
  selector: 'tiwp-play-dialog',
  templateUrl: './play-dialog.component.html',
  styleUrls: ['./play-dialog.component.scss']
})
export class PlayDialogComponent implements AfterViewInit  {
  title = '';
  messageTranslatedTextKey = '';

  constructor(
    private  dialogRef:  MatDialogRef<PlayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data:  PlayDialogData,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    this.messageTranslatedTextKey = data.messageTranslatedTextKey;

    // https://stackoverflow.com/questions/46772852/disable-click-outside-of-angular-material-dialog-area-to-close-the-dialog-with
    dialogRef.disableClose = true;
  }

  ngAfterViewInit () {
    // https://stackoverflow.com/questions/51410660/angular-afterviewinit-vs-aftercontentinit
    if (this.elRef !== undefined) {
      const parent = this.renderer.parentNode(this.elRef.nativeElement);
      this.renderer.setStyle(
        parent,
        'box-shadow',
        'none'
      );
      this.renderer.setStyle(
        parent,
        'background',
        'rgba(0, 0, 0, 0.0)'
      );
    }
  }
}
