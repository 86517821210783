import {AssetModel} from '@common/models/types-model';
import {environment as env_const} from '@env/environment';

export const isBackFolderPlmItem = ( item ): boolean => !!(item && item.parentSibling);
export const createBackFolderPlmItem = ( parentSibling: PlmItem[] ): PlmItem => {
  return {
    name: '..',
    nodeId: null,
    uiImage: 'assets/icons/folder go parent.svg',
    parentSibling: parentSibling,
  };
};

export const isFolderPlmItem = ( item ): boolean => !!(item && (item.children || item.children === null) && !item.projectRoot);
export const createFolderPlmItem = ( folderNode: PlmNodeChild ): PlmItem => {
  return {
    name: folderNode.name,
    nodeId: folderNode.instance_id || folderNode.query_id,
    description: folderNode.description,
    uiImage: 'assets/icons/folder.svg',
    children: null
  };
};


export const isProjectPlmItem = ( item ): boolean => !!(item && item.projectRoot);
export const createProjectPlmItem = ( name: string, projectModelId: string, root: PlmProjectRootResponse ): PlmItem => {
  console.log('Created Project: ' + name);  // projectResponse.in_project.name
  console.log('           Root: ' + root.name);
  return {
    name: name,
    nodeId: root.instance_id,
    description: '',
    uiImage: 'assets/icons/project_type.svg',
    projectRoot: {
      name: root.name,
      id: root.id,
      instanceId: root.instance_id,
      description: root.description,
      projectModelId: projectModelId.toString()
    },
    children: null
  };
};

export const mustBeGetPlmItemChildren = ( item ): boolean => !!(item && item.children === null);
const getAssetImageIco = ( assetNode: any ): string => {
  let imageIco = 'assets/icons/asset_type.svg';
  if ( assetNode.type ) {
    switch (assetNode.type) {
      case '.glb':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.asset3d];
        break;
      case '.svg':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.image];
        break;
      case '.png':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.image];
        break;
      case '.jpg':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.image];
        break;
      case '.mp4':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.video];
        break;
      case '.webm':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.video];
        break;
      case '.mp3':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.audio];
        break;
      case '.ogg':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.audio];
        break;
      case '.pdf':
        imageIco = env_const.default.assetTypeIcon[env_const.assetTypeIds.pdf];
        break;
    }
  }
  return imageIco;
};

export const isAssetPlmItem = ( item ): boolean => !!(item && item.asset);
export const createAssetPlmItem = ( projectId: string, assetNode: any ): PlmItem => {
  return {
    name: assetNode.title,
    nodeId: assetNode.instance_id,
    description: assetNode.description,
    uiImage: getAssetImageIco(assetNode),
    asset: {
      projectId: projectId,
      id: assetNode.id,
      fileId: assetNode.instance_id,
      fileName: assetNode.file_name,
      type: assetNode.type
    }
  };
};

interface PlmProjectRoot {
  name: string;
  id: string;
  instanceId: number;
  description: string;
  projectModelId: string;
}

export interface PlmAsset {
  projectId: string;
  id: string;
  fileId: number;
  fileName: string;
  type?: string;
}

export interface PlmItem {
  name: string;
  nodeId: number;
  description?: string;
  uiImage: string;
  url?: string;
  parentSibling?: PlmItem[];
  projectRoot?: PlmProjectRoot;
  asset?: PlmAsset;
  plmFolder?: {};
  plmFile?: {};
  children?: PlmItem[];
}

export interface PlmProjectRootResponse {
  name: string;
  id: string;
  instance_id: number;
  description: string;
}

export interface PlmProjectResponse {
  name: string;
  model_id: string;
  root: PlmProjectRootResponse;
}

export interface PlmProjectsResponse {
  success: boolean;
  errors: string;
  log?: any;
  projects: PlmProjectResponse[];
}

export interface PlmNodeChild {
  name: string;
  instance_id: number;
  query_id?: number;
  description: string;
  properties?: PlmNodeProperty[];
}

export interface PlmNodeChildContent {
  title: string;
  id: string;
  instance_id: number;
  description: string;
  file_name: string;
  type: string;
}

export interface PlmNodeProperty {
  name: string;
  num_val: number;
  unit: string;
  val: string;
}

export interface PlmChildren {
  children: PlmNodeChild[];
  contents: PlmNodeChildContent[];
}

export interface PlmChildrenResponse {
  success: boolean;
  errors: string;
  node: PlmChildren;
}

export interface PlmFileImportResponse {
  success: boolean;
  errors: string;
  log?: any;
  asset: AssetModel;
}
