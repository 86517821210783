import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import { environment as env_const } from '@env/environment';
import {LobbyWebsocketService} from './lobby-websocket.service';
import {map} from 'rxjs/operators';
import {StoreService} from '@common/services/store.service';
import {IdNameModel} from '@common/models/id-name-model';

export interface JoinRoom {
  callId: number;
  token: string;
}

export interface JoinCall {
  callId: number;
  fromUserId: number;
  userIds: number[];
}

enum Operations {
  updateUser = 'update_user',
  callUsers = 'create_call_req',
  callRequest = 'call_request',
  answerCallRequest = 'answer_call_req',
  joinCall = 'join_call',
  inviteCall = 'invite_call',
  leaveCall = 'leave_call',
  startWebrtc = 'start_webrtc',
  endWebrtc = 'end_webrtc'
}

enum UserStatus {
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED',
  busy = 'BUSY'
}

interface LobbyMessageModel {
  'operation': string;
  'platform'?: string;
  'call_id'?: number;
  'user_id'?: number;
  'user_ids'?: number[];
  'from_user_id'?: number;
  'user_name'?: string;
  'user_status'?: string;
  'is_contact'?: boolean;
  'accept'?: boolean;
  'webrtc_token'?: string;
}

const affiliate = ( users: IdNameModel[], userToAffiliate: IdNameModel ) => {
  if ( !users.find( user => user.id === userToAffiliate.id ) ) {
    users.push( userToAffiliate );
  }
};

const disaffiliate = ( users: IdNameModel[], userToDisaffiliate: IdNameModel ) => {
  const userIndex = users.findIndex( user => user.id === userToDisaffiliate.id );
  if ( userIndex !== -1 ) {
    users.splice( userIndex, 1 );
  }
};

const isNotMember = ( users: IdNameModel[], user: IdNameModel ): boolean => {
  const userIndex = users.findIndex( member => member.id === user.id );
  return userIndex === -1;
};

@Injectable({
  providedIn: 'root'
})
export class LobbyService {
  private connectedUsers: IdNameModel[] = [];
  private busyUsers: IdNameModel[] = [];
  private availableUsers: IdNameModel[] = [];

  private messages: Subject<LobbyMessageModel>;
  private _messagesSubscription: Subscription;

  private availableUsers$ = new BehaviorSubject<IdNameModel[]>(
    this.availableUsers
  );

  private userDisconnectionPublisher$ = new Subject<IdNameModel>();
  private roomTokenPublisher$ = new Subject<JoinRoom>();
  private roomDisconnectionPublisher$ = new Subject<JoinRoom>();
  private callRequestPublisher$ = new Subject<JoinCall>();

  private callTokenTable: {
    [n: number]: string;
  } = {};

  constructor(
    private store: StoreService,
    private lobbyWebsocketService: LobbyWebsocketService
  ) {}

  private parseWebsocketMessages(response: MessageEvent): LobbyMessageModel {
    const message = JSON.parse( response.data );
    console.log( 'Websocket Reception: ', message );
    return message;
  }

  connectServer() {
    this.callTokenTable = {};
    const accessToken = this.store.getAccessToken();
    const platform = 'WebGLPlayer';
    // const url = `${env_const.lobbyWebSocketUrl}?platform=${platform}`;
    const url = `${env_const.lobbyWebSocketUrl}?platform=${platform}&token=${accessToken}`;
    // const url = `${env_const.lobbyWebSocketPrtl}${accessToken}@${env_const.lobbyWebSocketPath}?platform=WebGLPlayer`;
    this.messages = <Subject<LobbyMessageModel>>this.lobbyWebsocketService.connect( url ).pipe(
      map( this.parseWebsocketMessages.bind(this) )
    );
    this.subscribeWebsocketMessages();
  }

  disconnectServer() {
    this.unsubscribeWebsocketMessages();
    this.lobbyWebsocketService.disconnect();
    this.messages = null;
    this.connectedUsers.length = 0;
    this.busyUsers.length = 0;
    this.updateAvailableUsers();
    this.callTokenTable = {};
  }

  callUser(userId: number ) {
    if ( this.messages && userId) {
      const message: LobbyMessageModel = {
        'operation': Operations.callUsers,
        'user_ids': [userId]
      };

      this.messages.next(message);
    }
  }

  inviteUser( userId: number, callId: number ) {
    if ( this.messages && userId) {
      const message: LobbyMessageModel = {
        'operation': Operations.inviteCall,
        'call_id': callId,
        'user_id': userId
      };

      this.messages.next(message);
    }
  }

  acceptCall(callId: number ) {
    if ( this.messages  && callId ) {
      const message: LobbyMessageModel = {
        'operation': Operations.answerCallRequest,
        'call_id': callId,
        'accept': true
      };

      this.messages.next(message);
    }
  }

  rejectCall(callId: number ) {
    if ( this.messages && callId ) {
      const message: LobbyMessageModel = {
        'operation': Operations.answerCallRequest,
        'call_id': callId,
        'accept': false
      };

      this.messages.next(message);
    }
  }

  private joinCall ( callId: number ) {
    if ( this.messages && callId ) {
      const message: LobbyMessageModel = {
        'operation': Operations.joinCall,
        'call_id': callId
      };

      this.messages.next(message);
    }
  }

  leaveCall ( callId: number ) {
    if ( this.messages && callId ) {
      const message: LobbyMessageModel = {
        'operation': Operations.leaveCall,
        'call_id': callId
      };

      this.messages.next(message);
    }
  }

  private updateAvailableUsers() {
    this.availableUsers = this.connectedUsers.filter( (user) => isNotMember( this.busyUsers, user ) );
    this.availableUsers$.next( this.availableUsers );
  }

  public getAvailableUsers$(): Observable<IdNameModel[]> {
    return this.availableUsers$.asObservable();
  }

  public getRoomIdPublisher$(): Observable<JoinRoom> {
    return this.roomTokenPublisher$.asObservable();
  }

  public getUserDisconnectionPublisher$(): Observable<IdNameModel> {
    return this.userDisconnectionPublisher$.asObservable();
  }

  public getRoomDisconnectionPublisher$(): Observable<JoinRoom> {
    return this.roomDisconnectionPublisher$.asObservable();
  }

  public getCallRequestPublisher$(): Observable<JoinCall> {
    return this.callRequestPublisher$.asObservable();
  }

  private unsubscribeWebsocketMessages() {
    if ( this._messagesSubscription ) {
      this._messagesSubscription.unsubscribe();
      this._messagesSubscription = null;
    }
  }


  private subscribeWebsocketMessages() {
    if ( this.messages && !this._messagesSubscription ) {
      this._messagesSubscription = this.messages.subscribe(
        this.manageServerMessages.bind(this),
        this.manageServerMessagesError.bind(this)
      );
    }
  }

  private manageServerMessages( msg: LobbyMessageModel ) {
    console.log('Response from websocket: ' + msg);
    if ( msg.operation === Operations.updateUser ) {
      this.updateUser( msg );
    }
    if ( msg.operation === Operations.callRequest ) {
      this.publishCallRequest( msg );
    }
    if ( msg.operation === Operations.startWebrtc ) {
      this.publishStartWebRTC( msg );
    }
    if ( msg.operation === Operations.endWebrtc ) {
      this.publishEndWebRTC( msg );
    }
  }

  private manageServerMessagesError( error ) {
    console.log( 'Lobby Socket Messages Subscription Error, restarting socket' );
    this.disconnectServer();
    this.connectServer();
  }

  private updateUser( msg: LobbyMessageModel ) {
    const user: IdNameModel = {
      id: msg.user_id,
      name: msg.user_name
    };
    switch ( msg.user_status ) {
      case UserStatus.connected:
        affiliate( this.connectedUsers, user );
        disaffiliate( this.busyUsers, user );
        break;
      case UserStatus.disconnected:
        disaffiliate( this.connectedUsers, user );
        disaffiliate( this.busyUsers, user );
        this.userDisconnectionPublisher$.next(user);
        break;
      case UserStatus.busy:
        affiliate( this.busyUsers, user );
        break;
    }
    this.updateAvailableUsers();
  }

  private publishCallRequest( msg: LobbyMessageModel ) {
    this.callRequestPublisher$.next({
      callId: msg.call_id,
      fromUserId: msg.from_user_id,
      userIds: msg.user_ids
    } );
  }

  private publishStartWebRTC( msg: LobbyMessageModel ) {
    this.joinCall ( msg.call_id );
    this.roomTokenPublisher$.next({
      callId: msg.call_id,
      token: msg.webrtc_token
    } );
    this.callTokenTable[msg.call_id] = msg.webrtc_token;
  }

  private publishEndWebRTC( msg: LobbyMessageModel ) {
    this.leaveCall ( msg.call_id );
    this.roomDisconnectionPublisher$.next({
      callId: msg.call_id,
      token: this.callTokenTable[msg.call_id]
    } );
  }
}
