import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {MatTreeModule} from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';

import {EditLabelModule} from '@components/edit-label/edit-label.module';

import { ItemTreeComponent } from './item-tree.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';

@NgModule({
  declarations: [ItemTreeComponent ],
  exports: [ ItemTreeComponent ],
  imports: [
    EditLabelModule,
    CommonModule,
    FormsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule
  ]
})
export class ItemTreeModule { }
