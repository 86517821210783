import { Injectable } from '@angular/core';
import {StoreService} from '@common/services/store.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment as env_const} from '@env/environment';

import {
  ComponentTypesResponseModel,
  FoldersResponseModel,
  ObjectTypesResponseModel,
  RolesResponseModel
} from '@common/models/response-model';
import {TypeModel, RoleModel, TreeNodeModel} from '@common/models/id-name-model';
import {ComponentTypeModel} from '@common/models/component-types-model';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GenericRestService {
  private apiUrl = env_const.apiUrl; // + '/credentials/';

  private accessibilityTypes: TypeModel[];
  private assetTypes: TypeModel[];
  private projectTypes: TypeModel[];
  private stateTypes: TypeModel[];
  private sceneTypes: TypeModel[];
  private objectTypes: TypeModel[];
  private componentTypes: ComponentTypeModel[];
  private roles: RoleModel[];
  private developmentFolders: TreeNodeModel;
  private organizationFolders: TreeNodeModel;
  private publicFolders: TreeNodeModel;

  static fulfillParentFolderTree ( folders: TreeNodeModel ) {
    const fulfillParentHierarchy = ( folder: TreeNodeModel ) => {
      if ( folder.children ) {
        folder.children.forEach( ( child: TreeNodeModel ) => {
          child.parentId = folder.id;
          child.parent = folder;
          fulfillParentHierarchy ( child );
        } );
      }
    };

    fulfillParentHierarchy ( folders );
  }

  static fullfillDefaultIcon ( types: TypeModel[], defaultIcon: string ) {
    types.forEach( type => {
      if ( !type.icon ) { type.icon = defaultIcon; }
    } );
  }

  constructor(
    private translate: TranslateService,
    private store: StoreService,
    private http: HttpClient
  ) {
    this.setupFetchFolders();

    this.subscribeToUserChange();

    this.subscribeToLanguageChange();
  }

  private setupFetchFolders() {
    this.store.setupFetchFolders(
      this.fetchUserFolders.bind(this),
      this.fetchDevelopmentFolders.bind(this),
      this.fetchOrganizationFolders.bind(this),
      this.fetchPublicFolders.bind(this)
    );
  }

  private subscribeToUserChange() {
    this.store.getUserName$().subscribe( this.updateWhenNewUserLogin.bind(this) );
    // this.store.getRefreshToken$().subscribe( this.updateWhenNewUserLogin.bind(this) );
  }

  private subscribeToLanguageChange () {
    // const languageChangeSubscription =
    this.translate.onLangChange.subscribe(
      ( event: LangChangeEvent ) =>  {
        if ( this.roles ) {
          this.updateRoles ( event.translations.roles, this.roles );
        }
        if ( this.objectTypes ) {
          this.updateObjectTypes ( event.translations.objectTypes, this.objectTypes );
        }
        if ( this.assetTypes ) {
          this.updateAssetTypes ( event.translations.assetTypes, this.assetTypes );
        }
        if ( this.accessibilityTypes ) {
          this.updateAccessibilityTypes ( event.translations.accessibilityTypes, this.accessibilityTypes );
        }
        if ( this.projectTypes ) {
          this.updateProjectTypes ( event.translations.projectTypes, this.projectTypes );
        }
        if ( this.developmentFolders ) {
          this.updateDevelopmentFolders(
            this.developmentFolders, event.translations.folders.rootDevelopment
          );
        }
        if ( this.organizationFolders ) {
          this.updateOrganizationFolders(
            this.organizationFolders, event.translations.folders.rootOrganization
          );
        }
        if ( this.publicFolders ) {
          this.updatePublicFolders(
            this.publicFolders, event.translations.folders.rootPublic
          );
        }
      }
    );
  }

  private translateDevelopmentRootFolderName ( devFolders: TreeNodeModel ) {
    this.translate.get('folders.rootDevelopment').subscribe(
      (name: string) => this.updateDevelopmentFolders ( devFolders, name )
    );
  }

  private updateDevelopmentFolders ( devFolders: TreeNodeModel, rootNameTranslation: string ) {
    devFolders.name = rootNameTranslation;
    this.developmentFolders = devFolders;

    this.store.updateDevelopmentFolders(devFolders);
  }

  private translateOrganizationRootFolderName ( orgFolders: TreeNodeModel ) {
    this.translate.get('folders.rootOrganization').subscribe(
      (name: string) => this.updateOrganizationFolders ( orgFolders, name )
    );
  }

  private updateOrganizationFolders ( orgFolders: TreeNodeModel, rootNameTranslation: string ) {
    orgFolders.name = rootNameTranslation;
    this.organizationFolders = orgFolders;

    this.store.updateOrganizationFolders(orgFolders);
  }

  private translatePublicRootFolderName ( publicFolders: TreeNodeModel ) {
    this.translate.get('folders.rootPublic').subscribe(
      (name: string) => this.updatePublicFolders ( publicFolders, name )
    );
  }

  private updatePublicFolders ( publicFolders: TreeNodeModel, rootNameTranslation: string ) {
    publicFolders.name = rootNameTranslation;
    this.publicFolders = publicFolders;

    this.store.updatePublicFolders(publicFolders);
  }

  private translateRoleNames ( roles: RoleModel[] ) {
    const roleKeys = roles.map( role => 'roles.' + role.i18n_key );
    // translateRoleNames
    this.translate.get(roleKeys).subscribe(
      (names: Object) => {
        const roleNames = {};
        roles.forEach( role => roleNames[role.i18n_key] = names['roles.' + role.i18n_key] );

        this.updateRoles( roleNames, roles );
      }
    );
  }

  private updateRoles ( rolesTranslation: Object, roles: RoleModel[] ) {
    this.roles = roles.map( role => {
      role.name = rolesTranslation[role.i18n_key];
      return role;
    } );

    this.store.updateRoles( this.roles );
  }

  private translateAccessibilityTypeNames ( accessibilityTypes: TypeModel[] ) {
    const accessibilityTypeKeys = accessibilityTypes.map( accessibilityType => 'accessibilityTypes.' + accessibilityType.i18n_key );
    // translateAccessibilityTypeNames
    this.translate.get(accessibilityTypeKeys).subscribe(
      (names: Object) => {
        const accessibilityTypeNames = {};
        accessibilityTypes.forEach(
          accessibilityType => accessibilityTypeNames[accessibilityType.i18n_key] = names['accessibilityTypes.' + accessibilityType.i18n_key]
        );

        this.updateAccessibilityTypes( accessibilityTypeNames, accessibilityTypes );
      }
    );
  }

  private updateAccessibilityTypes ( accessibilityTypesTranslation: Object, accessibilityTypes: TypeModel[] ) {
    this.accessibilityTypes = accessibilityTypes.map( accessibilityType => {
      accessibilityType.name = accessibilityTypesTranslation[accessibilityType.i18n_key];
      return accessibilityType;
    } );

    this.store.updateAccessibilityTypes( this.accessibilityTypes );
  }

  private translateAssetTypeNames ( assetTypes: TypeModel[] ) {
    const assetTypeKeys = assetTypes.map( assetType => 'assetTypes.' + assetType.i18n_key );
    // translateAssetTypeNames
    if (assetTypeKeys?.length) {
      this.translate.get(assetTypeKeys).subscribe(
        (names: Object) => {
          const translatedTypeNames = {};
          assetTypes.forEach( assetType => translatedTypeNames[assetType.i18n_key] = names['assetTypes.' + assetType.i18n_key] );

          this.updateAssetTypes( translatedTypeNames, assetTypes );
        }
      );
    }
  }

  private updateAssetTypes ( assetTypesTranslation: Object, assetTypes: TypeModel[] ) {
    this.assetTypes = assetTypes.map( assetType => {
      assetType.name = assetTypesTranslation[assetType.i18n_key];
      return assetType;
    } );

    this.store.updateAssetTypes( this.assetTypes );
  }

  private translateObjectTypeNames ( objectTypes: TypeModel[] ) {
    const objectTypeKeys = objectTypes.map( objectType => 'objectTypes.' + objectType.i18n_key );
    // translateAssetTypeNames
    this.translate.get(objectTypeKeys).subscribe(
      (names: Object) => {
        const translatedTypeNames = {};
        objectTypes.forEach( objectType => translatedTypeNames[objectType.i18n_key] = names['objectTypes.' + objectType.i18n_key] );

        this.updateObjectTypes( translatedTypeNames, objectTypes );
      }
    );
  }

  private updateObjectTypes ( objectTypesTranslation: Object, objectTypes: TypeModel[] ) {
    this.objectTypes = objectTypes.map( objectType => {
      objectType.name = objectTypesTranslation[objectType.i18n_key];
      return objectType;
    } );

    this.store.updateObjectTypes( this.objectTypes );
  }

  private translateProjectTypeNames ( projectTypes: TypeModel[] ) {
    const projectTypeKeys = projectTypes.map( projectType => 'projectTypes.' + projectType.i18n_key );
    // translateProjectTypeNames
    if (projectTypeKeys?.length) {
      this.translate.get(projectTypeKeys).subscribe(
        (names: Object) => {
          const translatedTypeNames = {};
          projectTypes.forEach( projectType => translatedTypeNames[projectType.i18n_key] = names['projectTypes.' + projectType.i18n_key] );

          this.updateProjectTypes( translatedTypeNames, projectTypes );
        }
      );
    }
  }

  private updateProjectTypes ( projectTypesTranslation: Object, projectTypes: TypeModel[] ) {
    this.projectTypes = projectTypes.map( projectType => {
      projectType.name = projectTypesTranslation[projectType.i18n_key];
      return projectType;
    } );

    this.store.updateProjectTypes( this.projectTypes );
  }

  private updateWhenNewUserLogin( userName: string ) {
    if ( userName ) {
      if ( userName !== 'admin' ) {
        this.updateFolders();
      }
      this.updateGenericData();
    }
  }

  private updateFolders ( ) {
    this.fetchUserFolders( );
    this.fetchDevelopmentFolders( );
    this.fetchOrganizationFolders( );
    this.fetchPublicFolders( );
  }

  private fetchUserFolders ( ) {
    this.get_user_folders$()
      .subscribe(
        this.onGetUserFolder.bind(this),
        error => {
          throw new Error(error || 'Error retrieving user folders.');
        }
      );
  }
  private fetchDevelopmentFolders ( ) {
    this.get_development_folders$()
      .subscribe(
        this.onGetDevelopmentFolder.bind(this),
        error => { throw new Error( error || 'Error retrieving development folders.' ); }
      );
  }
  private fetchOrganizationFolders ( ) {
    this.get_organization_folders$()
      .subscribe(
        this.onGetOrganizationFolder.bind(this),
        error => { throw new Error( error || 'Error retrieving organization folders.' ); }
      );
  }
  private fetchPublicFolders ( ) {
    this.get_public_folders$()
      .subscribe(
        this.onGetPublicFolder.bind(this),
        error => { throw new Error( error || 'Error retrieving public folders.' ); }
      );
  }

  private updateGenericData ( ) {
    this.get_asset_types$()
      .subscribe(
        this.onGetAssetTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving asset types.' ); }
      );

    this.get_accessibility_types$()
      .subscribe(
        this.onGetAccessibilityTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving accessibility types.' ); }
      );

    this.get_project_types$()
      .subscribe(
        this.onGetProjectTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving project types.' ); }
      );

    this.get_project_states$()
      .subscribe(
        this.onGetStateTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving project states.' ); }
      );

    this.get_scene_types$()
      .subscribe(
        this.onGetSceneTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving scene types.' ); }
      );

    this.get_component_types$()
      .subscribe(
        this.onGetComponentTypes.bind(this),
        error => {
          throw new Error( error || 'Error retrieving component types.' );
        }
      );

    this.get_object_types$()
      .subscribe(
        this.onGetObjectTypes.bind(this),
        error => { throw new Error( error || 'Error retrieving object types.' ); }
      );


    this.get_roles$()
      .subscribe(
        this.onGetRoles.bind(this),
        error => { throw new Error( error || 'Error retrieving roles.' ); }
      );
  }

  private onGetAccessibilityTypes(responseData) {
    if (responseData && responseData.success && responseData.accessibility_types) {
      // this.accessibilityTypes = responseData.accessibility_types;
      // this.store.updateAccessibilityTypes(this.accessibilityTypes);
      this.translateAccessibilityTypeNames( responseData.accessibility_types );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetAssetTypes(responseData) {
    if (responseData && responseData.success && responseData.asset_types) {
      GenericRestService.fullfillDefaultIcon( responseData.asset_types, env_const.default.assetImg );
      // this.assetTypes = responseData.asset_types;
      // this.store.updateAssetTypes(this.assetTypes);
      this.translateAssetTypeNames( responseData.asset_types );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetProjectTypes(responseData) {
    if (responseData && responseData.success && responseData.project_types) {
      GenericRestService.fullfillDefaultIcon( responseData.project_types, env_const.default.projectImg );
      // this.projectTypes = responseData.project_types;
      // this.store.updateProjectTypes(this.projectTypes);
      this.translateProjectTypeNames ( responseData.project_types );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetStateTypes(responseData) {
    if (responseData && responseData.success && responseData.states) {
      this.stateTypes = responseData.states;

      this.store.updateStateTypes(this.stateTypes);
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetSceneTypes(responseData) {
    if (responseData && responseData.success && responseData.scene_types) {
      this.sceneTypes = responseData.scene_types;

      this.store.updateSceneTypes(this.sceneTypes);
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetComponentTypes(responseData: ComponentTypesResponseModel) {
    if (responseData && responseData.success && responseData.component_types) {
      this.componentTypes = responseData.component_types;

      this.store.updateComponentTypes(this.componentTypes);
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetObjectTypes(responseData: ObjectTypesResponseModel) {
    if (responseData && responseData.success && responseData.object_types) {
      GenericRestService.fullfillDefaultIcon( responseData.object_types, env_const.default.objectImg );
      // this.objectTypes = responseData.object_types;
      // this.store.updateObjectTypes(this.objectTypes);
      this.translateObjectTypeNames( responseData.object_types );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetUserFolder(responseData: FoldersResponseModel) {
    if (responseData && responseData.success && responseData.folders) {
      GenericRestService.fulfillParentFolderTree ( responseData.folders );

      this.store.updateUserFolders( responseData.folders );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetDevelopmentFolder(responseData: FoldersResponseModel) {
    if (responseData && responseData.success && responseData.folders) {
      GenericRestService.fulfillParentFolderTree ( responseData.folders );

      this.translateDevelopmentRootFolderName( responseData.folders );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetOrganizationFolder(responseData: FoldersResponseModel) {
    if (responseData && responseData.success && responseData.folders) {
      GenericRestService.fulfillParentFolderTree ( responseData.folders );

      this.translateOrganizationRootFolderName( responseData.folders );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetPublicFolder(responseData: FoldersResponseModel) {
    if (responseData && responseData.success && responseData.folders) {
      GenericRestService.fulfillParentFolderTree ( responseData.folders );

      this.translatePublicRootFolderName( responseData.folders );
    } else {
      console.error(responseData.errors);
    }
  }

  private onGetRoles(responseData: RolesResponseModel) {
    if (responseData && responseData.success && responseData.roles) {
      this.translateRoleNames ( responseData.roles );
      // this.roles = responseData.roles;

      // this.store.updateRoles(this.roles);
    } else {
      console.error(responseData.errors);
    }
  }

  private get$<T>( restServiceUrlKey: string ): Observable<T> {
    const queryUrl = this.apiUrl + restServiceUrlKey;
    const queryData = new FormData();

    return this.http.post<T>(queryUrl, queryData);
  }

  public get_asset_types$(): Observable<TypeModel> {
    return this.get$<TypeModel>( 'get_asset_types/' );
  }

  public get_accessibility_types$(): Observable<TypeModel> {
    return this.get$<TypeModel>( 'get_accessibility_types/' );
  }

  public get_project_types$(): Observable<TypeModel> {
    return this.get$<TypeModel>( 'get_project_types/' );
  }

  public get_project_states$(): Observable<TypeModel> {
    return this.get$<TypeModel>( 'get_states/' );
  }

  public get_scene_types$(): Observable<TypeModel> {
    return this.get$<TypeModel>( 'get_scene_types/' );
  }

  public get_component_types$(): Observable<ComponentTypesResponseModel> {
    return this.get$<ComponentTypesResponseModel>( 'get_component_types/' );
  }

  public get_object_types$(): Observable<ObjectTypesResponseModel> {
    return this.get$<ObjectTypesResponseModel>( 'get_object_types/' );
  }

  public get_roles$(): Observable<RolesResponseModel> {
    return this.get$<RolesResponseModel>( 'get_roles/' );
  }

  public get_user_folders$(): Observable<FoldersResponseModel> {
    return this.get$<FoldersResponseModel>( 'get_user_folders/' );
  }

  public get_development_folders$(): Observable<FoldersResponseModel> {
    return this.get$<FoldersResponseModel>( 'get_development_folders/' );
  }

  public get_organization_folders$(): Observable<FoldersResponseModel> {
    return this.get$<FoldersResponseModel>( 'get_organization_folders/' );
  }

  public get_public_folders$(): Observable<FoldersResponseModel> {
    return this.get$<FoldersResponseModel>( 'get_public_folders/' );
  }
}
