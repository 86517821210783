import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import * as DetectRTC from 'detectrtc';

// @ts-ignore
const speechRecognitionAvailable = window.SpeechRecognition || window.webkitSpeechRecognition;
if (!speechRecognitionAvailable) { console.log('SpeechRecognition not available'); }
// @ts-ignore
const isGetDeviceMediaSupported = !!(navigator.getDisplayMedia || (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia));
if (!isGetDeviceMediaSupported) { console.log('Device Media Capture not available'); }
let isMicrophoneAllowed = false;

const isWindowsPC = navigator.platform && (navigator.platform.indexOf('Win32') !== -1 || navigator.platform.indexOf('Win64') !== -1 );

@Injectable({
  providedIn: 'root'
})
export class DetectService {

  private isMicrophoneSupported$ = new BehaviorSubject<boolean>(
    false
  );
  private isWebcamSupported$ = new BehaviorSubject<boolean>(
    false
  );
  private isSpeakersSupported$ = new BehaviorSubject<boolean>(
    false
  );

  private isMicrophoneAccessAllowed$ = new BehaviorSubject<boolean>(
    false
  );

  constructor() {
    // https://www.npmjs.com/package/detectrtc
    // Load method, If you're not detecting audio/video input/output devices then you can skip this method.
    DetectRTC.load(() => {
      if ( !DetectRTC.isWebRTCSupported ) {
        console.warn('Please use Chrome or Firefox to use WebRTC communications.');
      }
      DetectRTC.checkWebSocketsSupport(() => { } );
      console.log(DetectRTC.browser);
      this.isMicrophoneSupported$.next(DetectRTC.hasMicrophone);
      this.isWebcamSupported$.next(DetectRTC.hasWebcam);
      this.isSpeakersSupported$.next(DetectRTC.hasSpeakers);
    } );
    this.detectMicrophonePermission();
  }

  private detectMicrophonePermission() {
    // Only valid for Chrome
    if ( !navigator?.permissions?.query ) { return; }
    // https://www.webrtc-developers.com/how-to-know-if-my-microphone-works/
    const detectPermission = ( permission ) => {
      if ( permission.state === 'granted' ) {
        // OK - Access has been granted to the microphone
        isMicrophoneAllowed = true;
      } else if ( permission.state === 'denied' ) {
        // KO - Access has been denied. Microphone can't be used
        isMicrophoneAllowed = false;
      } else {
        // Permission should be asked
        isMicrophoneAllowed = undefined;
      }
      this.isMicrophoneAccessAllowed$.next(isMicrophoneAllowed);
    };
    navigator.permissions.query({ name: 'microphone' as PermissionName } ).then(
      ( permission ) => {
        detectPermission( permission );
        permission.onchange = ( permissionOnChange ) => {
          // React when the permission changed
          detectPermission( permissionOnChange );
        };
      }
    );
  }
  private detectMicrophonePermission_alternative() {
    if ( !navigator?.mediaDevices?.getUserMedia ) { return; }
    // https://www.webrtc-developers.com/how-to-know-if-my-microphone-works/
    navigator.mediaDevices.getUserMedia({audio: true}).then(
      (stream) => {
        const audioTracks = stream.getAudioTracks();

        if (audioTracks.length === 0) {
          // No audio from microphone has been captured
          return;
        }

        // We asked for the microphone so one track
        const track = audioTracks[0];
        if (track.muted) {
          // Track is muted which means that the track is unable to provide media data.
          // When muted, a track can't be unmuted.
          // This track will no more provide data...
        }

        if (!track.enabled) {
          // Track is disabled (muted for telephonist) which means that the track provides silence instead of real data.
          // When disabled, a track can be enabled again.
          // When in that case, user can't be heard until track is enabled again.
        }

        if (track.readyState === 'ended') {
          // Possibly a disconnection of the device
          // When ended, a track can't be active again
          // This track will no more provide data
        }
      }
    ).catch(
      ( error ) => {
        // Errors when accessing the device
        console.log('Error accessing the microphone: ' + error?.message, error );
        isMicrophoneAllowed = false;
        this.isMicrophoneAccessAllowed$.next(isMicrophoneAllowed);
      }
    );
  }

  getIsWindowsPC(): boolean {
    return isWindowsPC;
  }

  getWebRTCShareScreenMicrophoneSendSupported(): boolean {
    return DetectRTC.isWebRTCSupported && this.isMicrophoneSupported$.getValue() && DetectRTC.isScreenCapturingSupported;
  }

  getWebRTCShareScreenMicrophoneReceiveSupported(): boolean {
    return DetectRTC.isWebRTCSupported && this.isMicrophoneSupported$.getValue();
  }

  getIsMicrophoneAccessAllowed$(): Observable<boolean> {
    return this.isMicrophoneAccessAllowed$.asObservable();
  }

  getIsMicrophoneAccessAllowed(): boolean {
    return this.isMicrophoneAccessAllowed$.getValue();
  }

  getIsGetDeviceMediaSupported(): boolean {
    return isGetDeviceMediaSupported;
  }

  getIsGetUserMediaSupported(): boolean {
    return DetectRTC.isGetUserMediaSupported;
  }

  getIsWebRTCSupported(): boolean {
    return DetectRTC.isWebRTCSupported;
  }

  getIsSpeechRecognitionAvailable(): boolean {
    return isGetDeviceMediaSupported;
  }

  getIsMicrophoneSupported$(): Observable<boolean> {
    return this.isMicrophoneSupported$.asObservable();
  }

  getIsMicrophoneSupported(): boolean {
    return this.isMicrophoneSupported$.getValue();
  }

  getIsWebcamSupported$(): Observable<boolean> {
    return this.isWebcamSupported$.asObservable();
  }

  getIsWebcamSupported(): boolean {
    return this.isWebcamSupported$.getValue();
  }

  getIsSpeakersSupported$(): Observable<boolean> {
    return this.isSpeakersSupported$.asObservable();
  }

  getIsSpeakersSupported(): boolean {
    return this.isSpeakersSupported$.getValue();
  }
}
