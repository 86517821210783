import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
  ValidationErrors,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ErrorStateMatcher } from '@angular/material/core';

import { Observable } from 'rxjs';

import {
  LoginResponseModel,
  ResponseModel,
  TokenResponseModel,
} from '@common/models/response-model';
import { environment as env_const } from '@env/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(
      control &&
      control.invalid &&
      (control.parent.dirty || control.parent.touched)
    );
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      (control.parent.dirty || control.parent.touched)
    );

    return invalidCtrl || invalidParent;
  }
}

const checkPasswords = (group: UntypedFormGroup): ValidationErrors => {
  // here we have the 'passwords' group
  const password = group.controls.password.value;
  const confirmPassword = group.controls.confirmPassword.value;

  return password === confirmPassword ? null : { notSame: true };
};

export interface ChangeUserPwdDialogData {
  userId: number;
  username: string;
  pwdLabel: string;
  confirmPwdLabel: string;
  btnOkLabel: string;
  btnCancelLabel: string;
}

@Component({
  selector: 'tiwp-change-user-pwd-dialog',
  templateUrl: './change-user-pwd-dialog.component.html',
  styleUrls: ['./change-user-pwd-dialog.component.scss'],
})
export class ChangeUserPwdDialogComponent {
  myForm: UntypedFormGroup;

  matcher = new MyErrorStateMatcher();

  dialogData: ChangeUserPwdDialogData;
  private apiUrl = env_const.apiUrl;
  _userId: number;
  _username: string;
  _newPassword: string;
  _passwordRepetition: string;

  _hideText = true;

  readonly messagesErrorCommonTranslationPath = 'common.messages.errors.';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private dialogRef: MatDialogRef<ChangeUserPwdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeUserPwdDialogData
  ) {
    this._hideText = true;
    this.dialogData = data;
    this._userId = data.userId;
    this._username = data.username;
    this._newPassword = '';
    this._passwordRepetition = '';

    this.myForm = this.formBuilder.group(
      {
        password: [
          this._newPassword,
          [
            Validators.required,
            Validators.minLength(5) /*,
        Validators.maxLength(10),
        Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9.]+$')*/,
          ],
        ],
        confirmPassword: [this._passwordRepetition, [Validators.required]],
      },
      { validator: checkPasswords }
    );

    // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    // Minimum eight characters, at least one letter and one number:
    //   "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
    // Minimum eight characters, at least one letter, one number and one special character:
    //  "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
    // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
    //  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    //  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
    // Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    //  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
  }

  public changeUserPwd$(
    password: string,
    userId: number
  ): Observable<ResponseModel> {
    const loginServiceUrl = this.apiUrl + 'change_password/';
    const loginData = new FormData();
    loginData.set('new_password', password);
    loginData.set('user_id', userId.toString());

    return this.http.post<ResponseModel>(loginServiceUrl, loginData);
  }

  public getNewToken$(
    password: string,
    username: string
  ): Observable<TokenResponseModel> {
    const loginServiceUrl = this.apiUrl + 'token/new.json';
    const loginData = new FormData();
    loginData.set('user', username);
    loginData.set('password', password);

    return this.http.post<TokenResponseModel>(loginServiceUrl, loginData);
  }

  private login$(
    username: string,
    password: string
  ): Observable<LoginResponseModel> {
    const loginServiceUrl = this.apiUrl + 'login/';
    const loginData = new FormData();
    loginData.set('user', username);
    loginData.set('password', password);

    return this.http.post<LoginResponseModel>(loginServiceUrl, loginData);
  }

  public onOk() {
    if (this._newPassword && this._newPassword === this._passwordRepetition) {
      const password = this._newPassword;
      const username = this._username;
      this.changeUserPwd$(password, this._userId).subscribe(
        (result) => {
          if (result.success) {
            this.updateLogin(password, username);
          } else {
            console.error(
              '%cError on change password: ',
              'color:black;background:red',
              result.errors
            );
            this.dialogRef.close();
          }
        },
        (error) => {
          console.error(
            '%cError on change password: ',
            'color:black;background:red',
            error.message
          );
          this.dialogRef.close();
        }
      );
    }
  }

  public updateLogin(password: string, username: string) {
    if (this._newPassword && this._newPassword === this._passwordRepetition) {
      this.login$(username, password).subscribe(
        (result) => {
          if (result.success) {
            this.dialogRef.close(result);
          } else {
            console.error(
              '%cError getting New token after password change: ',
              'color:black;background:red',
              result.errors
            );
            this.dialogRef.close(null);
          }
        },
        (error) => {
          console.error(
            '%cError getting New token after password change: ',
            'color:black;background:red',
            error.message
          );
          this.dialogRef.close();
        }
      );
    }
  }
}
