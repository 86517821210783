import {IdNameIconModel, IdNameModel, ObjectTypeModel, TypeModel} from '@common/models/id-name-model';
import {
  LinkedItemsComponent,
  OrderComponent,
  QuestionComponent,
  QuestionComponentType
} from '@common/models/component-types-model';
import {ResponseModel} from '@common/models/response-model';


// function padZeros ( number, numberOfZeros) { return number.toString().padStart(numberOfZeros, '0'); };
// function pad(num, size){ return ('000000000' + num).substr(-size); }
// tslint:disable-next-line:no-shadowed-variable
export const padZeros = function padZeros ( number: number, numberOfDigits: number): string {
  let str = number.toString();
  while (str.length < numberOfDigits) { str = '0' + str; }
  return str;
};

export interface SelectionType {
  name: string;
  nameKey?: string;
  uiImage: string;
  id?: number | string;
  type?: TypeModel | QuestionComponentType;
  disabled?: boolean;
}

export interface NewUser {
  username: string;
  password: string;
  roleId: number;
  imageFile?: File;
  firstname?: string;
  lastname?: string;
  email?: string;
  nick?: string;
}


export enum UserRole {
  none = 0,
  admin = 1,
  editor = 2,
  consumer = 3
}

export interface ItemViewData {
  name: string;
  rawItem?: TiwpSceneObjectModel;
  onListColor?: number;
  noRemove?: boolean;
  timeString?: string;
  timeStringProblem?: boolean;
  isHidden?: boolean;
  isLocked?: boolean;
}

export enum Viewers {
  none = 0,
  glb = 1,
  video = 2,
  audio = 3,
  image = 4,
  pdf = 5,
  panorama = 6,
  fbx = 7,
  gaussianSplatting = 8
}

export interface AssetModel extends IdNameModel {
  accessibility: IdNameModel;
  animado: boolean;
  asset_type_id: TypeModel;
  folder: IdNameModel;
  file: string;
  organization: IdNameModel;
  user_id: IdNameModel;
  tag_ids: string[];
  view?: Viewers;
  type?: TypeModel;
  used_in_publication?: boolean;
  lastModified?: string;
}

export interface TiwpSceneObjectModel {
  id: number;
  name: string;
  object_type_id: ObjectTypeModel;
  parent_id: IdNameModel;
  active: boolean;
  visible: boolean;
  components: any; // any[];
  asset?: AssetModel;
  isHidden?: boolean; // Is object hidden (in editor)
  isLocked?: boolean; // is object locked (in editor)
  objectEntity?: any;
}

export interface ObjectEditModel {
  name?: string;
  typeId?: number;
  parentId?: number;
  active?: boolean;
  visible?: boolean;
  components?: any;
  assetId?: number;
}

export interface GeometryQuestionObject extends TiwpSceneObjectModel, ItemViewData {
  components: {
    order: OrderComponent;
    linkedItems: LinkedItemsComponent;
    question: QuestionComponent;
  };
}

export interface SceneModel extends IdNameModel {
  scene_type_id: IdNameIconModel;
  accessibility: IdNameModel;
  num_order: number;
  objects: TiwpSceneObjectModel[];
}

export interface SceneBasicModel extends IdNameModel {
  num_order: number;
  type_id: number;
}

export interface ProjectScenesModel extends ResponseModel {
  project: ProjectModel;
  scenes: SceneModel[];
}

export interface ProjectModel extends IdNameModel {
  accessibility: IdNameModel;
  file: string;
  folder: IdNameModel;
  logo: string;
  project_type_id: IdNameModel;
  urlJsonDescriptor: any;
  description: string;
  organization: IdNameModel;
  user_id: IdNameModel;
  state: IdNameModel;
  num_order?: number;
  tag_ids?: string[];
  lastModified?: string;
  scenes?: SceneBasicModel[];
}

export type JsonValue = string | number | boolean | KeyValueModel | string[] | number[] | boolean[] | KeyValueModel[];
export interface KeyValueModel {
  [s: string]: JsonValue;
}
export type ListenCallbackModel = (dictionary: KeyValueModel ) => void;
