import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env_const } from '@env/environment';

import { LoginResponseModel, UserInfoModel } from '@common/models/response-model';
import { AccessRefreshTokenResponse } from '@app/common/models/access-refresh-token-model';

@Injectable()
export class CredentialsService {
  private apiUrl = env_const.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  public getToken$(username: string, password: string): Observable<AccessRefreshTokenResponse> {
    if (  env_const.loginAccessRegistration ) {
      const tag = env_const.clientWebLoginRegisterKey;
      return this.http.post<AccessRefreshTokenResponse>(`${this.apiUrl}token/`, {username, password, tag});
    } else {
      return this.http.post<AccessRefreshTokenResponse>(`${this.apiUrl}token/`, {username, password});
    }
  }

  public getUserInfo$(): Observable<UserInfoModel> {
    return this.http.get<UserInfoModel>(`${this.apiUrl}get_users/info/`);
  }

  public register$(registrationData): Observable<LoginResponseModel> {
    // service "registration"
    const registrationServiceUrl = this.apiUrl + 'register/';
    const registrationFormData = new FormData();
    registrationFormData.set( 'user', registrationData.user );
    registrationFormData.set( 'password', registrationData.password );

    return this.http.post<LoginResponseModel>(registrationServiceUrl, registrationFormData);
  }
}
