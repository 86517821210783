<!--
<h1  mat-dialog-title>Message:</h1>
-->
<mat-dialog-content class="message-text-color" *ngIf="data.messageKey else notTranslatedMessage">
  {{data.messageKey | translate}}
</mat-dialog-content>
<ng-template #notTranslatedMessage>
  <mat-dialog-content class="message-text-color" >  {{data.message}} </mat-dialog-content>
</ng-template>
<!--
  <mat-dialog-actions>
      <button  mat-raised-button (click)="closeMe()">Close</button>
  </mat-dialog-actions>
-->
