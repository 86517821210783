<h1 mat-dialog-title class="m-0 flex justify-center">
  <span class="m-auto">
    {{ data.titleKey ? (data.titleKey | translate) : data.title }}
  </span>
</h1>
<div mat-dialog-content class="max-h-fit m-6 p-6">
  <div class="flex overflow-hidden border border-solid border-black/[.12]">
    <tiwp-item-tree
      [selectedNode]="data.selectedFolder"
      [editable]="false"
      [startExpanded]="true"
      [itemsTree]="data.folders"
      (click)="stopPropagation($event)"
      (itemClick)="selectFolder($event)"
    >
    </tiwp-item-tree>
  </div>
</div>
