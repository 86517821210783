import { NgModule } from '@angular/core';

import { PlmService } from '@common/plm/plm.service';
import { PlmAssetSelectionComponent } from './plm-select-asset/plm-asset-selection.component';
import { PlmAssetSelectionDialogComponent } from './plm-asset-selection-dialog/plm-asset-selection-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CustomMaterialModule } from '@common/material.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [PlmAssetSelectionDialogComponent, PlmAssetSelectionComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        CommonModule,
        CustomMaterialModule,
        TranslateModule.forChild({
            extend: true
        })
    ],
    exports: [],
    providers: [
        PlmService
    ]
})

export class PlmModule { }
/*
export class SharedModule {
  constructor (@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error(
        'SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
*/
