import {
  Component,
  AfterViewInit,
  Inject,
  ElementRef,
  Renderer2,
} from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';

export interface SpinnerDialogData {
  title?: string;
  color?: string;
  mode?: string;
  initialAmount?: number;
  timeout?: number;
  veryLongTime?: boolean;
}

/**
  Alternativa de Spinner
  https://www.npmjs.com/package/ngx-loading
*/

@Component({
  selector: 'tiwp-spinner-dialog',
  templateUrl: './spinner-dialog.component.html',
  styleUrls: ['./spinner-dialog.component.scss'],
})
export class SpinnerDialogComponent implements AfterViewInit {
  title = 'Loading...';
  mode = <ProgressSpinnerMode>'indeterminate'; // 'determinate'
  amount = 0;
  veryLongTime = false;

  constructor(
    private dialogRef: MatDialogRef<SpinnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SpinnerDialogData,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    // https://stackoverflow.com/questions/46772852/disable-click-outside-of-angular-material-dialog-area-to-close-the-dialog-with
    dialogRef.disableClose = true;

    this.title = data.title || '';

    if (data.mode === 'determinate') {
      this.mode = 'determinate';
      this.amount = data.initialAmount || 0;
    } else {
      this.mode = 'indeterminate';
      this.amount = 0;
    }

    this.veryLongTime = data.veryLongTime === true;

    if (data.timeout) {
      setTimeout(function closeDialog() {
        dialogRef.close();
      }, data.timeout);
    }

    /*
    // Para permitir que la tecla ESC cierre el cuadro de diálogo
    dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      dialogRef.close();
    } );
*/
  }

  /*
  // Para permitir que la tecla ESC cierre el cuadro de diálogo
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }
*/

  ngAfterViewInit() {
    // https://stackoverflow.com/questions/51410660/angular-afterviewinit-vs-aftercontentinit
    if (this.elRef !== undefined) {
      const parent = this.renderer.parentNode(this.elRef.nativeElement);
      this.renderer.setStyle(parent, 'box-shadow', 'none');
      this.renderer.setStyle(parent, 'background', 'rgba(0, 0, 0, 0.0)');
    }
  }

  public updateTitle(shortText: string) {
    this.title = shortText;
  }

  public setAmount(amount: number) {
    this.amount = amount;
  }

  public closeMe() {
    this.dialogRef.close();
  }
}
