<mat-dialog-content class="p-6">
  <form
    [formGroup]="myForm"
    autocomplete="off"
    class="w-full min-w-[150px] max-w-[500px] flex flex-col gap-4"
  >
    <mat-form-field class="w-full">
      <input type="password" hidden name="password" tabindex="-1" />
      <input
        matInput
        cdkFocusInitial
        [placeholder]="dialogData.pwdLabel"
        [(ngModel)]="_newPassword"
        (keydown.enter)="onOk()"
        [type]="_hideText ? 'password' : 'text'"
        autocomplete="new-password"
        formControlName="password"
        name="password"
        tabindex="1"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="_hideText = !_hideText"
        tabindex="-1"
      >
        <mat-icon class="cursor-pointer align-top">
          {{ _hideText ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </button>
      <mat-error
        *ngIf="
          myForm.controls.password.errors &&
          (myForm.controls.password.dirty || myForm.controls.password.touched)
        "
      >
        <mat-error *ngIf="myForm.hasError('required', 'password')">
          {{
            messagesErrorCommonTranslationPath + 'requiredPassword' | translate
          }}
        </mat-error>
        <mat-error *ngIf="myForm.hasError('minlength', 'password')">
          {{
            messagesErrorCommonTranslationPath + 'minLength'
              | translate : { length: 5 }
          }}
        </mat-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <input type="password" hidden name="confirmPassword" tabindex="-1" />
      <input
        matInput
        [placeholder]="dialogData.confirmPwdLabel"
        [(ngModel)]="_passwordRepetition"
        (keydown.enter)="onOk()"
        [type]="_hideText ? 'password' : 'text'"
        [errorStateMatcher]="matcher"
        autocomplete="new-password"
        formControlName="confirmPassword"
        name="confirmPassword"
        tabindex="2"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="_hideText = !_hideText"
        tabindex="-1"
      >
        <mat-icon class="cursor-pointer align-top">
          {{ _hideText ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </button>
      <mat-error
        *ngIf="
          myForm.errors &&
          (myForm.controls.confirmPassword.dirty ||
            myForm.controls.confirmPassword.touched)
        "
      >
        <mat-error *ngIf="myForm.hasError('required', 'confirmPassword')">
          {{
            messagesErrorCommonTranslationPath + 'retypePassword' | translate
          }}
        </mat-error>
        <mat-error *ngIf="myForm.hasError('notSame')">
          {{
            messagesErrorCommonTranslationPath + 'matchPasswords' | translate
          }}
        </mat-error>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-raised-button
    class="secondary-button"
    mat-dialog-close
    tabindex="4"
  >
    {{ dialogData.btnCancelLabel }}
  </button>
  <button
    mat-raised-button
    [ngClass]="{
      'primary-button': !myForm.invalid && _passwordRepetition === _newPassword
    }"
    tabindex="3"
    (click)="onOk()"
    [disabled]="myForm.invalid || _passwordRepetition !== _newPassword"
  >
    {{ dialogData.btnOkLabel }}
  </button>
</mat-dialog-actions>
