import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TreeNodeModel} from '@common/models/id-name-model';

export interface ItemTreeEvent {
  node: TreeNodeModel;
  parent?: TreeNodeModel;
  newName?: string;
}

export interface TreeConfirmationInterface {
  confirmAdd: ( node: TreeNodeModel, newId: number | null ) => void;
  confirmRename: ( node: TreeNodeModel, newName: string | null ) => void;
  confirmRemove: ( node: TreeNodeModel ) => void;
}

export const getNode = ( nodeId: number, startNode: TreeNodeModel ): TreeNodeModel => {
  let foundedNode;

  if ( startNode.id === nodeId ) {
    foundedNode = startNode;
  } else if ( startNode.children && startNode.children.length ) {
    for ( let index = 0 ; (!foundedNode && index < startNode.children.length) ; index += 1 ) {
      foundedNode = getNode( nodeId, startNode.children[index] );
    }
  }

  return foundedNode;
};

const getSuffixName = ( parentNode: TreeNodeModel, namePrefix: string ): string => {
  parentNode.children = parentNode.children || [];
  const childrenWithName = parentNode.children.filter( child => child.name.startsWith( namePrefix ) );
  let maxNumber = childrenWithName.length ? 1 : 0;
  childrenWithName.forEach( ( child ) => {
    const endName = child.name.substring( namePrefix.length );
    // tslint:disable-next-line:radix
    const num = parseInt( endName );
    if ( num >= maxNumber ) {
      maxNumber = num + 1;
    }
  } );

  return maxNumber ? '' + maxNumber : '';
};

@Component({
  selector: 'tiwp-item-tree',
  templateUrl: './item-tree.component.html',
  styleUrls: ['./item-tree.component.scss']
})
export class ItemTreeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public selectable = true;
  @Input() public addable = true;
  @Input() public editable = true;
  @Input() public removable = true;
  @Input() public disabled: boolean;
  @Input() public startExpanded: boolean;

  // @Input() public itemsTree: TreeNodeModel;
  @Input()
  get itemsTree(): TreeNodeModel { return this._itemsTree; }
  set itemsTree(folders: TreeNodeModel) {
    if ( this._itemsTree !== undefined ) {
      this._itemsTree = folders || null;
      this.initItemsTree( );
    } else {
      this._itemsTree = folders || null;
    }
  }
  private _itemsTree: TreeNodeModel;

  @Input() public newItemLabelKey: string;
  @Input() public newItemLabel = 'New ';
  @Input() public newNamePrefixKey: string;
  @Input() public newNamePrefix = 'item';

  @Input()
  get selectedNode(): TreeNodeModel { return this._selectedNode; }
  set selectedNode(node: TreeNodeModel) {
    this._selectedNode = node || null;
  }
  private _selectedNode: TreeNodeModel;

  @Output() public itemClick = new EventEmitter<TreeNodeModel>();
  @Output() public add = new EventEmitter<ItemTreeEvent>();
  @Output() public remove = new EventEmitter<ItemTreeEvent>();
  @Output() public rename = new EventEmitter<ItemTreeEvent>();
  @Output() public provideExpandBreadcrumb = new EventEmitter<(node: TreeNodeModel) => void>();
  @Output() public provideConfirmationInterface = new EventEmitter<TreeConfirmationInterface>();
  private mustWaitForConfirmation = false;

  private languageTranslationSubscription: Subscription;

  /** tree control */
  readonly treeControl = new NestedTreeControl<TreeNodeModel>(node => node.children );

  /** tree source stuff */
  readonly treeSource: MatTreeNestedDataSource<TreeNodeModel>;
  readonly dataSource$: BehaviorSubject<TreeNodeModel[]>;
  // dataSource = new MatTreeNestedDataSource<TreeNodeModel>();

  constructor( protected translate: TranslateService ) {
    this.treeSource = new MatTreeNestedDataSource<TreeNodeModel>();

    // this dataSource is not required but its rly. helpfull to think reactive
    this.dataSource$ = new BehaviorSubject<TreeNodeModel[]>([] );
    this.dataSource$.subscribe(items => {
      this.treeSource.data = null;
      this.treeSource.data = items;
    });
  }

  public static getParent ( node: TreeNodeModel, rootNode: TreeNodeModel ): TreeNodeModel {
    let parent;

    if ( node.parent ) {
      if ( node.parent.children.indexOf( node ) !== -1 ) {
        parent = node.parent;
      } else {
        node.parent = undefined;
      }
    }

    if ( !parent && (node.parentId || node.parentId === 0) ) {
      parent = getNode( node.parentId, rootNode );
      if ( parent ) {
        node.parent = parent;
      }
    }

    return parent;
  }

  public static isNodeInNodeTree ( node: TreeNodeModel, rootNode: TreeNodeModel ): boolean {
    if ( node ) {
      return getNode( node.id, rootNode ) !== undefined;
    } else {
      return false;
    }
  }

  readonly hasChild = (_: number, node: TreeNodeModel) => !!node.children && node.children.length > 0;

  readonly trackBy = (_: number, node: TreeNodeModel) => node.id || -1;

  readonly hasNoContent = (_: number, _nodeData: TreeNodeModel) => _nodeData.id === undefined;

  ngOnInit() {
/*
    if ( this.addable === undefined) {
      this.addable = this.add.observers.length > 0;
    }
*/
    this.initItemsTree();

    if ( this.newNamePrefixKey ) {
      this.initLanguageTranslation();
    } else {
      this.languageTranslationSubscription = null;
    }
  }

  private initLanguageTranslation () {
    const updateTranslatedNewNamePrefix = ( newNamePrefix: string ) => {
      this.newNamePrefix = newNamePrefix;
    };

    this.languageTranslationSubscription = this.translate.onLangChange.subscribe (
      ( event: LangChangeEvent ) =>  {
        let translationNamePrefix = event.translations;
        const listPath: string[] = this.newNamePrefixKey.split('.');
        if ( listPath && translationNamePrefix) {
          listPath.forEach( (subpath: string) => {
            if ( subpath && translationNamePrefix ) {
              translationNamePrefix = translationNamePrefix[subpath];
            }
          } );
        }
        if ( translationNamePrefix ) {
          updateTranslatedNewNamePrefix ( translationNamePrefix );
        }
      }
    );

    this.translate.get( this.newNamePrefixKey ).subscribe(
      updateTranslatedNewNamePrefix,
      ( error ) => {
        console.error('%cLanguage error: ', 'color:black;background:red', error.message, 'Key: ' + this.newNamePrefixKey );
      }
    );
  }

  ngAfterViewInit() {
    this.mustWaitForConfirmation = this.provideConfirmationInterface.observers.length > 0;
    if ( this.mustWaitForConfirmation ) {
      this.provideConfirmationInterface.emit( {
        confirmAdd: this.confirmItemAdd.bind(this),
        confirmRename: this.confirmItemRename.bind(this),
        confirmRemove: this.confirmItemRemove.bind(this)
      } );
    }

    this.provideExpandBreadcrumb.emit( this.expandBreadcrumb.bind(this) );

    if (this.startExpanded) {
      setTimeout ( () => {
        this.expandHierarchyNodes ( true );
      } );
    }
  }

  /** destroy */
  ngOnDestroy() {
    this.dataSource$.complete();
    if ( this.languageTranslationSubscription ) {
      this.languageTranslationSubscription.unsubscribe();
    }
  }

  private fillNodeParentHierarchy ( node: TreeNodeModel ) {
    if ( node.parent && node.parent.children.indexOf( node ) === -1 ) {
      node.parent = undefined;
    }

    if ( !node.parent && (node.parentId || node.parentId === 0) ) {
      node.parent = getNode( node.parentId, this.itemsTree );
    }

    if ( node.children && node.children.length ) {
      node.children.forEach( ( child ) => this.fillNodeParentHierarchy( child ) );
    }
  }

  /** init tree data */
  private initItemsTree( ) {
    if ( this.itemsTree ) {
      this.fillNodeParentHierarchy( this.itemsTree );
    } else {
      this.itemsTree = {
        name: 'Root',
        id: 0,
        parentId: null,
        parent: null,
        children: <TreeNodeModel[]>[]
      };
    }

    this.dataSource$.next ( [ this.itemsTree ] );
  }

  private refreshTree ( ) {
    this.dataSource$.next( this.dataSource$.value );
  }

  private expandHierarchyNodes ( expand: boolean, node?: TreeNodeModel ) {
    node = node || this.itemsTree;

    if ( node ) {
      if ( expand ) {
        this.treeControl.expand( node );
      } else {
        this.treeControl.collapse( node );
      }

      if ( node.children && node.children.length ) {
        for ( let index = 0 ; index < node.children.length ; index += 1 ) {
          this.expandHierarchyNodes ( expand, node.children[index] );
        }
      }
    }
  }

  private expandParent ( node: TreeNodeModel ) {
    const parent = ItemTreeComponent.getParent ( node, this.itemsTree );
    if ( parent ) {
      this.expandParent  ( parent );
    }
    this.treeControl.expand( node );
  }

  private getTotalItemCount ( ): number {
    let numberOfItems = 0;

    const computeNodeCount = ( nodeToCompute: TreeNodeModel ) => {
      if ( nodeToCompute.id !== undefined ) {
        numberOfItems += 1;

        if ( nodeToCompute.children && nodeToCompute.children.length ) {
          nodeToCompute.children.forEach( computeNodeCount );
        }
      }
    };

    computeNodeCount( this.itemsTree );

    return numberOfItems;
  }

  private isNotInUseId ( id: number ): boolean {
    let isNew = true;

    const checkId = ( nodeToCheck: TreeNodeModel ) => {
      if ( nodeToCheck.id !== undefined ) {
        isNew = nodeToCheck.id !== id;

        if ( nodeToCheck.children && nodeToCheck.children.length ) {
          nodeToCheck.children.forEach( checkId );
        }
      }
    };

    checkId( this.itemsTree );

    return isNew;
  }

  private expandBreadcrumb ( node: TreeNodeModel ) {
    if ( node ) {
      this.expandHierarchyNodes ( false );

      this.expandParent ( node );
    }
  }

  private getNewItemId () {
    return this.getTotalItemCount() + 99;
  }

  private confirmItemAdd ( node: TreeNodeModel, newId: number | null ) {
    if ( node ) {
      if ( newId === null || newId === undefined ) {
        this.discardNewNode( node );
      } else if ( newId === -1 ) {
        node.id = this.getNewItemId();
        this.refreshTree ( );
      } else if ( this.isNotInUseId(newId) ) {
        node.id = newId;
        this.refreshTree ( );
      } else {
        console.error( 'Tree node ERROR: duplicated or invalid item ID. ID=', newId );
      }
    }
  }

  private confirmItemRename ( node: TreeNodeModel, newName: string | null ) {
    if ( node && node.name !== newName ) {
      if ( newName === null ) {
        this.refreshTree ( );
      } else if ( newName && node.name !== newName ) {
        node.name = newName;
        this.refreshTree ( );
      } else {
        console.error( 'Tree node ERROR: Invalid item rename. name=', newName );
      }

    }
  }

  private confirmItemRemove ( nodeToRemove: TreeNodeModel ) {
    const parent = ItemTreeComponent.getParent( nodeToRemove, this.itemsTree );
    if ( parent && parent.children ) {
      if ( parent.children ) {
        const nodeToRemoveIndex = parent.children.indexOf(nodeToRemove);
        if ( nodeToRemoveIndex !== -1 ) {
          parent.children.splice(nodeToRemoveIndex, 1);
        }

        this.refreshTree ( );
      }
    } else {
      console.error('Tree node ERROR: Node to be removed without correct parent.', nodeToRemove );
    }
  }

  itemClicked( itemNode: TreeNodeModel ) {
    this.treeControl.expand( itemNode );

    this.selectedNode = itemNode;

    this.itemClick.emit( itemNode );
  }

  private createItemToAdd ( parentNodeWhereAdd: TreeNodeModel ): TreeNodeModel {
    const newNodeName = this.newNamePrefix + getSuffixName( parentNodeWhereAdd, this.newNamePrefix );

    return {
      name: newNodeName,
      id: undefined,
      parentId: parentNodeWhereAdd.id,
      parent: parentNodeWhereAdd,
      children: []
    };
  }

  addNewItem( parentNodeWhereAdd: TreeNodeModel) {
    if ( /*!this.isCreatingNewItem() && */parentNodeWhereAdd ) {
      const newNode = this.createItemToAdd( parentNodeWhereAdd );

      parentNodeWhereAdd.children = [
        ...(parentNodeWhereAdd.children || []),
        newNode
      ];

      this.refreshTree ( );

      if (!this.treeControl.isExpanded(parentNodeWhereAdd)) {
        this.treeControl.expand(parentNodeWhereAdd);
      }
    }
  }

  changeNodeName (nodeToRename: TreeNodeModel, newName: string ) {
    this.rename.emit( {
      node: nodeToRename,
      // parent: getParent( nodeToRename, this.itemsTree ),
      newName: newName
    } );

    if ( !this.mustWaitForConfirmation ) {
      this.confirmItemRename ( nodeToRename, newName );
    }
  }

  removeItem(nodeToRemove: TreeNodeModel) {
    const parent = ItemTreeComponent.getParent( nodeToRemove, this.itemsTree );

    if ( parent && parent.children ) {
      this.remove.emit( {
        node: nodeToRemove,
        parent: parent
      } );

      if ( !this.mustWaitForConfirmation ) {
        this.confirmItemRemove ( nodeToRemove );
      }
    } else {
      console.error('Tree node ERROR: Node to be removed without correct parent.', nodeToRemove );
    }
  }

  saveNewNode(node: TreeNodeModel) {
    if ( node.id === undefined ) {
      this.add.emit( {
        node: node,
        parent: node.parent
      } );

      if ( !this.mustWaitForConfirmation ) {
        if ( node.id === undefined ) {
          this.confirmItemAdd ( node, -1 );
        }
      }
    }
  }

  discardNewNode (node: TreeNodeModel) {
    if ( node.id === undefined ) {
      const sibling = node.parent.children;

      const nodeIndex = sibling.indexOf( node );

      if ( nodeIndex !== -1 ) {
        sibling.splice( nodeIndex, 1 );
      }

      this.refreshTree ( );
    }
  }
}
