import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType, HttpClient
} from '@angular/common/http';
import {Observable, of, Subject, Subscriber, throwError} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment as env_const } from '@env/environment';
import { CommonDialogsService } from '@common/dialogs/common-dialogs.service';
import { StoreService } from '@common/services/store.service';
import { AccessRefreshTokenResponse } from '@common/models/access-refresh-token-model';

const isPublicProjectCurrentPage = (): boolean => {
  return !!(location && location.href && location.href.includes('/public/project/'));
};

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  private started;
  private apiUrl = env_const.apiUrl; // + '/credentials/';
  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private store: StoreService,
    private commonDialog: CommonDialogsService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Queries start time
    if ( !env_const.avoid_console_log ) {
      this.started = Date.now();
    }

    return next.handle(req).pipe(
      map(this.interceptResponse.bind(this)),
      catchError((httpError) => {
        return this.catchError(httpError, req, next);
      })
    );
  }

  private tokenRefreshRegisteringLogin$( refresh: string, username: string ): Observable<AccessRefreshTokenResponse> {
    const tag = env_const.clientWebLoginRegisterKey;
    return this.http.post<AccessRefreshTokenResponse>(`${this.apiUrl}token/refresh/`, {refresh, username, tag});
  }

  private tokenRefresh$( refresh: string ): Observable<AccessRefreshTokenResponse> {
    return this.http.post<AccessRefreshTokenResponse>(`${this.apiUrl}token/refresh/`, {refresh});
  }

  private queryTokenRefresh$(refresh: string, username: string ): Observable<AccessRefreshTokenResponse> {
    if (
      !this.store.userLoginRegistered &&
      env_const.loginAccessRegistration &&
      env_const.loginAccessRegistrationOnTokenRefreshWhenCached
    ) {
      return this.tokenRefreshRegisteringLogin$( refresh, username );
    } else {
      return this.tokenRefresh$( refresh );
    }
  }

  private updateAccessToken ( username: string, refreshToken: string, tokenResponse: AccessRefreshTokenResponse ) {
    if ( tokenResponse.tag ) {
      this.store.updateUserJwtTokenAfterLoginRegistration(username, tokenResponse.access, refreshToken);
    } else {
      this.store.updateUserJwtToken(username, tokenResponse.access, refreshToken);
    }
    this.refreshTokenInProgress = false;
    this.tokenRefreshedSource.next(true);
  }

  private updateRefreshToken(): Observable<{ success: boolean, error?: any }> {
    const username = this.store.getUsername();
    const refreshToken = this.store.getRefreshToken();
    if ( username && refreshToken ) {
      if ( this.refreshTokenInProgress ) {
        return new Observable<{success: boolean}>( ( observer: Subscriber<{success: boolean}> ) => {
          this.tokenRefreshed$.subscribe(( ) => {
            observer.next();
            observer.complete();
          } );
        } );
      } else {
        this.refreshTokenInProgress = true;
        return this.queryTokenRefresh$( refreshToken, username ).pipe(
          tap(
            (tokenResponse: AccessRefreshTokenResponse ) => this.updateAccessToken( username, refreshToken, tokenResponse )
          ),
          catchError( () => {
            this.refreshTokenInProgress = false;
            this.onTokenErrorResetPage();
            return of({success: false});
          } )
        );
      }
    }
  }

  private interceptResponse(event: HttpEvent<any>): HttpEvent<any> {
    if (event instanceof HttpResponse) {
      if ( !env_const.avoid_console_log ) {
        // Queries end time
        const elapsed_ms = Date.now() - this.started;
        if ( elapsed_ms >= 2000 ) {
          console.log(
            `%c Request for ${event.url} took too long time: ${elapsed_ms / 1000} s`,
            'background: blue; color: yellow'
          );
        }

        if ( event.type === HttpEventType.Response &&  event.body ) {
          if ( event.body.log ) {
            console.log(
              '%c Server response log: ',
              'color: green; font-family: sans-serif; font-size: 2em; font-weight: bolder; text-shadow: #000 1px 1px;',
              event.body.log
            );
          }

          if ( event.body.errors ) {
            console.log(
              '%c Server response error: ',
              'color: red; font-family: sans-serif; font-size: 2em; font-weight: bolder; text-shadow: #000 1px 1px;',
              event.body.errors
            );
          }
        }
      } else if ( !env_const.avoid_console_error && event.type === HttpEventType.Response && event.body && event.body.errors ) {
        console.error(
          '%c Server response error: ',
          'color: red; font-family: sans-serif; font-size: 2em; font-weight: bolder; text-shadow: #000 1px 1px;',
          event.body.errors
        );
      }
    }
    return event;
  }

  private catchError(err: any, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      return this.catchHttpError(err, req, next);
    } else {
      const message = err.message ||
        ( err.error && `Backend returned code ${err.status}, body was: ${err.error}` ) ||
        `Backend returned code ${err.status}`;
      console.error(message);
      return of();
    }
  }

  private getClonedAuthorizedRequest( sourceRequest: HttpRequest<any> ): HttpRequest<any> {
    const accessToken = this.store.getAccessToken();
    const headers = sourceRequest.headers.set('Authorization', `Bearer ${accessToken}`);
    return sourceRequest.clone({headers});
  }

/*
  private catchHttpError_deprecated(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let observableToReturn = of();
    /!*
          const plmApiPrefixUrl = 'https://kyklos.jotne.com/EDMtruePLM';
          if ( err && err.url && err.url.startsWith(plmApiPrefixUrl) ) {}
    *!/
    if ( err.status === 401
         || (err.status === 403 && err.error && err.error['code'] === 'token_not_valid')
         || (err.status === 403 && err.error.errors === 'User doesn\'t exist.')
        ) {
      this.catchUnauthorized();
    } else if ( err.status === 403 ) {
      // https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
      observableToReturn = this.updateRefreshToken().pipe (
        switchMap(
          ( /!*response: { success: boolean, error?: any }*!/ ): Observable<HttpEvent<any>> => {
            const newReq = this.getClonedAuthorizedRequest( req );
            return next.handle(newReq);
          }
        ),
        catchError(
          ( httpError ): Observable<any> => {
            this.refreshTokenInProgress = false;
            return this.catchError(httpError, req, next);
          }
        )
      );
    } else {
      if ( navigator && !navigator.onLine ) {
        this.router.navigateByUrl ( env_const.offlineUrl );
        console.log('Network state: OFFLINE \n', err.message );
      } else {
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          setTimeout ( () => console.error('An error occurred:', err.error.message), 0 );
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          setTimeout ( () => console.error(
            `Backend returned code ${err.status}, message: ${err.message}, error: `, err
          ), 0 );
          throw err;
        }
      }
    }

    return observableToReturn;
  }
*/

  private catchHttpError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let observableToReturn: Observable<HttpEvent<any>>;

    if (err.status === 401
      || (err.status === 403 && err.error && err.error['code'] === 'token_not_valid')
      || (err.status === 403 && err.error.errors === 'User doesn\'t exist.')
    ) {
      this.catchUnauthorized();
      observableToReturn = of();
    } else if (err.status === 403) {
      // https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
      observableToReturn = this.updateRefreshToken().pipe(
        switchMap(
          (): Observable<HttpEvent<any>> => {
            const newReq = this.getClonedAuthorizedRequest(req);
            return next.handle(newReq);
          }
        ),
        catchError(
          (httpError): Observable<HttpEvent<any>> => {
            this.refreshTokenInProgress = false;
            return this.catchError(httpError, req, next);
          }
        )
      );
    } else {
      if (navigator && !navigator.onLine) {
        this.router.navigateByUrl(env_const.offlineUrl);
        console.log('Network state: OFFLINE \n', err.message);
        observableToReturn = of();
      } else {
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          setTimeout(() => console.error('An error occurred:', err.error.message), 0);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          setTimeout(() => console.error(
            `Backend returned code ${err.status}, message: ${err.message}, error: `, err
          ), 0);
        }
        observableToReturn = throwError(err); // <- Asegúrate de que devuelves un Observable en todos los casos.
      }
    }

    return observableToReturn;
  }

  private catchUnauthorized() {
    this.refreshTokenInProgress = false;
    console.error('Current user access not authorized');
    this.commonDialog.openTranslatedMessageDialog$('common.messages.userNotAuth');
    this.onTokenErrorResetPage();
  }

  private onTokenErrorResetPage() {
    this.store.clearStoredUser();
    if ( isPublicProjectCurrentPage() ) {
      console.log('Reload public experience');
      location.reload();
    } else {
      console.log('navigate to login');
      this.navigateToLogin();
    }
  }

  private navigateToLogin() {
    const returnUrl = this.router.url;
    if ( returnUrl ) {
      this.router.navigate([env_const.loginUrl], {queryParams: {returnUrl: returnUrl}});
    } else {
      this.router.navigateByUrl(env_const.loginUrl);
    }
  }
}
