<mat-dialog-content class="p-6">
  <form
    #pwdForm="ngForm"
    autocomplete="off"
    class="w-full min-w-[150px] max-w-[500px]"
  >
    <mat-form-field class="w-full">
      <input type="password" hidden name="password" tabindex="-1" />
      <input
        matInput
        cdkFocusInitial
        required
        tabindex="1"
        [placeholder]="dialogData.label"
        [(ngModel)]="_pwd"
        (keydown.enter)="onOk()"
        [type]="_hideText ? 'password' : 'text'"
        #pwdModel="ngModel"
        autocomplete="new-password"
        name="password"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="_hideText = !_hideText"
        tabindex="-1"
      >
        <mat-icon class="cursor-pointer align-top">
          {{ _hideText ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </button>
      <mat-error
        *ngIf="pwdModel.invalid && (pwdModel.dirty || pwdModel.touched)"
      >
        <mat-error *ngIf="pwdModel.errors.required">
          {{ 'common.messages.errors.requiredPassword' | translate }}
        </mat-error>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-raised-button
    class="secondary-button"
    mat-dialog-close
    tabindex="3"
  >
    {{ dialogData.btnCancelLabel }}
  </button>
  <button
    mat-raised-button
    [ngClass]="{
      'primary-button': !pwdForm.invalid
    }"
    (click)="onOk()"
    [disabled]="pwdForm.invalid"
    tabindex="2"
  >
    {{ dialogData.btnCheckLabel }}
  </button>
</mat-dialog-actions>
