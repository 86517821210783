import { Injectable } from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {AnonymousSubject} from 'rxjs/internal/Subject';
// import {StoreService} from '@common/services/store.service';

// Example:
// https://indepth.dev/tutorials/angular/how-to-implement-websockets-in-angular-project
@Injectable({
  providedIn: 'root'
})
export class LobbyWebsocketService {
  constructor(
    /*private store: StoreService,*/
  ) {}

  private websocketSubject: AnonymousSubject<MessageEvent>;
  private openedWebsocket: WebSocket;

  public connect(url): AnonymousSubject<MessageEvent> {
    if (!this.websocketSubject) {
      this.createWebsocketSubject(url);
      console.log('WebSocket successfully connected: ' + url);
    }
    return this.websocketSubject;
  }

  disconnect() {
    if ( this.openedWebsocket ) {
      this.openedWebsocket.close();
      this.openedWebsocket = null;
    }
    if ( this.websocketSubject ) {
      this.websocketSubject.complete();
      this.websocketSubject = null;
    }
  }

  private createWebsocketSubject(url) {
    // const accessToken = this.store.getAccessToken();
    //  'Authorization': `Bearer ${accessToken}`;
    // const webSocket = new WebSocket(url, ['access_token', accessToken]);
    const webSocket = new WebSocket(url);
    const observable = new Observable((obs: Observer<MessageEvent>) => {
      webSocket.onmessage = obs.next.bind(obs);
      webSocket.onerror = obs.error.bind(obs);
      webSocket.onclose = obs.complete.bind(obs);
      return webSocket.close.bind(webSocket);
    });
    const observer = {
      error: ( error ) => {
        console.log( 'Websocket error: ', error );
      },
      complete: () => {
        console.log('Complete websocket: CLOSE');
        webSocket.close();
      },
      next: (data: Object) => {
        console.log('Message sent to websocket: ', data);
        if (webSocket.readyState === WebSocket.OPEN) {
          webSocket.send(JSON.stringify(data));
        }
      }
    };
    this.openedWebsocket = webSocket;
    this.websocketSubject = new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
