<h2 mat-dialog-title>
  {{
    dialogData.titleKey ? (dialogData.titleKey | translate) : dialogData.title
  }}
</h2>

<mat-dialog-content class="flex flex-col text-center">
  <h3>
    {{ dialogData.questionKey | translate }}
  </h3>
  <a mat-stroked-button [href]="questionUrl.url" target="_blank" rel="noopener">
    <div class="flex items-center gap-2">
      <mat-icon>open_in_browser</mat-icon>
      <div>{{ questionUrl.url }}</div>
    </div>
  </a>
  <div class="flex justify-center">
    <qr-code
      [value]="questionUrl.url"
      size="300"
      errorCorrectionLevel="M"
    ></qr-code>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="secondary-button">
    {{
      dialogData.btnCancelLabelKey
        ? (dialogData.btnCancelLabelKey | translate)
        : dialogData.btnCancelLabel
    }}
  </button>

  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="primary-button"
  >
    {{
      dialogData.btnOkLabelKey
        ? (dialogData.btnOkLabelKey | translate)
        : dialogData.btnOkLabel
    }}
  </button>
</mat-dialog-actions>
