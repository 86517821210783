import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { HttpErrorInterceptorService } from '@common/services/http-error-interceptor.service';
import { PersistanceService } from '@common/services/persistance.service';
import { PublisherSubscriberService } from '@common/services/publisherSubscriber.service';
import { StoreService } from '@common/services/store.service';
import { LobbyService } from '@common/services/lobby.service';
import { LobbyWebsocketService } from '@common/services/lobby-websocket.service';
import { DetectService } from '@common/services/detect.service';

import { HttpAuthInterceptorService } from '@common/services/http-auth-interceptor.service';
import { DesktopRestService } from '@common/services/rest/desktop-rest.service';
import { GenericRestService } from '@common/services/rest/generic-rest.service';
import { ExecutionReportRestService } from '@common/services/rest/execution-report-rest.service';

import { CustomMaterialModule } from '@common/material.module';
import { CommonDialogsModule } from '@common/dialogs/common-dialogs.module';
import { PlmModule } from '@common/plm/plm.module';

import { CanActivateGuard } from '@common/guards/can-activate.guard';
import { AdminOrganizationGuard } from '@common/guards/admin-organization-guard.service';
import { AdminGuard } from '@common/guards/admin-guard.service';
import { SubscriptionOrganizationGuard } from '@common/guards/subscription-organization-guard.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule, HttpClientModule, CustomMaterialModule, CommonDialogsModule, PlmModule
    ],
    exports: [CustomMaterialModule, CommonDialogsModule, PlmModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptorService,
            multi: true
        },
        AdminGuard,
        AdminOrganizationGuard,
        SubscriptionOrganizationGuard,
        CanActivateGuard,
        PersistanceService,
        PublisherSubscriberService,
        DetectService,
        StoreService,
        LobbyService,
        LobbyWebsocketService,
        DesktopRestService,
        ExecutionReportRestService,
        GenericRestService
    ]
})

export class SharedModule {
  constructor (@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error(
        'SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
