import { /*HostListener,*/ Component, OnInit, AfterViewInit/*, OnDestroy*/ } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
// import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// https://www.npmjs.com/package/@ngx-pwa/offline
import { Network } from '@ngx-pwa/offline';

import { GenericRestService } from '@common/services/rest/generic-rest.service';

import { environment as env_const } from '@env/environment';

import { registerLocaleData } from '@angular/common';
import localeSpanish from '@angular/common/locales/es';
import localeEnglish from '@angular/common/locales/en';
import localeArabic from '@angular/common/locales/ar';
import localezhHans from '@angular/common/locales/zh-Hans';
import { MatIconRegistry } from '@angular/material/icon';

const handleConnection = () => {
  if (navigator.onLine) {
    isReachable(getServerUrl()).then((online) => {
      if (online) {
        // handle online status
        document.body.classList.remove('offline');
        console.log('%cKAYROX is ONLINE', 'color:black;background:green');
      } else {
        document.body.classList.add('offline');
        console.log(
          '%cKAYROX isn´t connectivity',
          'color:black;background:yellow'
        );
      }
    });
  } else {
    // handle offline status
    document.body.classList.add('offline');
    console.log('%cKAYROX is OFFLINE', 'color:black;background:orange');
  }
};

const isReachable = (url: string) => {
  /**
   * Note: fetch() still "succeeds" for 404s on subdirectories,
   * which is ok when only testing for domain reachability.
   *
   * Example:
   *   https://google.com/noexist does not throw
   *   https://noexist.com/noexist does throw
   */
  return fetch(url, { method: 'HEAD', mode: 'no-cors' })
    .then(function (resp) {
      return resp && (resp.ok || resp.type === 'opaque');
    })
    .catch(function (err) {
      console.warn('[conn test failure]:', err);
    });
};

const getServerUrl = (): string => {
  return env_const.serverUrl || window.location.origin;
};

const referenceScreenWidth = 1920; // Cambia esto según tu resolución de referencia

@Component({
  selector: 'tiwp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit/*, OnDestroy*/ {
  private screenViewScale = 1;
  private defaultLanguage = 'en';

/*
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateScale();
  }
*/

  // title = 'frontend-desktop';

  /*
  private isLoading = false;
*/
  // private _translationSubscription: Subscription;

  constructor(
    protected network: Network,
    private translate: TranslateService,
    private titleService: Title,
    private generic: GenericRestService,
    private router: Router,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer
  ) {
    this.registryIcons();
  }

  private useLanguage(key: string, keyIndex: number, languageKeys: string[]) {
    if (keyIndex !== -1) {
      this.translate.setDefaultLang(
        keyIndex ? languageKeys[0] : languageKeys[1]
      );
      this.translate.use(key);
    } else {
      if (key !== env_const.defaultLanguage) {
        keyIndex = languageKeys.indexOf(env_const.defaultLanguage);
        this.useLanguage(env_const.defaultLanguage, keyIndex, languageKeys);
      } else {
        keyIndex = languageKeys.indexOf(this.defaultLanguage);
        this.useLanguage(this.defaultLanguage, keyIndex, languageKeys);
      }
    }
  }

  private setupLanguage() {
    registerLocaleData(localeSpanish, 'es');
    registerLocaleData(localeEnglish, 'en');
    registerLocaleData(localeSpanish, 'es');
    registerLocaleData(localeSpanish, 'eus');
    registerLocaleData(localeArabic, 'ar');
    registerLocaleData(localezhHans, 'zh-Hans');

    const languages = env_const.languages;
    const languageKeys = languages.map((language) => language.key);
    // ['es', 'en']
    this.translate.addLangs(languageKeys);

    const keyLanguage =
      this.translate.getBrowserLang() ||
      env_const.defaultLanguage ||
      this.defaultLanguage;
    const keyLanguageIndex = keyLanguage
      ? languageKeys.indexOf(keyLanguage)
      : -1;
    this.useLanguage(keyLanguage, keyLanguageIndex, languageKeys);
  }

  ngOnInit() {
    this.setupLanguage();

    if (env_const.forcedTitle) {
      this.titleService.setTitle(env_const.forcedTitle);
    } else {
      this.translate.get('appTitle').subscribe(
        (title: string) => this.titleService.setTitle(title),
        (error) => {
          this.titleService.setTitle('KAYROX');
          console.error(
            '%cLanguage error: ',
            'color:black;background:red',
            error.message,
            'Key: ' + 'appTitle'
          );
        }
      );
    }

    /*
        // TODO: assign proper type to event
        this.router.events.subscribe((event: any): void => {
          this.navigationInterceptor(event);
        });
    */
    // this.updateScale();
  }

  private updateScale(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth < referenceScreenWidth) {
      this.screenViewScale = screenWidth / referenceScreenWidth;
    } else {
      this.screenViewScale = 1;
    }
    this.applyScaling();
  }

  private applyScaling(): void {
    document.body.style.transform = `scale(${this.screenViewScale})`;
  }

/*
  private subscribeLanguageChanges() {
    this._translationSubscription = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => this.updateLocale()
    );
  }
  private unsubscribeLanguageChanges() {
    this._translationSubscription.unsubscribe();
  }
  private updateLocale() {
    this.localeService.setupCurrentLanguage( this.translate.currentLang );
  }
*/

  ngAfterViewInit() {
    const handleNetworkChange = () => {
      if (navigator.onLine) {
        // this.router.navigateByUrl ( env_const.desktopUrl );
        document.body.classList.remove('offline');
        console.log('%cKAYROX is ONLINE', 'color:black;background:green');
      } else {
        this.router.navigateByUrl(env_const.offlineUrl);
        document.body.classList.add('offline');
        console.log('%cKAYROX is OFFLINE', 'color:black;background:orange');
      }
    };

    handleNetworkChange();

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
  }

/*
  ngOnDestroy(): void {
    this.unsubscribeLanguageChanges();
  }
*/

  /*
    navigationInterceptor(event): void {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      }
      if (event instanceof NavigationEnd) {
        this.isLoading = false;
      }
      if (event instanceof NavigationCancel) {
        this.isLoading = false;
      }
      if (event instanceof NavigationError) {
        this.isLoading = false;
      }
    }
  */

  private registryIcons() {
    this.matIconRegistry.addSvgIcon(
      'tiwp_object_3d',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/object_types/object-3d.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'tiwp_marker_3d',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/object_types/marker-3d.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'tiwp_marker_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/object_types/marker-image.svg'
      )
    );
  }
}
