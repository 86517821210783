import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StoreService} from './store.service';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptorService implements HttpInterceptor {
  constructor(private store: StoreService) {}

  private getAuthorizedRequest( req: HttpRequest<any>, accessToken: string ): HttpRequest<any> {
    const headers = req.headers.set('Authorization', `Bearer ${accessToken}`);
    return req.clone({headers});
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.store.getAccessToken();
    if ( !accessToken ) {
      if ( req.url && req.url.indexOf('/login/') !== -1 ) {
        console.warn('Error Access Token: Undefined access token, not rest data injection');
      }
      return next.handle(req);
    } else {
      const authorizedRequest = this.getAuthorizedRequest( req, accessToken );
      return next.handle(authorizedRequest);
    }
  }
}
