import {Component, EventEmitter, Input, OnInit, AfterViewInit, Output} from '@angular/core';
import {environment as env_const} from '@env/environment';
import {StoreService} from '@common/services/store.service';
import {
  PlmItem, isBackFolderPlmItem, mustBeGetPlmItemChildren, isAssetPlmItem,
  createFolderPlmItem, createBackFolderPlmItem, createAssetPlmItem, PlmChildrenResponse, PlmChildren, PlmNodeChildContent
} from '@common/plm/plm-model';
import {PlmService} from '@common/plm/plm.service';
import {CommonDialogsService} from '@common/dialogs/common-dialogs.service';
import {TypeModel} from '@common/models/id-name-model';

const sortArray = ( array: any[], key: string ) => array.sort((a, b) => a[key].localeCompare(b[key]));

const filterContentsByAssetTypes = (contents: PlmNodeChildContent[], assetTypes: TypeModel[] ): PlmNodeChildContent[] => {
  return contents.filter(
    ( assetChild ) =>  {
      assetChild.type = assetChild.type.trim().toLowerCase();
      return assetTypes.filter(
        ( assetType: TypeModel ) => assetType.accept.indexOf( assetChild.type ) !== -1
      ).length > 0;
      // return assetChild.type === '.glb' || assetChild.type === '.png' || assetChild.type === '.jpg';
    }
  );
};

@Component({
  selector: 'tiwp-plm-asset-selection',
  templateUrl: './plm-asset-selection.component.html',
  styleUrls: ['./plm-asset-selection.component.scss']
})
export class PlmAssetSelectionComponent implements OnInit, AfterViewInit {

  @Input() public projects: PlmItem[];
  @Input() public assetTypes: TypeModel[];

  @Output() assetsReady = new EventEmitter<boolean>();
  @Output() assetClick = new EventEmitter<PlmItem>();
  @Output() assetSelectionChange = new EventEmitter<PlmItem[]>();

  assetDefaultImg = env_const.assetImg;
  _items: PlmItem[];
  selectedAssets: PlmItem[];

  private projectModelId: string;

  constructor(
    private store: StoreService,
    private plmService: PlmService,
    private commonDialogService: CommonDialogsService
) {}

  ngOnInit(): void {
    this.selectedAssets = [];
    this._items = this.projects;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
    }, 0 );
  }

  animationDone(event: AnimationEvent) {
    if (event.animationName === 'itemsIn') {
      this.assetsReady.emit(true);
    }
  }

  private clearAssetSelectionChange( ) {
    if ( this.selectedAssets.length ) {
      this.selectedAssets.splice(0, this.selectedAssets.length );
      this.assetSelectionChange.emit( this.selectedAssets.slice() );
    }
  }

  private plmAssetClicked( plmItem: PlmItem ) {
    const assetIndex = this.selectedAssets.indexOf(plmItem);
    if ( assetIndex === -1 ) {
      this.selectedAssets.push( plmItem );
    } else {
      this.selectedAssets.splice( assetIndex, 1 );
    }

    this.assetClick.emit(plmItem );
    this.assetSelectionChange.emit( this.selectedAssets.slice() );
  }

  private selectedPlmItem( plmItem: PlmItem ) {
    if ( isAssetPlmItem(plmItem) ) {
      this.plmAssetClicked( plmItem );
    } else if ( plmItem.children ) {
      this._items = plmItem.children;
    }
  }

  private fillPlmItemChildren( plmItem: PlmItem, plmItemSiblings: PlmItem[], itemPulledData: PlmChildren ) {
    let plmItemChildren = [ createBackFolderPlmItem( plmItemSiblings ) ];

    if ( itemPulledData.children ) {
      const folderChildren = itemPulledData.children.map( ( nodeChild ) => createFolderPlmItem( nodeChild ) );
      sortArray( folderChildren, 'name' );
      console.log( '-- NODE children: ' + plmItem.name + ' (' + folderChildren.length + ' folders)' );
      plmItemChildren = plmItemChildren.concat( folderChildren );
    }
    if ( itemPulledData.contents ) {
      const supported_contents = filterContentsByAssetTypes( itemPulledData.contents, this.assetTypes );
      if ( supported_contents?.length ) {
        const assetChildren = supported_contents.map(
          ( assetChild ) =>  createAssetPlmItem( this.projectModelId, assetChild )
        );
        sortArray( assetChildren, 'name' );
        console.log( '-- NODE children: ' + plmItem.name + ' (' + assetChildren.length + ' glb assets)' );
        plmItemChildren = plmItemChildren.concat( assetChildren );
      }
    }

    plmItem.children = plmItemChildren;
    this.selectedPlmItem( plmItem );
  }

  private pullPlmChildren( plmItem: PlmItem, plmItemSiblings: PlmItem[] ) {
    if ( this.projectModelId ) {
      this.commonDialogService.showSpinner();
      this.plmService.getNodeChildren$( this.projectModelId, plmItem.nodeId ).subscribe(
        ( nodeChildrenResponse: PlmChildrenResponse ) => {
          this.commonDialogService.hideSpinner();
          if ( nodeChildrenResponse?.node ) {
            this.fillPlmItemChildren( plmItem, plmItemSiblings, nodeChildrenResponse.node );
          } else {
            console.log( 'FAIL getting ' + plmItem.name + ' children.' );
          }
        },
        (error) => {
          this.commonDialogService.hideSpinner();
          console.log( 'ERROR getting ' + plmItem.name + ' children: ' + error );
        }
      );
    }
  }

  onItemClicked( plmItem: PlmItem ) {
    if ( plmItem ) {
      if ( isBackFolderPlmItem(plmItem) ) {
        this.clearAssetSelectionChange();
        this._items = plmItem.parentSibling;
        if ( this._items[0]?.projectRoot ) {
          this.projectModelId = null;
        }
      } else {
        if ( plmItem.projectRoot ) {
          this.projectModelId = plmItem.projectRoot.projectModelId;
        }

        if ( mustBeGetPlmItemChildren( plmItem ) ) {
          this.pullPlmChildren( plmItem, this._items );
        } else {
          this.selectedPlmItem( plmItem );
        }
      }
    }
  }
}
