import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  createProjectPlmItem,
  PlmItem,
  PlmAsset,
  PlmProjectsResponse,
  PlmChildrenResponse,
  PlmFileImportResponse
} from '@common/plm/plm-model';
import {CommonDialogsService} from '@common/dialogs/common-dialogs.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PlmAssetSelectionDialogComponent} from '@common/plm/plm-asset-selection-dialog/plm-asset-selection-dialog.component';
import {environment as env_const} from '@env/environment';
import {TiwpItem} from '@common/models/item-model';
import {TypeModel} from '@common/models/id-name-model';

/*
const plmApiPrefixUrl = 'https://kyklos.jotne.com/EDMtruePLM';
const plmLoginService = '/api/admin/token';
const plmProjectsService = '/api/adm_user/all_user_proj';
const plmBreakdownService = '/api/bkd';
const plmRepositoty = '/TruePLMprojectsRep/';
const prepareDownloadService = '/api/dat/file/link';
const downloadService = '/api/dat/file/data';

const hardcodedUser = 'ana_tech';
const hardcodedPass = 'Ana17#tech';
const hardcodedGroup = 'sdai-group';

const blobToFile = ( theBlob: Blob, fileName: string, type: string ): File => {
  const cast: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  cast.lastModifiedDate = new Date();
  cast.name = fileName;
  // cast.type = type;

  // Cast to a File() type
  return <File>cast;
};

const blobToFile2 = (theBlob: Blob, fileName: string, type?: string): File => {
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: type || theBlob.type } );
};
*/

const getAssetType = ( plmAsset: PlmAsset ): number => {
  let assetTypeId;
  if ( plmAsset.type ) {
    switch (plmAsset.type) {
      case '.glb':
        assetTypeId = env_const.assetTypeIds.asset3d;
        break;
      case '.svg':
        assetTypeId = env_const.assetTypeIds.image;
        break;
      case '.png':
        assetTypeId = env_const.assetTypeIds.image;
        break;
      case '.jpg':
        assetTypeId = env_const.assetTypeIds.image;
        break;
      case '.jpeg':
        assetTypeId = env_const.assetTypeIds.image;
        break;
      case '.mp3':
        assetTypeId = env_const.assetTypeIds.audio;
        break;
      case '.ogg':
        assetTypeId = env_const.assetTypeIds.audio;
        break;
      case '.webm':
        assetTypeId = env_const.assetTypeIds.video;
        break;
      case '.mp4':
        assetTypeId = env_const.assetTypeIds.video;
        break;
      case '.pdf':
        assetTypeId = env_const.assetTypeIds.pdf;
        break;
    }
  }
  return assetTypeId;
};

@Injectable()
export class PlmService {
  private plmToken: string;

  constructor(
    private commonDialog: CommonDialogsService,
    private dialog:  MatDialog,
    private http: HttpClient
  ) {
    this.plmToken = null;
  }

/*
  public impotAssetFromPLM_fromClient( assetTypeId: number, folderId: number, importDone?: ()  => void ) {
    const subscription = this.openTranslatedPlmAssetSelectionDialog$().subscribe(
      (selectedAsset: PlmItem) => {
        subscription.unsubscribe();
        if ( selectedAsset?.asset ) {
          this.getAssetFile( selectedAsset.asset, assetTypeId, folderId, importDone );
        }
      }
    );
  }
*/

  public importAssetFromPLM( folderId: number, assetTypes: TypeModel[], projectsFilter: string[], importDone?: ()  => void ) {
    const subscription = this.openTranslatedPlmAssetSelectionDialog$( assetTypes, projectsFilter ).subscribe(
      (selectedAsset: PlmItem) => {
        subscription.unsubscribe();
        if ( selectedAsset?.asset ) {
          this.importAsset( selectedAsset.asset, folderId, importDone );
        }
      }
    );
  }

  public updateAssetFromPLM( asset_to_update: TiwpItem, projectsFilter: string[], importDone?: ()  => void ) {
    if ( asset_to_update?.asset ) {
      const subscription = this.openTranslatedPlmAssetSelectionDialog$( [asset_to_update.asset.type], projectsFilter ).subscribe(
        ( selectedAsset: PlmItem ) => {
          subscription.unsubscribe();
          if ( selectedAsset?.asset ) {
            this.updateAsset( selectedAsset.asset, asset_to_update, importDone );
          }
        }
      );
    }
  }

  private importPlmFile$(
    projectModelId: string, fileId: number, fileName: string, assetTypeId: number, folderId: number
  ): Observable<PlmFileImportResponse> {
    const restServiceUrl = `${env_const.apiUrl}import_plm_file/`;
    const queryData = new FormData();
    queryData.append('project_model_id', projectModelId );
    queryData.append('file_id', fileId.toString() );
    queryData.append('file_name', fileName );
    queryData.append('folder_id', folderId.toString() );
    queryData.append('asset_type_id', assetTypeId.toString() );

    return <Observable<PlmFileImportResponse>>this.http.post<PlmFileImportResponse>( restServiceUrl, queryData );
  }

  private updatePlmFile$(
    projectModelId: string, fileId: number, fileName: string, asset_to_update: TiwpItem
  ): Observable<PlmFileImportResponse> {
    const restServiceUrl = `${env_const.apiUrl}import_plm_file/`;
    const queryData = new FormData();
    queryData.append('project_model_id', projectModelId );
    queryData.append('file_id', fileId.toString() );
    queryData.append('file_name', fileName );
    queryData.append('asset_id_to_update', asset_to_update?.asset?.id.toString() );
    // queryData.append('asset_id', asset_to_update?.asset?.id.toString() );

    return <Observable<PlmFileImportResponse>>this.http.post<PlmFileImportResponse>( restServiceUrl, queryData );
  }

  private importAsset ( plmAsset: PlmAsset, folderId?: number, importDone?: ()  => void ) {
    if ( plmAsset ) {
      const assetTypeId = getAssetType( plmAsset );
      if ( assetTypeId ) {
        this.commonDialog.showSpinner();
        const subscription = this.importPlmFile$(
          plmAsset.projectId, plmAsset.fileId, plmAsset.fileName, assetTypeId, folderId
        ).subscribe(
          (response: PlmFileImportResponse) => {
            subscription.unsubscribe();
            this.commonDialog.hideSpinner();
            if ( importDone ) {
              importDone ();
            }
          },
          ( error ) => {
            this.commonDialog.hideSpinner();
            console.log( 'ERROR importing: ' + plmAsset.fileName );
          }
        );
      } else {
        console.error( 'File to import not supported, type: ', plmAsset.type );
        this.commonDialog.openTranslatedMessageDialog$('common.messages.fileNotSupported');
      }
    }
  }

  private updateAsset ( plmAsset: PlmAsset, asset_to_update: TiwpItem, updateDone?: ()  => void ) {
    if ( plmAsset ) {
      const assetTypeId = getAssetType( plmAsset );
      if ( assetTypeId ) {
        this.commonDialog.showSpinner();
        const subscription = this.updatePlmFile$(
          plmAsset.projectId, plmAsset.fileId, plmAsset.fileName, asset_to_update
        ).subscribe(
          (response: PlmFileImportResponse) => {
            subscription.unsubscribe();
            this.commonDialog.hideSpinner();
            if ( response?.success && updateDone ) {
              updateDone ();
            }
          },
          ( error ) => {
            this.commonDialog.hideSpinner();
            console.log( 'ERROR importing: ' + plmAsset.fileName + ' to update asset' );
          }
        );
      } else {
        console.error( 'File to import not supported, type: ', plmAsset.type );
        this.commonDialog.openTranslatedMessageDialog$('common.messages.fileNotSupported');
      }
    }
  }

  private openTranslatedPlmAssetSelectionDialog$ ( assetTypes: TypeModel[], projectsFilter: string[] ): Observable<PlmItem> {
    this.commonDialog.showTranslatedSpinner(  );

    return new Observable<PlmItem>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        this.commonDialog.hideSpinner();
        observer.error( error );
        console.error ( error );
      };

      let dialogCloseSubscription;
      const onGetPlmProjectsAsPlmItems = ( projectsPlmItem: PlmItem[] ) => {
        if (projectsFilter?.length ) {
          projectsPlmItem = projectsPlmItem.filter( (plmProject) => projectsFilter.indexOf(plmProject.name) !== -1 );
        }

        const dialogRef = this.dialog.open( PlmAssetSelectionDialogComponent, {
          data: {
            titleKey: 'desktop.labels.plmAssetSelect',
            items: projectsPlmItem,
            btnOkLabelKey: 'desktop.labels.okButton',
            btnCancelLabelKey: 'desktop.labels.cancelButton',
            assetTypes: assetTypes,
            afterViewInit: () => this.commonDialog.hideSpinner()
          }
        } );

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( selectedPlmItem: PlmItem ) =>  {
            dialogCloseSubscription.unsubscribe();

            if ( selectedPlmItem ) {
              observer.next( selectedPlmItem );
            } else {
              observer.next( null );
            }
          },
          onGettingMessageError
        );
      };

      this.getPlmProjectsAsPlmItems$().subscribe(
        onGetPlmProjectsAsPlmItems, onGettingMessageError
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }

/*
  public searchAssetsFromPLM( assetTypeId: number, folderId: number ) {
    const subscription = this.openTranslatedMultiplePlmAssetSelectionDialog$().subscribe(
      (selectedAssets: PlmItem[]) => {
        subscription.unsubscribe();
        selectedAssets.forEach( ( selectedAsset: PlmItem ) => this.importAsset( selectedAsset?.asset, assetTypeId, folderId ) );
      }
    );
  }
*/

/*
  private login$(user: string, pass: string, group: string): Observable<any> {
    const queryData = new FormData();
    queryData.append('group', group);
    queryData.append('user', user);
    queryData.append('pass', pass);

    return this.http.post(plmApiPrefixUrl + plmLoginService, queryData);
  }

  private genericGETServiceAfterLogin(serviceUrl: string, parameters = null, headers: HttpHeaders = null): Observable<any> {
    return new Observable((observer) => {
      this.login$(hardcodedUser, hardcodedPass, hardcodedGroup).subscribe(
        (login_response) => {
          if (login_response.error) {
            observer.error(login_response.error);
          } else {
            this.plmToken = login_response.token;
            let queryUrl = serviceUrl + this.plmToken;
            if (parameters) {
              queryUrl += '/?' + parameters;
            }
            let options;
            if (headers) {
              options = {
                headers: headers
              };
            }
            this.http.get(queryUrl, options).subscribe(
              (response) => observer.next(response),
              (error) => observer.error(error)
            );
          }
        },
        (error) => observer.error(error)
      );
    });
  }
*/

  private getPlmProjectsAsPlmItems$(): Observable<PlmItem[]> {
    return new Observable<PlmItem[]>((observer) => {
      let getProjectsSubscription;
      getProjectsSubscription = this.getProjects$().subscribe(
        (projectsResponse: PlmProjectsResponse) => {
          if (projectsResponse) {
            const projectsAsItems = projectsResponse.projects.map( projectResponse => {
              return createProjectPlmItem(
                projectResponse.name, projectResponse.model_id, projectResponse.root
              );
            });
            observer.next( projectsAsItems );
          }
        },
        (error) => console.log( 'ERROR getting PLM Project: ', error )
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if (getProjectsSubscription) {
            getProjectsSubscription.unsubscribe();
            getProjectsSubscription = null;
          }
        }
      };
    });
  }

  public getProjects$(): Observable<PlmProjectsResponse> {
    const restServiceUrl = `${env_const.apiUrl}get_plm_projects/`;
    const queryData = new FormData();
    return <Observable<PlmProjectsResponse>>this.http.post<PlmProjectsResponse>(restServiceUrl, queryData);
/*
    canCurrentUserPlmAccess() {
      return !!(this.currentUserInfo?.organization?.personalization?.plmAssetDownload);
    }
*/
  }

  public getNodeChildren$(projectModelId: string, nodeId: number): Observable<PlmChildrenResponse> {
    const restServiceUrl = `${env_const.apiUrl}get_plm_children/`;
    const queryData = new FormData();
    queryData.append('project_model_id', projectModelId );
    queryData.append('node_id', nodeId.toString() );
    queryData.append('count', env_const.maxNumberPlmNodeChildren.toString() );
    return <Observable<PlmChildrenResponse>>this.http.post<PlmChildrenResponse>(restServiceUrl, queryData);
  }

  public getNodeChildrenFiltered$(projectModelId: string, nodeId: number, pattern: string): Observable<PlmChildrenResponse> {
    const restServiceUrl = `${env_const.apiUrl}get_plm_children_filtered/`;
    const queryData = new FormData();
    queryData.append('project_model_id', projectModelId );
    queryData.append('node_id', nodeId.toString() );
    queryData.append('pattern', pattern );
    return <Observable<PlmChildrenResponse>>this.http.post<PlmChildrenResponse>(restServiceUrl, queryData);
  }

/*
  public getPlmFile$( projectModelId: string, fileId: number, fileName: string ): Observable<Blob> {
    const restServiceUrl = `${env_const.apiUrl}get_plm_file/`;
    const queryData = new FormData();
    queryData.append('project_model_id', projectModelId );
    queryData.append('file_id', fileId.toString() );
    queryData.append('file_name', fileName );

    // @ts-ignore
    return <Observable<Blob>>this.http.post<Blob>( restServiceUrl, queryData, {
      responseType: 'blob' as 'json'
    } );
  }

  private getAssetFile ( plmAsset: PlmAsset, assetTypeId?: number, folderId?: number, importDone?: ()  => void ) {
    if ( plmAsset ) {
      const filename = plmAsset.fileName;
      this.commonDialog.showSpinner();
      const subscription = this.getPlmFile$(
        plmAsset.projectId, plmAsset.fileId, plmAsset.fileName
      ).subscribe(
        (response: Blob) => {
          subscription.unsubscribe();
          this.commonDialog.hideSpinner();
          if ( response ) {
            // var file = new File([blob], "file_name", {lastModified: 1534584790000});
            // 1534584790000 is an unix timestamp for "GMT: Saturday, August 18, 2018 9:33:10 AM"
            const theFile = new File([response], filename );
            // const fileUrl = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            // const file = blobToFile( response, filename, '.glb' );
            const file = blobToFile2( response, filename, 'application/octet-stream' ); // 'application/xml+html' );

            this.uploadFile( file, filename, assetTypeId, folderId, importDone );
          }
/!*
          if ( importDone ) {
            importDone ();
          }
*!/
        },
        ( error ) => {
          this.commonDialog.hideSpinner();
          if ( error ) {}
          console.log( 'ERROR importing: ' + plmAsset.fileName );
        }
      );
    }
  }
*/

/*
  private prepareFileToDownload$(projectModelId: string, fileId: number, fileName: string): Observable<any> {
    const queryUrl = `${plmApiPrefixUrl}${prepareDownloadService}${plmRepositoty}${projectModelId}/${userRegisteredAs}/`;
    const parameters = `ver=${fileId}&name=${fileName}`;
    if (!this.plmToken) {
      return this.genericGETServiceAfterLogin(queryUrl, parameters);
    }
    return this.http.get( `${queryUrl}${this.plmToken}/?${parameters}` );
  }

  private downloadAfterPrepareFile$(source: string, fileName: string): Observable<any> {
    const queryUrl = `${plmApiPrefixUrl}${downloadService}${plmRepositoty}${source}/${fileName}/`;
    const headers = new HttpHeaders()
      .append('Access-Control-Allow-Origin', '*')
      .append('Allow', '*')
      .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS')
      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent');
    if (!this.plmToken) {
      return this.genericGETServiceAfterLogin(queryUrl, null, headers);
    }
    const options = {
      headers: headers
    };
    return this.http.get(queryUrl + this.plmToken, options);
  }

  private downloadFile$(projectModelId: string, fileId: number, fileName: string): Observable<any> {
    return new Observable<any>((observer) => {
      let downloadFileSubscription;
      let prepareFileSubscription = this.prepareFileToDownload$(projectModelId, fileId, fileName).subscribe(
        (prepareResponse) => {
          console.log('--- Preparando FILE: ' + prepareResponse.source + '( ' + prepareResponse.title + ')');
          downloadFileSubscription = this.downloadAfterPrepareFile$(prepareResponse.source, fileName).subscribe(
            (downloadResponse) => {
              console.log('--- DOWNLOAD OK: ' + downloadResponse);
              observer.next(downloadResponse);
            },
            (error) => observer.error(error)
          );
        },
        (error) => {
          console.log('--- ERROR EN PREPARE FILE: ' + error);
        }
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if (prepareFileSubscription) {
            prepareFileSubscription.unsubscribe();
            prepareFileSubscription = null;
          }
          if (downloadFileSubscription) {
            downloadFileSubscription.unsubscribe();
            downloadFileSubscription = null;
          }
        }
      };
    });
  }
*/

/*
  private openTranslatedMultiplePlmAssetSelectionDialog$ (): Observable<PlmItem[]> {
    this.commonDialog.showTranslatedSpinner(  );

    return new Observable<PlmItem[]>(( observer ) => {
      const onGettingMessageError = ( error ) => {
        this.commonDialog.hideSpinner();
        observer.error( error );
        console.error ( error );
      };

      let dialogCloseSubscription;
      const onGetPlmProjectsAsPlmItems = ( projectsPlmItems: PlmItem[] ) => {
        const dialogRef = this.dialog.open( PlmAssetSelectionDialogComponent, {
          data: {
            titleKey: 'desktop.labels.plmAssetSelect',
            items: projectsPlmItems,
            multipleSelection: true,
            btnOkLabelKey: 'desktop.labels.okButton',
            btnCancelLabelKey: 'desktop.labels.cancelButton',
            afterViewInit: () => this.commonDialog.hideSpinner()
          }
        } );

        const dialogOpened = dialogRef.afterClosed();
        dialogCloseSubscription = dialogOpened.subscribe(
          ( selectedPlmItems: PlmItem[] ) =>  {
            dialogCloseSubscription.unsubscribe();

            if ( selectedPlmItems && selectedPlmItems.length ) {
              observer.next( selectedPlmItems );
            } else {
              observer.next( null );
            }
          },
          onGettingMessageError
        );
      };

      this.getPlmProjectsAsPlmItems$().subscribe(
        onGetPlmProjectsAsPlmItems, onGettingMessageError
      );

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          if ( dialogCloseSubscription ) {
            dialogCloseSubscription.unsubscribe();
          }
        }
      };
    } );
  }
*/

/*
  private getUploadListener ( importDone: ()  => void ): ( event: HttpEvent<any> ) => void {
    const commonDialog = this.commonDialog;
    return (event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // It could be show Spinner Here
          console.log('Request sent!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header received!');
          break;
        case HttpEventType.UploadProgress:
          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log(`File is ${percentDone}% uploaded.`);
          commonDialog.setSpinnerProgress( percentDone );
          break;
        case HttpEventType.DownloadProgress:
          const kbLoaded = Math.round(event.loaded / 1024);
          console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
          break;
        case HttpEventType.Response:
          console.log('😺 Asset upload END!', event.body);
          commonDialog.hideSpinner( );
          if (event.body) {
            if (event.body.success) {
              let assetUpdate;
              if ( event.body.isAnUpdate ) {
                assetUpdate = event.body.asset;
              }
              console.log( '😺 Asset upload finished! ' );
              if ( importDone ) {
                importDone();
              }
            } else {
              setTimeout ( () => console.error ( event.body.errors ), 0 );
              console.log('😺 On file upload to server FAIL! ', event.body);
              commonDialog.openTranslatedMessageDialog$( 'desktop.messages.errors.fileUpload' );
            }
          } else {
            commonDialog.openTranslatedMessageDialog$( 'desktop.messages.errors.fileUpload' );
            console.error('😺 NOT body reception on file asset upload!');
          }
          break;
      }
    };
  }

  private getUploadErrorListener (): ( error: HttpErrorResponse ) => void {
    const commonDialog = this.commonDialog;
    return ( error: HttpErrorResponse ) => {
      commonDialog.openTranslatedMessageDialog$( 'desktop.messages.errors.fileUpload' );
      console.error ( error.message );

      if (error.error instanceof Error) {
        console.log('Upload Client-side error occurred.');
      } else {
        // this.router.navigate(['/error', error.error.error]);
        console.log('Upload Server-side error occurred.');
      }
    };
  }

  private uploadFile( file: File, filename?: string, assetTypeId?: number, folderId?: number, importDone?: ()  => void ) {
    const fileUpload$ = this.uploadFile$( file, filename, assetTypeId, folderId );

    // Show Spinner
    this.commonDialog.showTranslatedSpinner('desktop.messages.spinnerUploadInfo', 0 );

    // send the http-request and subscribe for progress-updates
    fileUpload$.subscribe(
      this.getUploadListener( importDone ),
      this.getUploadErrorListener()
    );
  }

  private uploadFile$( file: File, filename?: string, assetTypeId?: number, folderId?: number ): Observable<any> {
    // https://malcoded.com/posts/angular-file-upload-component-with-express/
    // https://www.techiediaries.com/angular-file-upload-progress-bar/
    const restServiceUrl = env_const.apiUrl + 'send_file/';

    // create a new multipart-form for every file
    const queryData: FormData = new FormData();
    queryData.append('file', file );

    if ( folderId !== undefined && folderId !== null ) {
      queryData.append('folder_id', folderId.toString() );
    }

    if ( assetTypeId !== undefined && assetTypeId !== null ) {
      queryData.append('asset_type_id', assetTypeId.toString() );
    }

    if ( filename ) {
      queryData.append('file_name', filename );
    }

    // https://stackoverflow.com/questions/62235319/reportprogress-is-not-working-in-production/62257571#62257571
    const headers = new HttpHeaders({ 'ngsw-bypass': ''});
    return this.http.post<any>(restServiceUrl, queryData, {
      reportProgress: true,
      observe: 'events',
      headers: headers
    } );
  }
*/
}
