import {Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface SelectListDialogData {
  messageKey: string;
  messageParams: Object;
  itemList: any[];
  btnOkLabelKey: string;
  btnCancelLabelKey: string;
}

@Component({
  selector: 'tiwp-sort-items-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.scss']
})
export class SelectListDialogComponent {
  public dialogData: SelectListDialogData;

  constructor(private  dialogRef:  MatDialogRef<SelectListDialogComponent>, @Inject(MAT_DIALOG_DATA) public  data:  SelectListDialogData) {
    this.dialogData = data;
  }
  public  select( item ) {
    this.dialogRef.close( item );
  }

}
