import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { EditLabelComponent } from './edit-label.component';

@NgModule({
  declarations: [EditLabelComponent],
  exports: [EditLabelComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule
  ]
})
export class EditLabelModule { }
