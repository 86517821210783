<span
  *ngIf="isReadonly(); else editionMode"
  class="unselectable"
  [ngClass]="{ 'cursor-default': readonly, 'opacity-80': disabled }"
  (dblclick)="useDoubleClick && !readonly && setEditionMode(true)"
  (click)="!useDoubleClick && !readonly && setEditionMode(true)"
>
  {{ text }}
</span>

<ng-template #editionMode>
  <input
    matInput
    type="text"
    name="text"
    required
    minlength="2"
    placeholder="Edit text"
    [(ngModel)]="text"
    (focusout)="setEditionMode(false)"
    (keydown.enter)="setEditionMode(false)"
  />
</ng-template>
