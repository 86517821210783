import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env_const } from '@env/environment';

import {
  CloneProjectsResponseModel,
  OrganizationCreationResponseModel,
  ResponseModel
} from '@common/models/response-model';

export interface OrganizationCreationModel {
  name: string;
  short_name: string;
  admin_username: string;
  admin_password: string;
  email: string;
}
@Injectable()
export class AdminRestService {
  private static readonly HEADERS = new HttpHeaders({'Content-Type': 'application/json'});

  private apiUrl = env_const.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  public createOrganization$( registrationData: OrganizationCreationModel ): Observable<OrganizationCreationResponseModel> {
    const registrationServiceUrl = `${this.apiUrl}admin_create_organization/`;
    const registrationFormData = new FormData();
    registrationFormData.set( 'name', registrationData.name );
    registrationFormData.set( 'short_name', registrationData.short_name );
    registrationFormData.set( 'admin_username', registrationData.admin_username );
    registrationFormData.set( 'admin_password', registrationData.admin_password );
    if ( registrationData.email ) {
      registrationFormData.set( 'email', registrationData.email );
    }

    return this.http.post<OrganizationCreationResponseModel>(registrationServiceUrl, registrationFormData);
  }

  public setupOrganizationTypes$( organizationId: number, assetIds: number[], projectIds: number[] ): Observable<ResponseModel> {
    const restServiceUrl = this.apiUrl + 'admin_set_organization_types/';
    const params = new HttpParams().appendAll({
      'organization_id': organizationId,
      'asset_type_ids': assetIds,
      'project_type_ids': projectIds
    });
    return this.http.post<ResponseModel>(restServiceUrl, null, {
      headers: AdminRestService.HEADERS,
      params: params
    });
  }

  public cloneProjects$( organizationId: number, userId: number, projectIds: number[] ): Observable<CloneProjectsResponseModel> {
    const restServiceUrl = this.apiUrl + 'admin_clone_projects/';
    const params = new HttpParams().appendAll({
      'organization_id': organizationId,
      'user_id': userId,
      'ids': projectIds
    });
    return this.http.post<CloneProjectsResponseModel>(restServiceUrl, null, {
      headers: AdminRestService.HEADERS,
      params: params
    });
  }

  public cloneAssets$( organizationId: number, userId: number, assetIds: number[] ): Observable<CloneProjectsResponseModel> {
    const restServiceUrl = this.apiUrl + 'admin_clone_assets/';
    const params = new HttpParams().appendAll({
      'organization_id': organizationId,
      'user_id': userId,
      'ids': assetIds
    });
    return this.http.post<CloneProjectsResponseModel>(restServiceUrl, null, {
      headers: AdminRestService.HEADERS,
      params: params
    });
  }
}
