import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {CustomMaterialModule} from '@common/material.module';

import { AdminComponent } from './admin.component';
import { AdminRestService } from './admin-rest.service';

@NgModule({
  declarations: [
    AdminComponent
  ],
  providers: [AdminRestService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CustomMaterialModule,
    TranslateModule.forChild({
      extend: true
    } )
  ]
})
export class AdminModule { }
