import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

// import { AFrame } from '@app/common/@types/aframe/index';

if (environment.production) {
  enableProdMode();
}

if (environment.avoid_console_log && window) {
  window.console.log = () => {};
}

if (environment.avoid_console_warn && window) {
  window.console.warn = () => {};
}

if (environment.avoid_console_error && window) {
  window.console.error = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
