import {Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {Subscription} from 'rxjs';

import { StoreService } from '@common/services/store.service';

import { environment as env_const } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard  implements OnDestroy {
  private isAnonymousSubscription: Subscription;
  private userIsAnonymous = true;

  constructor(private store: StoreService, private router: Router) {
    const userIsAnonymous$ = this.store.getUserIsAnonymous$();
    this.isAnonymousSubscription = userIsAnonymous$.subscribe( this.setOnChangeUserAuth.bind(this) );
  }

  ngOnDestroy() {
    this.isAnonymousSubscription.unsubscribe();
  }

  /*private setOnChangeUserAuth = userIsAnonymous => {
    this.userIsAnonymous = userIsAnonymous;
  }
  */
  private setOnChangeUserAuth(userIsAnonymous) {
    this.userIsAnonymous = userIsAnonymous;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userInfo = this.store.getUserInfo();
    // const accessToken = !!this.store.getAccessToken();
    const refreshToken = this.store.getRefreshToken();
    const canBeActivated = (refreshToken && userInfo && userInfo.username !== 'anonymous' && userInfo.username !== env_const.adminUser) === true;
    if ( !canBeActivated ) {
      this.router.navigate([env_const.loginUrl], {queryParams: {returnUrl: state.url}});
    }
/*
    const userInfo = this.store.getUserInfo();
    const canBeActivated = !this.userIsAnonymous && userInfo && userInfo.username !== 'anonymous';
    if (!canBeActivated) {
    }
*/

    return canBeActivated;
  }

/*
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    return !this.userIsAnonymous;
/!*
    let isLoggedIn = route.queryParams.login || 0;
    return this.auth.isAuthorized(isLoggedIn);
*!/
  }
*/

/*
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
*/
}

