<div class='center-me'>
  <mat-card class="example-card">
    <mat-card-header>
      <!-- https://material.io/tools/icons/?style=outline -->
      <div mat-card-avatar><mat-icon>error_outline</mat-icon></div>
      <mat-card-title>{{'common.labels.notFoundTitle' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'common.messages.errors.404' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="assets/images/hero.png" alt="Tiwp Logo">
    <mat-card-actions>
      <button mat-stroked-button class="gohome-button" (click)="goHome()">
        <a routerLink="">{{'common.labels.goHomeButton' | translate}}</a>
        <mat-icon>home</mat-icon>
      </button>
      </mat-card-actions>
  </mat-card>
</div>
