import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PlmItem} from '@common/plm/plm-model';
import {TypeModel} from '@common/models/id-name-model';

export interface SelectPlmItemDialogData {
  titleKey?: string;
  items: PlmItem[];
  multipleSelection?: boolean;
  btnOkLabelKey: string;
  btnCancelLabelKey: string;
  assetTypes: TypeModel[];
  afterViewInit?: () => void;
}

const titleKey = 'desktop.labels.assetSelect';
@Component({
  selector: 'tiwp-plm-asset-selection-dialog',
  templateUrl: './plm-asset-selection-dialog.component.html',
  styleUrls: ['./plm-asset-selection-dialog.component.scss']
})
export class PlmAssetSelectionDialogComponent implements AfterViewInit {
  public dialogData: SelectPlmItemDialogData;

  titleKey: string;
  multipleSelection: boolean;
  selectedAssets: PlmItem[];

  afterViewInitCallback: () => void;

  constructor (
    private  dialogRef:  MatDialogRef<PlmAssetSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  SelectPlmItemDialogData
  ) {
    this.selectedAssets = [];
    this.dialogData = data;
    this.titleKey = data.titleKey || titleKey;
    this.multipleSelection = data.multipleSelection === true;
    this.afterViewInitCallback = data.afterViewInit;
  }

  public  onCancel ( ) {
  }

  public  onOk ( ) {
    if ( this.multipleSelection && this.selectedAssets.length ) {
      this.dialogRef.close( this.selectedAssets );
    }
  }

  assetSelected ( selectedAsset: PlmItem ) {
    if ( selectedAsset && !this.multipleSelection ) {
      this.dialogRef.close( selectedAsset );
    }
  }

  assetSelectionChange ( selectedAssets: PlmItem[] ) {
    if ( this.multipleSelection ) {
      this.selectedAssets = selectedAssets;
    }
  }

  ngAfterViewInit(): void {
    if ( this.afterViewInitCallback ) {
      setTimeout(() => this.afterViewInitCallback(), 0 );
    }
  }
}
