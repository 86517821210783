import {Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { StoreService } from '@common/services/store.service';

import { environment as env_const } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private store: StoreService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const routeCanBeActivated = (this.store.getRefreshToken() && this.store.getUsername() === env_const.adminUser) === true;
    if (!routeCanBeActivated) {
      this.router.navigateByUrl(env_const.loginUrl);
    }
    return routeCanBeActivated;
  }

/*
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    return !this.userIsAnonymous;
/!*
    let isLoggedIn = route.queryParams.login || 0;
    return this.auth.isAuthorized(isLoggedIn);
*!/
  }
*/

/*
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
*/
}

