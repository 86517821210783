import {Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

export interface SortItemsDialogData {
  messageKey: string;
  messageParams: Object;
  items: any[];
  btnOkLabelKey: string;
  btnCancelLabelKey: string;
}

@Component({
  selector: 'tiwp-sort-items-dialog',
  templateUrl: './sort-items-dialog.component.html',
  styleUrls: ['./sort-items-dialog.component.scss']
})
export class SortItemsDialogComponent {
  public dialogData: SortItemsDialogData;

  constructor(private  dialogRef:  MatDialogRef<SortItemsDialogComponent>, @Inject(MAT_DIALOG_DATA) public  data:  SortItemsDialogData) {
    this.dialogData = data;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dialogData.items, event.previousIndex, event.currentIndex);
  }
  public  onOk( ) {
    this.dialogRef.close( this.dialogData.items );
  }

}
