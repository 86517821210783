import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tiwp-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  message = 'Not found : 404';

  constructor(private router: Router) {}

  goHome(): void {
    this.router.navigateByUrl('desktop');
  }
}
