import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@views/not-found/not-found.component';

import { CanActivateGuard } from '@common/guards/can-activate.guard';
import { LoginComponent } from '@views/login/login.component';
import { AdminComponent } from '@views/admin/admin.component';
import { AdminGuard } from '@common/guards/admin-guard.service';

const routes: Routes = [
  {
    path: 'desktop',
    canActivate: [CanActivateGuard],
    loadChildren: () => import('@views/desktop/desktop.module').then(m => m.DesktopModule)
  },
  {
    path: 'offline',
    loadChildren: () => import('@views/offline/offline.module').then(m => m.OfflineModule)
    // component: OfflineComponent
  },
  {
    path: 'public',
    loadChildren: () => import('@views/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    component: AdminComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '',
    redirectTo: 'desktop',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes /*, {onSameUrlNavigation: 'reload'}*/, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
