import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment as env_const } from '@env/environment';

import { StoreService } from '@common/services/store.service';
import { CommonDialogsService } from '@common/dialogs/common-dialogs.service';

import { CredentialsService } from './credentials.service';
import { AccessRefreshTokenResponse } from '@common/models/access-refresh-token-model';
import { UserInfoModel } from '@common/models/response-model';

@Component({
  selector: 'tiwp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  public get isOnline(): boolean {
    return navigator.onLine === true;
  }

  public pageData = {
    user: '',
    password: ''
  };
  public returnUrl: string;
  public _hideText = true;

  readonly translationPath = 'login.';

  constructor(
    private router: Router,
    private store: StoreService,
    private activatedRoute: ActivatedRoute,
    private commonDialog: CommonDialogsService,
    private credentialsService: CredentialsService
  ) { }

  ngOnInit( ) {
    this.returnUrl = this.activatedRoute.snapshot.queryParams[ 'returnUrl' ] || env_const.desktopUrl;
    this.store.clearStoredUser();
  }

  ngAfterViewInit( ) {
    this.commonDialog.hideSpinner( );
  }

  onUsernameChange( newUsername: string ) {
    // this.pageData.user = newUsername ? newUsername.trim().toLowerCase() : '';
  }

  login(): void {
    this.store.clearStoredUser();

    // this.pageData.user = this.pageData.user.trim().toLowerCase();
    const user = this.pageData.user;
    this.credentialsService
      .getToken$( user, this.pageData.password )
      .subscribe(
        ( tokenResponse: AccessRefreshTokenResponse ) => {
          if ( tokenResponse.access && tokenResponse.refresh ) {
            if ( user === env_const.adminUser ) {
              this.commonDialog.openTranslatedMessageDialog$( this.translationPath + 'greetingAdmin' ).subscribe(
                (_) =>  this.correctAdminLogin( user, tokenResponse )
              );
            } else {
              this.commonDialog.openTranslatedMessageDialog$( this.translationPath + 'greeting', { 'name': user } ).subscribe(
                (_) =>  this.correctLogin( user, tokenResponse )
              );
            }
          } else {
            this.invalidLogin( tokenResponse );
          }
        },
        this.invalidLogin.bind(this)
      );
  }

  private correctAdminLogin( user: string, tokenResponse: AccessRefreshTokenResponse ) {
    this.store.updateUserJwtTokenAfterLoginRegistration( user, tokenResponse.access,  tokenResponse.refresh );
    this.router.navigateByUrl('admin');
  }

  private verifiedLogin( user_info ) {
    this.store.updateUserInfo( user_info );
    this.router.navigateByUrl('desktop');
  }

  private correctLogin( user: string, tokenResponse: AccessRefreshTokenResponse ) {
    this.store.updateUserJwtTokenAfterLoginRegistration( user, tokenResponse.access,  tokenResponse.refresh );
    this.credentialsService.getUserInfo$().subscribe(
      (user_info: UserInfoModel) => {
        if ( !user_info.remaining || user_info.remaining.subscription_days > 0 ) {
          this.verifiedLogin( user_info );
        } else {
          this.expiredLogin();
        }
      }
    );
  }

  private expiredLogin() {
    this.store.clearStoredUser();
    console.warn( 'Expired subscription.' );
    this.commonDialog.openTranslatedMessageDialog$('common.messages.3xp1r3dSubscr1pt10n');
  }

  private invalidLogin(responseData) {
    const message = (responseData.errors && responseData.error) ||
                     responseData.message || responseData.statusText || 'Login inválido';

    this.store.clearStoredUser();
    this.commonDialog.openMessageDialog$ ( message );
  }
}
