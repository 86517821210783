import { Component, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// https://github.com/mnahkies/ng-qrcode
// https://www.npmjs.com/package/ng-qrcode
// https://mnahkies.github.io/ng-qrcode/

export interface ConfirmationDialogData {
  question?: string;
  questionKey?: string;
  questionParams?: string;
  url: string;
  title?: string;
  titleKey?: string;
  btnOkLabel?: string;
  btnOkLabelKey?: string;
  btnCancelLabel?: string;
  btnCancelLabelKey?: string;
  timeout?: number;
}

@Component({
  selector: 'tiwp-confirmation-dialog',
  templateUrl: './public-link-dialog.component.html',
  styleUrls: ['./public-link-dialog.component.scss']
})
export class PublicLinkDialogComponent {
  public dialogData: ConfirmationDialogData;
  questionUrl = { url: '' };

  constructor(private  dialogRef:  MatDialogRef<PublicLinkDialogComponent>, @Inject(MAT_DIALOG_DATA) public  data:  ConfirmationDialogData) {
    this.dialogData = data;
    this.questionUrl.url = data.url;

    if ( data.timeout ) {
      setTimeout( function closeDialog () {
          dialogRef.close();
        }, data.timeout
      );
    }
  }

  public  closeMe() {
    this.dialogRef.close();
  }
}
