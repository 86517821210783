<mat-card class="assets-body">
  <mat-card-content>
    <div  id="gridContainer" class="grid-container">
      <ng-container  *ngIf="_items && _items.length else emptyOfItems">
        <mat-card class="grid-card" *ngFor="let item of _items"
                  [ngClass]="{ 'selected' : selectedAssets.indexOf(item) !== -1 }"
                  (animationend)="animationDone($event)"
                  (click)="onItemClicked(item)">
          <mat-card-content class="content-container">
            <div class="title-container" [ngClass]="{'title-container': true, 'warn-item': item.projectRoot && !item.projectRoot.id}">
              <div class="title-item">
                <span  class="disabled unselectable">{{item.name}}</span>
              </div>
            </div>

            <div class="img-container">
              <img class="img-item" [src]="item.uiImage || assetDefaultImg" alt="" (error)="item.uiImage = ''">
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-template #emptyOfItems>
        <mat-card *ngIf="_items" class="grid-card empty-items" (animationend)="animationDone($event)">
          <mat-card-content class="content-container" >
            <div class="title-container empty-title">
              <span class="title-item">{{'desktop.labels.assetsGalleryEmpty' | translate}}</span>
            </div>

            <div class="img-container">
              <img class="img-item" [src]="assetDefaultImg" alt="empty">
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
