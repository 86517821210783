import { Component, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ConfirmationDialogData {
  question?: string;
  questionKey?: string;
  questionParams?: string;
  title?: string;
  titleKey?: string;
  btnOkLabel?: string;
  btnOkLabelKey?: string;
  btnCancelLabel?: string;
  btnCancelLabelKey?: string;
  timeout?: number;
}

@Component({
  selector: 'tiwp-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public dialogData: ConfirmationDialogData;

  constructor(private  dialogRef:  MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public  data:  ConfirmationDialogData) {
    this.dialogData = data;

    if ( data.timeout ) {
      setTimeout( function closeDialog () {
          dialogRef.close();
        }, data.timeout
      );
    }
  }

  public  closeMe() {
    this.dialogRef.close();
  }
}
