import {Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { StoreService } from '@common/services/store.service';

import { environment as env_const } from '@env/environment';
import {CommonDialogsService} from '@common/dialogs/common-dialogs.service';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionOrganizationGuard  {
  constructor(
    private store: StoreService,
    private router: Router,
    private commonDialog: CommonDialogsService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userInfo = this.store.getUserInfo();
    const subscriptionToCheck = userInfo && userInfo.remaining;
    const routeCanBeActivated = !subscriptionToCheck || subscriptionToCheck.subscription_days > 0;

    if (!routeCanBeActivated) {
      this.expiredSubscription();
    }
    return routeCanBeActivated;
  }

  private expiredSubscription() {
    this.store.clearStoredUser();
    this.commonDialog.openTranslatedMessageDialog$('common.messages.3xp1r3dSubscr1pt10n');
    this.router.navigateByUrl(env_const.loginUrl);
  }
}

