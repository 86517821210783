import { commonEnvironment } from './environment.common';

const clonedEnvironment = Object.assign( {}, commonEnvironment );

export const environment = Object.assign( clonedEnvironment, {
  banishedOrganizationIds: [],
  environment_tag: 'int',
  avoid_console_log: false,
  avoid_console_warn: false,
  avoid_console_error: false,
  production: true,
  loginAccessRegistration: true,
  loginAccessRegistrationOnTokenRefreshWhenCached: false
} );
