import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';

import { CustomMaterialModule } from '@common/material.module';

import { LoginComponent } from './login.component';
import { CredentialsService } from './credentials.service';

@NgModule({
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [CredentialsService],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    CustomMaterialModule,
    TranslateModule.forChild({
      extend: true
    } )
  ]
})
export class CredentialsModule { }
