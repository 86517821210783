import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found.component';

/*
import { MatIconModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
*/

import { CustomMaterialModule } from '@common/material.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CustomMaterialModule,
    TranslateModule.forChild({
      extend: true
    } )
  ],
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent]
})
export class NotFoundModule {
}
