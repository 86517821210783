<mat-dialog-content class="mat-typography main-container">
  <h3 *ngIf="dialogData.messageParams else translationWithoutParams">
    {{dialogData.messageKey | translate: dialogData.messageParams}}
  </h3>
  <ng-template #translationWithoutParams>
    <h3>{{dialogData.messageKey | translate}}</h3>
  </ng-template>
  <div class="item-list mat-list-base">
    <div class="item-box" *ngFor="let item of dialogData.itemList" (click)="select(item)">
      {{item.name}}
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{dialogData.btnCancelLabelKey | translate}}
  </button>
</mat-dialog-actions>
