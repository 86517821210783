<h1 *ngIf="messageTranslatedTextKey" mat-dialog-title class="h-16 flex m-auto overflow-hidden">
  <span
    class="m-auto overflow-hidden flex static w-auto font-bold text-4xl text-color-red"
  >
    {{messageTranslatedTextKey | translate}}
  </span>
</h1>

<mat-dialog-content
  class="flex justify-center items-center overflow-hidden p-0 m-auto container"
>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    <mat-icon>play_circle_outline</mat-icon>    <!--{{dialogData.btnOkLabelKey | translate}}-->
  </button>
</mat-dialog-content>
