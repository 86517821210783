<mat-tree
  [dataSource]="treeSource"
  [treeControl]="treeControl"
  [trackBy]="trackBy"
  class="parent-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <!--End Node without CHILDREN Nodes, can be the first one if only one in hierarchy-->
  <mat-tree-node
    [disabled]="disabled"
    *matTreeNodeDef="let node"
    matTreeNodeToggle
  >
    <li
      class="mt-0 mb-0.5 list-none flex justify-between items-center min-h-[48px] w-full bg-gray text-white"
      [ngClass]="{
        'text-color-red border border-solid border-red':
          selectable && node === selectedNode
      }"
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>

      <div class="pr-2.5" (click)="itemClicked(node)">
        <tiwp-edit-label
          [disabled]="disabled"
          [readonly]="!editable || !node.parent"
          [text]="node.name"
          [useDoubleClick]="true"
          (changed)="changeNodeName(node, $event)"
        >
        </tiwp-edit-label>
      </div>

      <div *ngIf="editable">
        <button
          *ngIf="removable && node.parent"
          mat-icon-button
          aria-label="eliminar"
          [disabled]="disabled"
          (click)="removeItem(node)"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
        <button
          *ngIf="addable"
          mat-icon-button
          aria-label="crear"
          [disabled]="disabled"
          (click)="addNewItem(node)"
        >
          <mat-icon>create_new_folder</mat-icon>
        </button>
      </div>
    </li>
  </mat-tree-node>

  <!--NEW Node when we are defining new one-->
  <mat-tree-node
    class="border-l-4 border-l-red"
    *matTreeNodeDef="let node; when: hasNoContent"
    matTreeNodePadding
  >
    <button mat-icon-button disabled></button>
    <div>
      <mat-label
        class="select-none"
        *ngIf="newItemLabelKey; else notTranslatedLabel"
      >
        {{ newItemLabelKey | translate }}
      </mat-label>
      <ng-template #notTranslatedLabel>
        <mat-label>{{ newItemLabel }}</mat-label>
      </ng-template>
      <input
        matInput
        placeholder="item name"
        [(ngModel)]="node.name"
        (keydown.enter)="saveNewNode(node)"
      />
    </div>
    <div>
      <button
        mat-icon-button
        [disabled]="disabled"
        (click)="discardNewNode(node)"
      >
        <mat-icon class="remove-scene-button md-48">delete_outline</mat-icon>
      </button>
      <button mat-icon-button [disabled]="disabled" (click)="saveNewNode(node)">
        <mat-icon class="remove-scene-button md-48"
          >drive_folder_upload</mat-icon
        >
      </button>
    </div>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <!--PARENT Node with children-->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li class="my-0 list-none">
      <div
        class="mb-0.5 flex justify-between items-center w-full min-h-[48px] bg-gray"
        [ngClass]="
          selectable && node === selectedNode
            ? 'text-color-red border border-solid border-red'
            : 'text-white'
        "
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon>
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>

        <div class="pr-2.5" (click)="itemClicked(node)">
          <tiwp-edit-label
            [disabled]="disabled"
            [readonly]="!editable"
            [text]="node.name"
            [useDoubleClick]="true"
            (changed)="changeNodeName(node, $event)"
          >
          </tiwp-edit-label>
        </div>

        <div *ngIf="editable">
          <button
            *ngIf="removable"
            class="hidden"
            mat-icon-button
            aria-label="eliminar"
            [disabled]="disabled"
            (click)="removeItem(node)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button
            *ngIf="addable"
            mat-icon-button
            aria-label="crear"
            [disabled]="disabled"
            (click)="addNewItem(node)"
          >
            <mat-icon>create_new_folder</mat-icon>
          </button>
        </div>
      </div>
      <ul [class.hidden]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
