<div class="candy-theme login-background w-full h-full bg-contain bg-left bg-no-repeat flex justify-center items-center">
  <div class="w-1/3">
    <div class="text-center">
      <img
        src="assets/images/kayrox.png"
        alt="Kayrox"
        class="w-full max-w-xl"
      />
      <h3 class="login-font-title font-light text-white">
        {{ translationPath + 'loginDescriptionText' | translate }}
      </h3>
    </div>
    <div class="flex justify-center">
      <div class="input-gray w-52">
        <form name="login" #loginForm="ngForm">
          <mat-form-field class="w-full mb-4">
            <input
              matInput
              [placeholder]="'common.labels.user' | translate"
              [(ngModel)]="pageData.user"
              (ngModelChange)="onUsernameChange($event)"
              #usernameModel="ngModel"
              required
              name="user"
              tabindex="1"
            />
            <mat-error
              *ngIf="
                usernameModel.invalid &&
                (usernameModel.dirty || usernameModel.touched)
              "
            >
              <mat-error
                *ngIf="usernameModel.errors.required"
                class="input-gray__error"
              >
                {{ translationPath + 'requiredUserMessage' | translate }}
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <input
              matInput
              [placeholder]="'common.labels.password' | translate"
              [(ngModel)]="pageData.password"
              #passwordModel="ngModel"
              required
              [type]="_hideText ? 'password' : 'text'"
              name="password"
              tabindex="2"
            />

            <mat-icon
              matSuffix
              class="cursor-pointer"
              (click)="_hideText = !_hideText"
            >
              {{ _hideText ? 'visibility' : 'visibility_off' }}
            </mat-icon>
            <mat-error
              *ngIf="
                passwordModel.invalid &&
                (passwordModel.dirty || passwordModel.touched)
              "
            >
              <mat-error
                *ngIf="passwordModel.errors.required"
                class="input-gray__error"
              >
                {{ translationPath + 'requiredPasswordMessage' | translate }}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </form>
        <button
          mat-flat-button
          class="w-full primary-button"
          tabindex="3"
          (click)="login()"
          [disabled]="
            loginForm.invalid && (loginForm.dirty || loginForm.touched)
          "
        >
          {{ translationPath + 'loginBtnLabel' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
