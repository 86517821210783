import { Injectable } from '@angular/core';
import { Observable, /*Subject,*/ BehaviorSubject } from 'rxjs';
import { environment as env_const } from '@env/environment';

import { UserRole } from '@common/models/types-model';
import {IdNameModel, RoleModel, TreeNodeModel, TypeModel} from '@common/models/id-name-model';
import {ComponentTypesModel, ComponentTypeModel} from '@common/models/component-types-model';
import {UserInfoModel, UserRemainingModel} from '@common/models/response-model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private _userLoginRegistered = false;
  public get userLoginRegistered (): boolean {
    return this._userLoginRegistered === true;
  }

  private state = {
    userInfo: StoreService.persistentGet ('userInfo' ) || null,
    accessToken: StoreService.persistentGet ('accessToken' ) || '', // localStorage.getItem( 'accessToken' ) || '',
    refreshToken: StoreService.persistentGet ('refreshToken' ) || '', // localStorage.getItem( 'refreshToken' ) || '',
    userIsAnonymous: !( StoreService.persistentGet( 'userIsAnonymous' ) === false ), // !(localStorage.getItem( 'userIsAnonymous' ) === 'false'),
    userName: StoreService.persistentGet ('userName' ) || '' // localStorage.getItem( 'userName' ) || ''
  };

  /* https://www.bit.es/knowledge-center/observables-subjects-y-data-stores-en-angular/
   Suject, cuando nos suscribimos a su Observable se obtienen los eventos que este Sujeto emite a partir de haberse suscrito.
   Del Observable del BehaviorSubject, obtenemos eventos emitidos a partir de la suscripción y evento con el valor actual del Sujeto
   @Output() add = new BehaviorSubject("Awesome").filter(v => !!v);
   */
  private userIsAnonymous$ = new BehaviorSubject<boolean>(
    this.state.userIsAnonymous
  );
  private userName$ = new BehaviorSubject<string>(
    this.state.userName
  );
  private userInfo$ = new BehaviorSubject<UserInfoModel>(
    this.state.userInfo
  );
  private refreshToken$ = new BehaviorSubject<string>(
    this.state.refreshToken
  );
  // private accessToken$ = new Subject<string>();
  private accessToken$ = new BehaviorSubject<string>(
    this.state.accessToken
  );
  private userRole$ = new BehaviorSubject<UserRole>(
    UserRole.none
  );
  private canAdmin$ = new BehaviorSubject<boolean>(
    false
  );
  private canEdit$ = new BehaviorSubject<boolean>(
    false
  );
  private canViewReports$ = new BehaviorSubject<boolean>(
    false
  );
  private isScreenShareHelpAllowed$ = new BehaviorSubject<boolean>(
    false
  );

  private userFolders: TreeNodeModel = { id: 0, name: 'PrivateRoot', parentId: null, children: [] };
  private userFolders$ = new BehaviorSubject<TreeNodeModel>( this.userFolders );
  private developmentFolders: TreeNodeModel = { id: 0, name: 'DevRoot', parentId: null, children: [] };
  private developmentFolders$ = new BehaviorSubject<TreeNodeModel>( this.developmentFolders );
  private organizationFolders: TreeNodeModel = { id: 0, name: 'OrgRoot', parentId: null, children: [] };
  private organizationFolders$ = new BehaviorSubject<TreeNodeModel>( this.organizationFolders );
  private publicFolders: TreeNodeModel = { id: 0, name: 'PublicRoot', parentId: null, children: [] };
  private publicFolders$ = new BehaviorSubject<TreeNodeModel>( this.publicFolders );

  private types = {
    roles: [],
    assets: [],
    accessibility: [],
    projects: [],
    components: [],
    scenes: [],
    objects: [],
    stateTypes: []
  };
  private roles$ = new BehaviorSubject<Array<RoleModel>>(
    this.types.roles
  );
  private assetTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.assets
  );
  private accessibilityTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.accessibility
  );
  private projectTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.projects
  );
  private sceneTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.scenes
  );
  private objectTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.objects
  );
  private componentTypes$ = new BehaviorSubject<ComponentTypeModel[]>(
    this.types.components
  );
  private stateTypes$ = new BehaviorSubject<Array<TypeModel>>(
    this.types.stateTypes
  );

  private allGenericTypesAvailable = false;
  private genericTypesAvailable$ = new BehaviorSubject<boolean>(
    this.allGenericTypesAvailable
  );

  private genericTypes$ = new BehaviorSubject< {
    roles: RoleModel[];
    assets: TypeModel[];
    accessibility: TypeModel[];
    projects: TypeModel[];
    scenes: TypeModel[];
    objects: TypeModel[];
    stateTypes: TypeModel[];
  } >(
    this.types
  );

  private fetchUserFolders: Function;
  private fetchDevelopmentFolders: Function;
  private fetchOrganizationFolders: Function;
  private fetchPublicFolders: Function;

  private static persistentSet (key: string, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  private static persistentGet (key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log('Error getting data from localStorage', e);
      return null;
    }
  }

  public static getUserInfoRole (userInfo: UserInfoModel): UserRole {
    let userRole = UserRole.none;

    if ( userInfo && userInfo.role ) {
      switch ( userInfo.role.role_type ) {
        case env_const.roles.admin:
          userRole = UserRole.admin;
          break;
        case env_const.roles.editor:
          userRole = UserRole.editor;
          break;
        case env_const.roles.consumer:
          userRole = UserRole.consumer;
          break;
      }
    }

    return userRole;
  }

/*
  private static persistentGetUserRole (): UserRole {
    const persistentUserInfo = StoreService.persistentGet ('userInfo' );
    return StoreService.getUserRole( persistentUserInfo );
  }
*/

  constructor( ) {
    this._userLoginRegistered = false;
    const persistentUserInfo = StoreService.persistentGet ('userInfo' );
    if ( persistentUserInfo ) {
      this.updateUserRole(persistentUserInfo);
    }
  }

  public getCanAdmin$(): Observable<boolean> {
    return this.canAdmin$.asObservable();
  }

  public getCanAdmin(): boolean {
    return this.canAdmin$.getValue();
  }

  public getCanEdit$(): Observable<boolean> {
    return this.canEdit$.asObservable();
  }

  public getUserCanViewReports$(): Observable<boolean> {
    return this.canViewReports$.asObservable();
  }

  /*
    public getUserCanViewReports(): boolean {
      return this.canViewReports$.getValue();
    }
  */

  public getIsScreenShareHelpAllowed$(): Observable<boolean> {
    return this.isScreenShareHelpAllowed$.asObservable();
  }

  public getIsScreenShareHelpAllowed(): boolean {
    return this.isScreenShareHelpAllowed$.getValue();
  }

  public getUserRole(): UserRole {
    return this.userRole$.getValue();
  }
  public getUserRole$(): Observable<UserRole> {
    return this.userRole$.asObservable();
  }

  public getUserInfo(): UserInfoModel {
    return this.userInfo$.getValue();
  }
  public getUserInfo$(): Observable<UserInfoModel> {
    return this.userInfo$.asObservable();
  }

  public getUserIsAnonymous$(): Observable<boolean> {
    return this.userIsAnonymous$.asObservable();
  }

  public getAccessToken$(): Observable<string> {
    return this.accessToken$.asObservable();
  }
  public getAccessToken(): string {
    return this.accessToken$.getValue();
  }
  public showLogAccessTokenExpires( ) {
    const accessToken = this.getAccessToken();
    if ( accessToken ) {
      const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
      const expires = new Date(jwtToken.exp * 1000);
      console.log( `${new Date()} Token expires at ${expires}` );
    } else {
      console.log( `${new Date()} No Auth Token` );
    }
  }

  public getRefreshToken$(): Observable<string> {
    return this.refreshToken$.asObservable();
  }
  public getRefreshToken(): string {
    return this.refreshToken$.getValue();
  }
  public getUsername(): string {
    return this.userName$.getValue();
  }

  public getUserName(): string {
    return this.userName$.getValue();
  }
  public getUserName$(): Observable<string> {
    return this.userName$.asObservable();
  }

  public clearStoredUser() {
    this._userLoginRegistered = false;
    this.updateUserInfo(null );
    this.updateUserJwtToken( '', '', '' );
  }
  public updateUserJwtTokenAfterLoginRegistration(userName: string, accessToken: string, refreshToken: string ) {
    this._userLoginRegistered = true;
    this.updateUserJwtToken( userName, accessToken, refreshToken );
  }
  public updateUserJwtToken( userName: string, accessToken: string, refreshToken: string ) {
    if ( userName !== this.state.userName ) {
      this.state.userName = userName;
      StoreService.persistentSet('userName', userName);
      this.userName$.next(this.state.userName);
    }

    if ( accessToken !== this.state.accessToken ) {
      this.state.accessToken = accessToken;
      StoreService.persistentSet('accessToken', accessToken);
      this.accessToken$.next(this.state.accessToken);
    }

    if ( refreshToken !== this.state.refreshToken ) {
      this.state.refreshToken = refreshToken;
      StoreService.persistentSet('refreshToken', refreshToken);
      this.refreshToken$.next(this.state.refreshToken);
    }

    const userIsAnonymous = !(accessToken && refreshToken && userName);
    if ( userIsAnonymous !== this.state.userIsAnonymous ) {
      this.state.userIsAnonymous = userIsAnonymous;
      StoreService.persistentSet('userIsAnonymous', this.state.userIsAnonymous);
      this.userIsAnonymous$.next(this.state.userIsAnonymous);
    }
  }

  public updateUserInfo(userInfo: UserInfoModel) {
    this.state.userInfo = userInfo;
    StoreService.persistentSet('userInfo', userInfo);

    this.userInfo$.next(this.state.userInfo);
    this.updateUserRole(this.state.userInfo);
  }

  public updateUserRemaining( userRemaing: UserRemainingModel, avoidPropagation?: boolean ) {
    if ( this.state.userInfo ) {
      this.state.userInfo.remaining = userRemaing;
      StoreService.persistentSet('userInfo', this.state.userInfo);

      if (!avoidPropagation) {
        this.userInfo$.next(this.state.userInfo);
      }
    }
  }

/*
  public updateUserToken(userToken: string) {
    this.state.userToken = userToken || '';
    this.state.userIsAnonymous = !(userToken && this.state.userName);

    StoreService.persistentSet('userToken', userToken);
    StoreService.persistentSet('userIsAnonymous', this.state.userIsAnonymous);

    this.userToken$.next(this.state.userToken);
    this.userIsAnonymous$.next(this.state.userIsAnonymous);
  }
*/

/*
  private refreshTokenBeforeExpity(issuedAt: number) {
    const jwtToken = JSON.parse(atob(this.state.accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const lastTokenIssuedAt = new Date(issuedAt * 1000);
    const timeout = expires.getTime() - lastTokenIssuedAt.getTime() - MS_FOR_REFRESH_BEFORE_EXPIRATION;
    console.log(`${new Date()} Token expires at ${expires}, new token will be requested in ${timeout / 1000}s`);
    this.refreshTokenTimeout = setTimeout(() =>  {
      this.credentialService.refreshToken$(this.state.refreshToken).subscribe(
        (accessToken) => {
          this.state.accessToken = accessToken.access;
          StoreService.persistentSet('accessToken', accessToken.access);
          this.accessToken$.next(accessToken.access);
          this.refreshTokenBeforeExpity(accessToken.date);
        }
      );
    }, timeout);
  }
*/

  private updateUserRole(userInfo: UserInfoModel) {
    const userRole = StoreService.getUserInfoRole( userInfo );

    this.userRole$.next(userRole);
    this.canAdmin$.next(userRole === UserRole.admin);
    this.canEdit$.next(userRole === UserRole.editor || userRole === UserRole.admin);

    this.isScreenShareHelpAllowed$.next(
      !!userInfo?.organization?.personalization?.screenSharingHelp
    );

    this.updateUserCanViewReports();
  }

  public isCurrentUserAdmin ( ): boolean {
    return this.userRole$.getValue() === UserRole.admin;
  }
/*
  public isConsumerUser ( ): boolean {
    return this.userRole$.getValue() === UserRole.consumer;
  }
*/
  public isCurrentUserEditor ( ): boolean {
    return this.userRole$.getValue() === UserRole.editor;
  }

  public getAssetTypes(): TypeModel[] {
    return this.assetTypes$.getValue();
  }
  public getAssetTypes$(): Observable<Array<TypeModel>> {
    return this.assetTypes$.asObservable();
  }
  public updateAssetTypes(assetTypes: Array<TypeModel>) {
    this.types.assets = assetTypes;

    this.assetTypes$.next(this.types.assets);

    this.updateGenericTypes();
  }

  public getAccessibilityTypes(): TypeModel[] {
    return this.accessibilityTypes$.getValue();
  }
  public getAccessibilityTypes$(): Observable<Array<TypeModel>> {
    return this.accessibilityTypes$.asObservable();
  }
  public updateAccessibilityTypes(accessibilityTypes: Array<TypeModel>) {
    this.types.accessibility = accessibilityTypes;

    this.accessibilityTypes$.next(this.types.accessibility);

    this.updateGenericTypes();
  }

  public getProjectTypes(): TypeModel[] {
    return this.projectTypes$.getValue();
  }
  public getProjectTypes$(): Observable<Array<TypeModel>> {
    return this.projectTypes$.asObservable();
  }

  updateUserCanViewReports () {
    if ( this.types.projects && this.types.projects.length && this.userRole$.getValue() === UserRole.admin ) {
      const anyProjectTypeCanReport = this.types.projects.some (
        (projectType: TypeModel) => env_const.produceReportProjectTypeIds.indexOf(projectType.id) !== -1
      );
      this.canViewReports$.next( anyProjectTypeCanReport );
    } else {
      this.canViewReports$.next( false );
    }
  }
  public updateProjectTypes(projectTypes: Array<TypeModel>) {
    this.types.projects = projectTypes;

    this.projectTypes$.next(this.types.projects);

    this.updateUserCanViewReports ();
    this.updateGenericTypes();
  }

  public getSceneTypes(): TypeModel[] {
    return this.sceneTypes$.getValue();
  }
  public getSceneTypes$(): Observable<Array<TypeModel>> {
    return this.sceneTypes$.asObservable();
  }
  public updateSceneTypes(sceneTypes: Array<TypeModel>) {
    this.types.scenes = sceneTypes;

    this.sceneTypes$.next(this.types.scenes);

    this.updateGenericTypes();
  }

  public getComponentTypesKeyValue(): ComponentTypesModel {
    const componentTypesKeyValue = {};
    const componentTypes = this.componentTypes$.getValue();

    componentTypes.forEach(
      ( componentType ) => componentTypesKeyValue[componentType.name] = componentType
    );

    return componentTypesKeyValue;
  }

  public getComponentType( nameKey: string ): ComponentTypeModel {
    const componentTypes = this.componentTypes$.getValue();

    return componentTypes && componentTypes.find(
      ( componentType ) => componentType.name === nameKey
    );
  }

  public getObjectComponentTypes ( objectTypeId: number ): ComponentTypeModel[] {
    const objectTypes = this.getObjectTypes();
    const foundedObjectType = objectTypes.find ((objectType) => objectType.id === objectTypeId );
    return foundedObjectType && foundedObjectType.component_types;
  }

  public getComponentTypes(): ComponentTypeModel[] {
    return this.componentTypes$.getValue();
  }

  public getComponentTypes$(): Observable<ComponentTypeModel[]> {
    return this.componentTypes$.asObservable();
  }

  public getObjectTypes(): TypeModel[] {
    return this.objectTypes$.getValue();
  }
  public getObjectTypes$(): Observable<Array<TypeModel>> {
    return this.objectTypes$.asObservable();
  }

  public updateComponentTypes(componentTypes: ComponentTypeModel[]) {// Array<TypeModel>) {
/*
    const componentTypes: ComponentTypesModel = {};
    const setObjectComponents = ( objectType: TypeModel ) => {
      objectType.component_types.forEach( ( component: ComponentTypeModel ) => {
        componentTypes[component.name] = component;
      } );
    };
    objectTypes.forEach( ( objectType) => setObjectComponents( objectType ) );
*/

    this.types.components = componentTypes;

    this.componentTypes$.next(this.types.components);

    this.updateGenericTypes();
  }
  public updateObjectTypes(objectTypes: Array<TypeModel>) {
    //  this.updateComponentTypes( objectTypes );
    this.types.objects = objectTypes;

    this.objectTypes$.next(this.types.objects);

    this.updateGenericTypes();
  }

  public setupFetchFolders( fetchUserFolders: Function, fetchDevelopmentFolders: Function,
                                fetchOrganizationFolders: Function, fetchPublicFolders: Function ) {
    this.fetchUserFolders = fetchUserFolders;
    this.fetchDevelopmentFolders = fetchDevelopmentFolders;
    this.fetchOrganizationFolders = fetchOrganizationFolders;
    this.fetchPublicFolders = fetchPublicFolders;
  }
  public resetUserFolders() {
    if ( this.fetchUserFolders ) { this.fetchUserFolders(); }
  }
  public resetDevelopmentFolders() {
    if ( this.fetchDevelopmentFolders ) { this.fetchDevelopmentFolders(); }
  }
  public resetOrganizationFolders() {
    if ( this.fetchOrganizationFolders ) { this.fetchOrganizationFolders(); }
  }
  public resetPublicFolders() {
    if ( this.fetchPublicFolders ) { this.fetchPublicFolders(); }
  }
  public getUserFolders(): TreeNodeModel {
    return this.userFolders$.getValue();
  }
  public getUserFolders$(): Observable<TreeNodeModel> {
    return this.userFolders$.asObservable();
  }
  public updateUserFolders(folders: TreeNodeModel) {
    console.log('USER FOLDERS:', folders);
    this.userFolders = folders;

    this.userFolders$.next(this.userFolders);
  }
  public getDevelopmentFolders(): TreeNodeModel {
    return this.developmentFolders$.getValue();
  }
  public getDevelopmentFolders$(): Observable<TreeNodeModel> {
    return this.developmentFolders$.asObservable();
  }
  public updateDevelopmentFolders(folders: TreeNodeModel) {
    this.developmentFolders = folders;

    this.developmentFolders$.next(this.developmentFolders);
  }
  public getOrganizationFolders(): TreeNodeModel {
    return this.organizationFolders$.getValue();
  }
  public getOrganizationFolders$(): Observable<TreeNodeModel> {
    return this.organizationFolders$.asObservable();
  }
  public updateOrganizationFolders(folders: TreeNodeModel) {
    this.organizationFolders = folders;

    this.organizationFolders$.next(this.organizationFolders);
  }
  public getPublicFolders(): TreeNodeModel {
    return this.publicFolders$.getValue();
  }
  public getPublicFolders$(): Observable<TreeNodeModel> {
    return this.publicFolders$.asObservable();
  }
  public updatePublicFolders(folders: TreeNodeModel) {
    this.publicFolders = folders;

    this.publicFolders$.next(this.publicFolders);
  }


  public getRoles(): RoleModel[] {
    return this.roles$.getValue();
  }
  public getRoles$(): Observable<Array<RoleModel>> {
    return this.roles$.asObservable();
  }
  public updateRoles(roles: Array<RoleModel>) {
    this.types.roles = roles;

    this.roles$.next(this.types.roles);

    this.updateGenericTypes();
  }

  public getStateTypes(): TypeModel[] {
    return this.stateTypes$.getValue();
  }
  public getStateTypes$(): Observable<Array<TypeModel>> {
    return this.stateTypes$.asObservable();
  }
  public updateStateTypes(stateTypes: Array<TypeModel>) {
    this.types.stateTypes = stateTypes;

    this.stateTypes$.next(this.types.stateTypes);

    this.updateGenericTypes();
  }

  private updateGenericTypes() {
    this.allGenericTypesAvailable = this.types.assets !== undefined &&
      this.types.assets.length !== 0 &&
      this.types.accessibility !== undefined &&
      this.types.accessibility.length !== 0 &&
      this.types.projects !== undefined &&
      this.types.projects.length !== 0 &&
      this.types.scenes !== undefined &&
      this.types.scenes.length !== 0 &&
      this.types.objects !== undefined &&
      this.types.objects.length !== 0 &&
      this.types.components !== undefined &&
      this.types.components.length !== 0 &&
      this.types.roles !== undefined &&
      this.types.roles.length !== 0 &&
      this.types.stateTypes !== undefined &&
      this.types.stateTypes.length !== 0;

    if ( this.allGenericTypesAvailable ) {
      this.genericTypes$.next(this.types);
    }

    this.genericTypesAvailable$.next(this.allGenericTypesAvailable );
  }

  public getGenericTypes$(): Observable<any> {
    return this.genericTypes$.asObservable();
  }
  public getGenericTypesAvailability(): boolean {
    return this.genericTypesAvailable$.getValue();
  }
  public getGenericTypesAvailability$(): Observable<boolean> {
    return this.genericTypesAvailable$.asObservable();
  }

  public isOwner ( user: IdNameModel ): boolean {
    if ( user && this.state.userInfo ) {
      return this.state.userInfo.id === user.id;
    } else {
      return false;
    }
  }

  public isOwnOrganization ( organization: IdNameModel ): boolean {
    if ( organization && this.state.userInfo && this.state.userInfo.organization ) {
      return this.state.userInfo.organization.id === organization.id;
    } else {
      return false;
    }
  }
}
