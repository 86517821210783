import {Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ResponseModel} from '@common/models/response-model';
import {environment as env_const} from '@env/environment';

export interface CheckUserDialogData {
  label: string;
  btnCheckLabel: string;
  btnCancelLabel: string;
}

@Component({
  selector: 'tiwp-check-user-dialog',
  templateUrl: './check-user-dialog.component.html',
  styleUrls: ['./check-user-dialog.component.scss']
})
export class CheckUserDialogComponent {
  public dialogData: CheckUserDialogData;
  private apiUrl = env_const.apiUrl;
  public _pwd = '';

  public _hideText = true;

  constructor(
    private http: HttpClient,
    private  dialogRef:  MatDialogRef<CheckUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  CheckUserDialogData
  ) {
    this._hideText = true;
    this.dialogData = data;
    this._pwd = '';
  }

  public checkUser$(password: string): Observable<ResponseModel> {
    const loginServiceUrl = this.apiUrl + 'check_password/';
    const loginData = new FormData();
    loginData.set( 'password', password );

    return this.http.post<ResponseModel>(loginServiceUrl, loginData);
  }

  public  onOk( ) {
    this.checkUser$(
      this._pwd
    ).subscribe(
      (result) => {
        this.dialogRef.close( result.success );
      },
      ( error ) => {
        console.log('%cError on validation: ', 'color:black;background:red', error.message );
        this.dialogRef.close( );
      }
    );
  }

}
