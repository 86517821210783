import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
} from '@angular/core';

@Component({
  selector: 'tiwp-edit-label',
  templateUrl: './edit-label.component.html',
  styleUrls: ['./edit-label.component.scss'],
})
export class EditLabelComponent {
  private onEditionMode = false;

  @Input() public text: string;
  @Input() public useDoubleClick: boolean;
  @Input() public readonly: boolean;
  @Input() public disabled: boolean;
  @Output() public changed = new EventEmitter<string>();

  private cachedText = '';

  constructor(private elRef: ElementRef, private ngZone: NgZone) {}

  setEditionMode(mode: boolean) {
    this.onEditionMode = mode === true;

    if (mode) {
      setTimeout(() => {
        const input = this.elRef.nativeElement.querySelector('input');
        input.focus();
      }, 0);

      this.cachedText = this.text;
    } else {
      if (this.cachedText !== this.text) {
        // textChanged
        setTimeout(() => {
          this.ngZone.run(() => {
            this.changed.emit(this.text);
          });
        }, 0);
      }
    }
  }

  isReadonly(): boolean {
    return this.readonly || !this.onEditionMode;
  }
}
