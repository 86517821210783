import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';

// custom validator to check that two fields match
export function MustMatchValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function PasswordUnmatchedValidator( passwordControlName?: string ) {
  if ( !passwordControlName ) {
    passwordControlName = 'password';
  }
  return (confirmPasswordControl: AbstractControl): ValidationErrors => {
    if ( confirmPasswordControl.parent ) {
      const passwordControl = confirmPasswordControl.parent.get( passwordControlName );

      if ( !passwordControl || passwordControl.errors || confirmPasswordControl.errors) {
        // return if another validator has already found an error
        return null;
      }

      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      let error: ValidationErrors;
      if (password !== confirmPassword) {
        error = { passwordUnmatched: true };
      } else {
        error = null;
      }

      confirmPasswordControl.setErrors( error );
      return error;
    }
  };
}
