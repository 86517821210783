<h1 *ngIf="title" mat-dialog-title class="h-16 flex m-auto overflow-hidden">
  <span
    class="m-auto overflow-hidden flex static w-auto font-bold text-4xl text-color-red"
  >
    {{ title }}
  </span>
</h1>

<mat-dialog-content
  class="flex justify-center items-center overflow-hidden p-0 m-auto"
>
  <mat-progress-spinner
    class="overflow-hidden"
    diameter="100"
    strokeWidth="8"
    [mode]="mode"
    [value]="amount"
  >
  </mat-progress-spinner>
  <mat-spinner
    *ngIf="veryLongTime"
    class="absolute left-[calc(50vw - 25px)] top-[calc(50vh + 7px)] overflow-hidden"
    diameter="50"
    strokeWidth="8"
  >
  </mat-spinner>
</mat-dialog-content>
