<h1 mat-dialog-title class="main-title">
  <span class="main-title-item">{{data.titleKey | translate}}</span>
</h1>

<div class="dialog-content-background">
  <mat-dialog-content class="dialog-main-container mat-typography">
    <tiwp-plm-asset-selection [projects]="dialogData.items"
                              [assetTypes]="dialogData.assetTypes"
                              (assetClick)="assetSelected($event)"
                              (assetSelectionChange)="assetSelectionChange($event)">
    </tiwp-plm-asset-selection>
  </mat-dialog-content>
</div>

<mat-dialog-actions *ngIf="multipleSelection" align="end">
  <button mat-button mat-dialog-close>
    {{dialogData.btnCancelLabelKey | translate}}
  </button>
  <button mat-button color="primary"
          (click)="onOk()"
          [disabled]="!(selectedAssets && selectedAssets.length)">
    {{dialogData.btnOkLabelKey | translate}}
  </button>
</mat-dialog-actions>
