<h2 mat-dialog-title *ngIf="dialogData.titleKey; else notTranslatedTitle">
  {{ dialogData.titleKey | translate }}
</h2>
<ng-template #notTranslatedTitle>
  <h2 mat-dialog-title *ngIf="dialogData.title">{{ dialogData.title }}</h2>
</ng-template>

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="dialogData.questionKey; else notTranslatedQuestion">
    <h3 *ngIf="dialogData.questionParams; else translationWithoutParams">
      {{ dialogData.questionKey | translate : dialogData.questionParams }}
    </h3>
    <ng-template #translationWithoutParams>
      <h3>{{ dialogData.questionKey | translate }}</h3>
    </ng-template>
  </ng-container>
  <ng-template #notTranslatedQuestion>
    <h3>{{ dialogData.question }}</h3>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="secondary-button">
    {{
      dialogData.btnCancelLabelKey
        ? (dialogData.btnCancelLabelKey | translate)
        : dialogData.btnCancelLabel
    }}
  </button>

  <button
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="primary-button"
  >
    {{
      dialogData.btnOkLabelKey
        ? (dialogData.btnOkLabelKey | translate)
        : dialogData.btnOkLabel
    }}
  </button>
</mat-dialog-actions>
