import { Injectable } from '@angular/core';

import {KeyValueModel, ListenCallbackModel} from '@common/models/types-model';

interface SubscribersModel {
  [s: string]: ListenCallbackModel[];
}

@Injectable({
  providedIn: 'root'
})
export class PublisherSubscriberService {
  subscribers = <SubscribersModel>{};

  constructor() {}

  public publish(keyMessage: string, data: KeyValueModel ) {
    const messageSubscribers = this.subscribers[keyMessage];
    if ( messageSubscribers ) {
      messageSubscribers.forEach( subscribedCallback => subscribedCallback( data ) );
    }
  }

  public subscribe( keyMessage: string, listenCallback: ListenCallbackModel ) {
    let messageSubscribers = this.subscribers[keyMessage];
    if ( !messageSubscribers ) {
      this.subscribers[keyMessage] = messageSubscribers = <ListenCallbackModel[]>[];
    }
    // messageSubscribers.push( listenCallback );
    // only one subscription by listen callback
    const subscriptionIndex = messageSubscribers.indexOf( listenCallback );
    if ( subscriptionIndex === -1 ) {
      messageSubscribers.push( listenCallback );
    }
  }

  public unsubscribe( keyMessage: string, listenCallback: ListenCallbackModel ) {
    const messageSubscribers = this.subscribers[keyMessage];
    if ( messageSubscribers ) {
      const subscriptionIndex = messageSubscribers.indexOf( listenCallback );
      if ( subscriptionIndex !== -1 ) {
        messageSubscribers.splice( subscriptionIndex, 1 );
      }
/*
      let subscriptionIndex = messageSubscribers.indexOf( listenCallback );
      while ( subscriptionIndex !== -1 ) {
        messageSubscribers.splice( subscriptionIndex, 1 );
        subscriptionIndex = messageSubscribers.indexOf( listenCallback );
      }
*/
    }
  }
}
