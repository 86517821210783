<div class='centered top-space'>
  <mat-card class="top-card">
    <mat-card-header>
      <!-- https://material.io/tools/icons/?style=outline -->
      <div mat-card-avatar class="center-w-h"><mat-icon>domain</mat-icon></div>
      <div>
        <mat-card-title>{{'admin.newOrgTitle' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'admin.newOrgSubTitle' | translate}}</mat-card-subtitle>
      </div>
    </mat-card-header>
    <img mat-card-image src="assets/images/hero.png" alt="Tiwp Logo">
  </mat-card>
</div>

<mat-card class="new-user-card">
  <mat-card-title class="card-title">
    <img class='card-avatar' src="assets/images/organization.png">
  </mat-card-title>
  <mat-card-content>
    <form class="example-form" autocomplete="off" [formGroup]="formController">
      <div class="login_group_row">
        <mat-form-field class="full-width bottom-space">
          <input matInput
                 [(ngModel)]="_organizationName"
                 [placeholder]="'admin.orgName' | translate"
                 type="text" required
                 name="organizationName"
                 formControlName="organizationName">
          <mat-error *ngIf="formControls.organizationName.errors && (formControls.organizationName.dirty || formControls.organizationName.touched)">
            <mat-error *ngIf="formControls.organizationName.errors.required">
              {{ 'admin.requiredOrgName' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput
                 [(ngModel)]="_organizationShortName"
                 [placeholder]="'admin.orgShortName' | translate"
                 type="text" required
                 name="organizationShortName"
                 formControlName="organizationShortName">
          <mat-error *ngIf="formControls.username.errors && (formControls.username.dirty || formControls.username.touched)">
            <mat-error *ngIf="formControls.username.errors.required">
              {{ 'admin.requiredShortName' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput
                 [(ngModel)]="_username"
                 (ngModelChange)="onUsernameChange($event)"
                 [placeholder]="'admin.adminUsername' | translate"
                 type="text" required
                 name="username"
                 formControlName="username">
          <mat-error *ngIf="formControls.username.errors && (formControls.username.dirty || formControls.username.touched)">
            <mat-error *ngIf="formControls.username.errors.required">
              {{ 'common.messages.errors.requiredUsername' | translate }}
            </mat-error>
            <mat-error *ngIf="formControls.username.errors.minlength">
              {{ 'common.messages.errors.minLength' | translate:{length: 5} }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput [placeholder]="'admin.adminUserPwd' | translate"
                 [(ngModel)]="_password"
                 type="text" required
                 name="password"
                 autocomplete="admin-new-password"
                 formControlName="password">
          <mat-error *ngIf="formControls.password.errors && (formControls.password.dirty || formControls.password.touched)">
            <mat-error *ngIf="formControls.password.errors.required">
              {{ 'common.messages.errors.requiredPassword' | translate }}
            </mat-error>
            <mat-error *ngIf="formControls.password.errors.minlength">
              {{ 'common.messages.errors.minLength' | translate:{length: 8} }}
            </mat-error>
            <mat-error *ngIf="formControls.password.errors.maxlength">
              {{ 'common.messages.errors.maxLength' | translate:{length: 10} }}
            </mat-error>
            <mat-error *ngIf="formControls.password.errors.pattern">
              {{ 'common.messages.errors.passwordRegexp' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput [placeholder]="'admin.adminRetypePwd' | translate"
                 [(ngModel)]="_passwordConfirmation"
                 type="text" required
                 name="#passwordConfirmation#"
                 autocomplete="admin-new-password"
                 formControlName="confirmPassword">
          <mat-error *ngIf="formControls.confirmPassword.errors && (formControls.confirmPassword.dirty || formControls.confirmPassword.touched)">
            <mat-error *ngIf="formControls.confirmPassword.errors.required">
              {{ 'common.messages.errors.retypePassword' | translate }}
            </mat-error>
            <mat-error *ngIf="formControls.confirmPassword.errors.mustMatch">
              {{ 'common.messages.errors.matchPasswords' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput
                 [(ngModel)]="_email"
                 [placeholder]="'admin.email' | translate"
                 type="email"
                 name="email"
                 formControlName="email">
          <mat-error *ngIf="formControls.email.errors && (formControls.email.dirty || formControls.email.touched)">
            <mat-error *ngIf="formControls.email.errors.email || formControls.email.errors.pattern">
              {{ 'common.messages.errors.wrongEmail' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="admin-organization-types" [ngClass]="{ 'empty-selection' : !(selectedAssetTypes?.length) }" appearance="outline">
          <mat-label>{{ 'admin.assetTypesLabel' | translate }}</mat-label>
          <mat-select [ngClass]="{ 'admin-organization-types' : selectedAssetTypes?.length }"
                      [(ngModel)]="selectedAssetTypes" [formControl]="assetTypesControl"
                      panelClass="selection-asset-types-panel"
                      required multiple>
            <mat-option *ngFor="let item of assetTypes" [value]="item.id">
              <img class="admin-organization-types" [src]="item.uiImage" alt="Asset type icon"/>
              {{item.name}}
            </mat-option>
          </mat-select>
          <mat-chip-list aria-label="Asset type selection">
            <mat-chip class="admin-organization-types" *ngFor="let itemSelected of selectedAssetTypes">
              <img matChipAvatar [src]="getAssetTypeIcon( itemSelected )" alt="Asset type icon"/>
              {{getAssetTypeName( itemSelected )}}
            </mat-chip>
          </mat-chip-list>
          <mat-error *ngIf="assetTypesControl.errors && (assetTypesControl.dirty || assetTypesControl.touched)">
            {{ 'admin.anyAssetTypeRequired' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="admin-organization-types" [ngClass]="{ 'empty-selection' : !(selectedProjectTypes?.length) }" appearance="outline">
          <mat-label>{{ 'admin.projectTypesLabel' | translate }}</mat-label>
          <mat-select class="admin-organization-types" [ngClass]="{ 'admin-organization-types' : selectedProjectTypes?.length }"
                      panelClass="selection-project-types-panel"
                      [(ngModel)]="selectedProjectTypes" [formControl]="projectTypesControl"
                      required multiple>
            <mat-option *ngFor="let projectType of projectTypes" [value]="projectType.id">
              <img class="admin-organization-types" [src]="projectType.uiImage"/>
              {{projectType.name}}
            </mat-option>
          </mat-select>
          <mat-chip-list aria-label="Project type selection">
            <mat-chip class="admin-organization-types" *ngFor="let projectType of selectedProjectTypes">
              <img matChipAvatar [src]="getProjectTypeIcon( projectType )"/>
              {{getProjectTypeName( projectType )}}
            </mat-chip>
          </mat-chip-list>
          <mat-error *ngIf="projectTypesControl.errors && (projectTypesControl.dirty || projectTypesControl.touched)">
            {{ 'admin.anyProjectTypeRequired' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput [placeholder]="'admin.projectIdsClone' | translate"
                 [(ngModel)]="_projects"
                 type="text"
                 name="projects"
                 autocomplete="admin-projects-clone"
                 formControlName="projects">
          <mat-error *ngIf="formControls.projects.errors && (formControls.projects.dirty || formControls.projects.touched)">
            <mat-error *ngIf="formControls.projects.errors.pattern">
              {{ 'admin.idsClone' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width bottom-space">
          <input matInput [placeholder]="'admin.assetIdsClone' | translate"
                 [(ngModel)]="_assets"
                 type="text"
                 name="assets"
                 autocomplete="admin-projects-clone"
                 formControlName="assets">
          <mat-error *ngIf="formControls.assets.errors && (formControls.assets.dirty || formControls.assets.touched)">
            <mat-error *ngIf="formControls.assets.errors.pattern">
              {{ 'admin.idsClone' | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <mat-card-actions class="actions-row">
      <button mat-stroked-button
              class="secondary-button"
              [disabled]="formController.invalid || !(formController.dirty || formController.touched)"
              (click)="createNewOrganization()">
        {{ 'admin.newOrgAction' | translate }}
        <mat-icon class="login-button-icon">library_add</mat-icon>
      </button>
      <button class="primary-button toLogin-button center-w-h" mat-stroked-button (click)="navToLogin()">
        {{ 'admin.newOrgExit' | translate }}
        <mat-icon class="login-button-icon">close</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
